import { useEffect } from "react";
import { useLocation } from "react-router-dom"; // This hook tracks the route changes
import ReactGA from "react-ga4";

const Analytics = () => {
  const location = useLocation(); // Get the current route

  useEffect(() => {
    // Every time the route changes, send a pageview to Google Analytics
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]); // This effect runs whenever the route changes

  return null; // No visible UI component is needed
};

export default Analytics;
