import { Routes, Route } from "react-router-dom";

import {
  AdvancedCommonMistakes,
  ElementaryCommonMistakes,
  IntermediateCommonMistakes,
  PreIntermediateCommonMistakes,
  UpperIntermediateCommonMistakes,
  IELTSWritingCommonMistakes,
} from "../pages";

export const CommonMistakesRoutes = () => {
  return (
    <Routes>
      <Route
        path="advanced"
        element={<AdvancedCommonMistakes title="Advanced Common Mistakes" />}
      />
      <Route
        path="elementary"
        element={
          <ElementaryCommonMistakes title="Elementary Common Mistakes" />
        }
      />
      <Route
        path="intermediate"
        element={
          <IntermediateCommonMistakes title="Intermediate Common Mistakes" />
        }
      />
      <Route
        path="preIntermediate"
        element={
          <PreIntermediateCommonMistakes title="PreIntermediate Common Mistakes" />
        }
      />
      <Route
        path="UpperIntermediate"
        element={
          <UpperIntermediateCommonMistakes title="Upper-Intermediate Common Mistakes" />
        }
      />
      <Route
        path="IELTSWriting"
        element={
          <IELTSWritingCommonMistakes title="Common Mistakes in IELTS Writing" />
        }
      />
    </Routes>
  );
};
