import { useState } from "react";
import { Link } from "react-router-dom";
import { LanguageSwitch, ExerciseCard, useTitle } from "../../../components";
import Bulb from "../../../assets/images/bulb.png";
import LongArrow from "../../../assets/images/arrowLongLight.svg";

export const PastSimpleRegularElementary = ({ title }) => {
  const [farsi, setFarsi] = useState(false);
  const instruction = (
    <span>
      Complete the sentences using the Simple Past form of the verbs in
      parentheses. Use contractions wherever possible.
    </span>
  );
  const questions = [
    {
      id: 1,
      question: (
        <span>
          <span>"____ you ____ law at university?" (study)</span>
          <span className="ml-8">"No, I ____. I ____ sociology." (study)</span>
        </span>
      ),
      answer: (
        <span className="font-normal">
          <span>
            "<span className="font-bold">Did</span> you{" "}
            <span className="font-bold">study</span> law at university?"
          </span>
          <span className="ml-8">
            "No, I <span className="font-bold">didn't</span>. I{" "}
            <span className="font-bold">studied</span> sociology."
          </span>
        </span>
      ),
    },
    {
      id: 2,
      question: (
        <span>
          <span>
            "How was your trip to the island? ____ you ____ it?" (like)
          </span>
          <span className="pl-8">
            "Yes, we ____. we ____ to come back home." (not / want)
          </span>
        </span>
      ),
      answer: (
        <span>
          <span>
            "How was your trip to the island?{" "}
            <span className="font-bold">Did</span> you{" "}
            <span className="font-bold">like</span> it?"
          </span>
          <span className="pl-8">
            "Yes, we <span className="font-bold">did</span>. We{" "}
            <span className="font-bold">didn't want</span> to come back home."
          </span>
        </span>
      ),
    },
    {
      id: 3,
      question: (
        <span>
          <span>"____ you ____ the football match last night?" (watch)</span>
          <span className="ml-8">
            "No, I ____. I ____ late last night." (work)
          </span>
        </span>
      ),
      answer: (
        <span>
          <span>
            "<span className="font-bold">Did</span> you{" "}
            <span className="font-bold">watch</span> the football match last
            night?"
          </span>
          <span className="ml-8">
            "No, I <span className="font-bold">didn't</span>. I{" "}
            <span className="font-bold">worked</span> late last night."
          </span>
        </span>
      ),
    },
    {
      id: 4,
      question: (
        <span>
          <span>
            "I ____ Tom three times yesterday, but he ____." (call - not /
            answer)
          </span>
          <span className="ml-8">"Maybe his phone ____." (not / work)</span>
        </span>
      ),
      answer: (
        <span>
          <span>
            "I <span className="font-bold">called</span> Tom three times
            yesterday, but he <span className="font-bold">didn't answer</span>."
          </span>
          <span className="ml-8">
            "Maybe his phone <span className="font-bold">didn't work</span>."
          </span>
        </span>
      ),
    },
  ];

  useTitle(title);

  return (
    <>
      {/* -------------------- Breadcrumb Menu -------------------- */}
      <div data-aos="fade" data-aos-duration="1500">
        <nav className="flex pl-3 pt-2 text-gray-400 text-sm font-semibold">
          <Link to="/" className="flex duration-300 hover:text-indigo-950">
            <span className="pt-0.5 md:pr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
            </span>
            <span className="max-md:hidden">Home</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link to="/grammar" className="duration-300 hover:text-indigo-950">
            <span>Grammar</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link
            to="/grammar/elementary"
            className="duration-300 hover:text-indigo-950"
          >
            <span>A1 - A2</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <span className="text-indigo-950">Past Simple (Regular Verbs)</span>
        </nav>
      </div>

      <div data-aos="zoom-in" data-aos-duration="800">
        {/* -------------------- Language Switch -------------------- */}
        <LanguageSwitch farsi={farsi} setFarsi={setFarsi} />

        {/* -------------------- Body in English -------------------- */}
        <div
          className={`duration-300 ${
            farsi ? "hidden opacity-0" : ""
          } my-7 mx-5 text-indigo-950 text-xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            The Past Simple (Regular Verbs)
          </h1>

          <p className="mb-4">
            A regular verb is a verb that forms its past tense by adding '-ed'
            to its base form.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-8">
            <p className="font-semibold pb-6 italic">Examples:</p>{" "}
            <p className="pb-1 italic flex">
              <span>work (v)</span>
              <span>
                <img src={LongArrow} alt="arrow" className="w-8 mx-2 pt-1" />
              </span>
              <span>worked (p)</span>
            </p>
            <p className="pb-1 italic flex">
              <span>play (v)</span>
              <span>
                <img src={LongArrow} alt="arrow" className="w-8 mx-2 pt-1" />
              </span>
              <span>played (p)</span>
            </p>
          </div>

          <p className="mb-4">
            Let's take a look at the different forms of the Past Simple tense
            for the verb "work".
          </p>
          <div className="mb-8">
            <h3 className="font-bold text-pink-700 uppercase mb-1">Positive</h3>
            <p>
              I <span className="font-semibold">worked</span> ... .
            </p>
            <p>
              You <span className="font-semibold">worked</span> ... .
            </p>
            <p>
              He <span className="font-semibold">worked</span> ... .
            </p>
            <p>
              She <span className="font-semibold">worked</span> ... .
            </p>
            <p>
              It <span className="font-semibold">worked</span> ... .
            </p>
            <p>
              We <span className="font-semibold">worked</span> ... .
            </p>
            <p>
              You <span className="font-semibold">worked</span> ... .
            </p>
            <p>
              They <span className="font-semibold">worked</span> ... .
            </p>
          </div>

          <div className="mb-8">
            <h3 className="font-bold text-pink-700 uppercase mb-1">Negative</h3>
            <p>
              I <span className="font-semibold">did</span>{" "}
              <span className="font-semibold text-red-700">not</span> work ... .
              / I<span className="font-semibold"> did</span>
              <span className="font-semibold text-red-700">n't</span> work ... .
            </p>
            <p>
              You <span className="font-semibold">did</span>{" "}
              <span className="font-semibold text-red-700">not</span> work ... .
              / You
              <span className="font-semibold"> did</span>
              <span className="font-semibold text-red-700">n't</span> work ... .
            </p>
            <p>
              He <span className="font-semibold">did</span>{" "}
              <span className="font-semibold text-red-700">not</span> work ... .
              / He
              <span className="font-semibold"> did</span>
              <span className="font-semibold text-red-700">n't</span> work ... .
            </p>
            <p>
              She <span className="font-semibold">did</span>{" "}
              <span className="font-semibold text-red-700">not</span> work ... .
              / She
              <span className="font-semibold"> did</span>
              <span className="font-semibold text-red-700">n't</span> work ... .
            </p>
            <p>
              It <span className="font-semibold">did</span>{" "}
              <span className="font-semibold text-red-700">not</span> work ... .
              / It
              <span className="font-semibold"> did</span>
              <span className="font-semibold text-red-700">n't</span> work ... .
            </p>
            <p>
              We <span className="font-semibold">did</span>{" "}
              <span className="font-semibold text-red-700">not</span> work ... .
              / We
              <span className="font-semibold"> did</span>
              <span className="font-semibold text-red-700">n't</span> work ... .
            </p>
            <p>
              You <span className="font-semibold">did</span>{" "}
              <span className="font-semibold text-red-700">not</span> work ... .
              / You
              <span className="font-semibold"> did</span>
              <span className="font-semibold text-red-700">n't</span> work ... .
            </p>
            <p>
              They <span className="font-semibold">did</span>{" "}
              <span className="font-semibold text-red-700">not</span> work ... .
              / They
              <span className="font-semibold"> did</span>
              <span className="font-semibold text-red-700">n't</span> work ... .
            </p>
          </div>

          <div className="mb-8">
            <h3 className="font-bold text-pink-700 uppercase mb-1">
              Positive Questions
            </h3>
            <p>
              <span className="font-semibold">Did</span> I work ... ?
            </p>
            <p>
              <span className="font-semibold">Did</span> you work ... ?
            </p>
            <p>
              <span className="font-semibold">Did</span> he work ... ?
            </p>
            <p>
              <span className="font-semibold">Did</span> she work ... ?
            </p>
            <p>
              <span className="font-semibold">Did</span> it work ... ?
            </p>
            <p>
              <span className="font-semibold">Did</span> we work ... ?
            </p>
            <p>
              <span className="font-semibold">Did</span> you work... ?
            </p>
            <p>
              <span className="font-semibold">Did</span> they work ... ?
            </p>
          </div>

          <div className="mb-8">
            <h3 className="font-bold text-pink-700 uppercase mb-1">
              Negative Questions
            </h3>
            <p>
              <span className="font-semibold">Did</span> I{" "}
              <span className="font-semibold text-red-700">not</span> work ...?
              / <span className="font-semibold">Did</span>
              <span className="font-semibold text-red-700">n't</span> I work ...
              ?
            </p>
            <p>
              <span className="font-semibold">Did</span> you{" "}
              <span className="font-semibold text-red-700">not</span> work ... ?
              / <span className="font-semibold">Did</span>
              <span className="font-semibold text-red-700">n't</span> you work
              ... ?
            </p>
            <p>
              <span className="font-semibold">Did</span> he{" "}
              <span className="font-semibold text-red-700">not</span> work ... ?
              / <span className="font-semibold">Did</span>
              <span className="font-semibold text-red-700">n't</span> he work
              ... ?
            </p>
            <p>
              <span className="font-semibold">Did</span> she{" "}
              <span className="font-semibold text-red-700">not</span> work ... ?
              / <span className="font-semibold">Did</span>
              <span className="font-semibold text-red-700">n't</span> she work
              ... ?
            </p>
            <p>
              <span className="font-semibold">Did</span> it{" "}
              <span className="font-semibold text-red-700">not</span> work ... ?
              / <span className="font-semibold">Did</span>
              <span className="font-semibold text-red-700">n't</span> it work
              ... ?
            </p>
            <p>
              <span className="font-semibold">Did</span> we{" "}
              <span className="font-semibold text-red-700">not</span> work ... ?
              / <span className="font-semibold">Did</span>
              <span className="font-semibold text-red-700">n't</span> we work
              ... ?
            </p>
            <p>
              <span className="font-semibold">Did</span> you{" "}
              <span className="font-semibold text-red-700">not</span> work ... ?
              / <span className="font-semibold">Did</span>
              <span className="font-semibold text-red-700">n't</span> you work
              ... ?
            </p>
            <p>
              <span className="font-semibold">Did</span> they{" "}
              <span className="font-semibold text-red-700">not</span> work ... ?
              / <span className="font-semibold">Did</span>
              <span className="font-semibold text-red-700">n't</span> they work
              ... ?
            </p>
          </div>

          <div className="pb-8">
            <h3 className="font-bold text-pink-700 uppercase mb-1">
              Short Answers
            </h3>
            <p>
              Yes, I <span className="font-semibold">did</span>. / No, I
              <span className="font-semibold"> did</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, you <span className="font-semibold">did</span>. / No, you{" "}
              <span className="font-semibold">did</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, he <span className="font-semibold">did</span>. / No, he{" "}
              <span className="font-semibold">did</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, she <span className="font-semibold">did</span>. / No, she{" "}
              <span className="font-semibold">did</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, it <span className="font-semibold">did</span>. / No, it{" "}
              <span className="font-semibold">did</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, we <span className="font-semibold">did</span>. / No, we{" "}
              <span className="font-semibold">did</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, you <span className="font-semibold">did</span>. / No, you{" "}
              <span className="font-semibold">did</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, they <span className="font-semibold">did</span>. / No, they{" "}
              <span className="font-semibold">did</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
          </div>

          <p className="flex mb-4">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              In the Past Simple tense, we use the auxiliary verb 'did' to form
              negative sentences and questions. When 'did' is used, the main
              verb returns to its base form, and we do not add '-ed' to it.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-8">
            <p className="font-semibold pb-4 italic">Examples:</p>{" "}
            <p className="flex pb-1">
              <span className="w-10 font-bold">+</span>
              <span className="italic">We stayed at home last night.</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">-</span>
              <span className="italic">We didn't stay at home last night.</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">+?</span>
              <span className="italic">Did you stay at home last night?</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">-?</span>
              <span className="italic">
                Didn't you stay at home last night? / Did you not stay at home
                last night?
              </span>
            </p>
            <p className="flex">
              <span className="w-10 font-bold"></span>
              <span className="italic">Yes, we did. / No, we didn't.</span>
            </p>
          </div>

          <p className="flex mb-4">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              When a regular verb ends in '<span className="font-bold">y</span>'
              preceded by a <span className="underline">consonant</span>, we
              change the '<span className="font-bold">y</span>' to '
              <span className="font-bold">i</span>' before adding '-ed' to form
              the Past Simple tense.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-8">
            <p className="font-semibold pb-4 italic">Examples:</p>{" "}
            <p className="pb-1 italic flex">
              <span>
                stu<span className="underline">d</span>
                <span className="font-bold">y</span>
              </span>
              <span>
                <img src={LongArrow} alt="arrow" className="w-8 mx-2 pt-1" />
              </span>
              <span>
                stu<span className="underline">d</span>
                <span className="font-bold">ied</span>
              </span>
            </p>
            <p className="pb-1 italic flex">
              <span>
                t<span className="underline">r</span>
                <span className="font-bold">y</span>
              </span>
              <span>
                <img src={LongArrow} alt="arrow" className="w-8 mx-2 pt-1" />
              </span>
              <span>
                t<span className="underline">r</span>
                <span className="font-bold">ied</span>
              </span>
            </p>
          </div>

          <p className="flex mb-4">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              If a regular verb ends in '<span className="font-bold">e</span>,'
              we just add '<span className="font-bold">-d</span>' to make the
              Past Simple tense.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-8">
            <p className="font-semibold pb-4 italic">Examples:</p>{" "}
            <p className="pb-1 italic flex">
              <span>
                decid
                <span className="underline">e</span>
              </span>
              <span>
                <img src={LongArrow} alt="arrow" className="w-8 mx-2 pt-1" />
              </span>
              <span>
                decid<span className="underline">e</span>
                <span className="font-bold">d</span>
              </span>
            </p>
            <p className="pb-1 italic flex">
              <span>
                lov<span className="underline">e</span>
              </span>
              <span>
                <img src={LongArrow} alt="arrow" className="w-8 mx-2 pt-1" />
              </span>
              <span>
                lov<span className="underline">e</span>
                <span className="font-bold">d</span>
              </span>
            </p>
          </div>

          <p className="flex mb-4">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              Another spelling rule is that for one-syllable regular verbs
              ending in a consonant-vowel-consonant (CVC) pattern, we double the
              last letter before adding "-ed".
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-8">
            <p className="font-semibold pb-4 italic">Examples:</p>{" "}
            <p className="pb-1 italic flex">
              <span>plan</span>
              <span>
                <img src={LongArrow} alt="arrow" className="w-8 mx-2 pt-1" />
              </span>
              <span>
                plan<span className="font-bold">n</span>
                ed
              </span>
            </p>
            <p className="pb-1 italic flex">
              <span>stop</span>
              <span>
                <img src={LongArrow} alt="arrow" className="w-8 mx-2 pt-1" />
              </span>
              <span>
                stop
                <span className="font-bold">p</span>ed
              </span>
            </p>
          </div>

          <p className="flex mb-4">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              For regular verbs that end in a{" "}
              <span className="font-bold">/t/</span> or{" "}
              <span className="font-bold">/d/</span> sound, the '-ed' ending
              sounds like <span className="font-bold">/ɪd/</span>.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-8">
            <p className="font-semibold pb-4 italic">Examples:</p>{" "}
            <p className="pb-1 italic flex">
              <span>decided</span>
              <span className="ml-8 not-italic">
                /dɪˈsaɪ<span className="font-bold">d</span>
                <mark>ɪd</mark>/
              </span>
            </p>
            <p className="pb-1 italic flex">
              <span>wanted</span>
              <span className="ml-8 not-italic">
                /ˈwɑːn<span className="font-bold">t</span>
                <mark>ɪd</mark>/
              </span>
            </p>
          </div>

          <p className="flex mb-4">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              For regular verbs that end in{" "}
              <span className="font-bold">/p/</span>,{" "}
              <span className="font-bold">/f/</span>,{" "}
              <span className="font-bold">/k/</span>,{" "}
              <span className="font-bold">/ʧ/</span>,{" "}
              <span className="font-bold">/ʃ/</span>, or{" "}
              <span className="font-bold">/s/</span>, the '-ed' ending sounds
              like <span className="font-bold">/t/</span>.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-8">
            <p className="font-semibold pb-6 italic">Examples:</p>{" "}
            <p className="pb-1 italic flex">
              <span>stopped</span>
              <span className="ml-8 not-italic">
                /stɑː<span className="font-bold">p</span>
                <mark>t</mark>/
              </span>
            </p>
            <p className="pb-1 italic flex">
              <span>laughed</span>
              <span className="ml-8 not-italic">
                /læ<span className="font-bold">f</span>
                <mark>t</mark>/
              </span>
            </p>
            <p className="pb-1 italic flex">
              <span>picked</span>
              <span className="ml-8 not-italic">
                /pɪ<span className="font-bold">k</span>
                <mark>t</mark>/
              </span>
            </p>
            <p className="pb-1 italic flex">
              <span>watched</span>
              <span className="ml-8 not-italic">
                /wɑː<span className="font-bold">tʃ</span>
                <mark>t</mark>/
              </span>
            </p>
            <p className="pb-1 italic flex">
              <span>washed</span>
              <span className="ml-8 not-italic">
                /wɑː<span className="font-bold">ʃ</span>
                <mark>t</mark>/
              </span>
            </p>
            <p className="pb-1 italic flex">
              <span>passed</span>
              <span className="ml-8 not-italic">
                /pæ<span className="font-bold">s</span>
                <mark>t</mark>/
              </span>
            </p>
          </div>
        </div>

        {/* ---------- Body in Farsi ---------- */}
        <div
          className={`duration-300 ${
            farsi ? "" : "hidden opacity-0"
          } my-7 mx-5 text-indigo-950 text-xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            The Simple Past <br />
            <span lang="fa" dir="rtl" className="text-2xl">
              (افعال با قاعده)
            </span>
          </h1>

          <p lang="fa" dir="rtl" className="mb-4">
            افعال با قاعده، قعل هایی هستند که در زمان Simple Past، شکل گذشته
            آنها "ed-" می گیرد.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-8">
            <p lang="fa" dir="rtl" className="font-semibold pb-6">
              مثال:
            </p>{" "}
            <p className="pb-1 italic flex">
              <span>work (v)</span>
              <span>
                <img src={LongArrow} alt="arrow" className="w-8 mx-2 pt-1" />
              </span>
              <span>worked (p)</span>
            </p>
            <p className="pb-1 italic flex">
              <span>play (v)</span>
              <span>
                <img src={LongArrow} alt="arrow" className="w-8 mx-2 pt-1" />
              </span>
              <span>played (p)</span>
            </p>
          </div>

          <p lang="fa" dir="rtl" className="mb-4">
            در ادامه ساختار های مختلف جمله در این زمان را برای فعل با قاعده work
            به عنوان مثال مشاهده می کنیم.
          </p>
          <div className="mb-8">
            <h3 className="font-bold text-pink-700 uppercase mb-1">
              <span>Positive</span>
              <span lang="fa" dir="rtl" className="ml-8">
                خبری مثبت
              </span>
            </h3>
            <p>
              I <span className="font-semibold">worked</span> ... .
            </p>
            <p>
              You <span className="font-semibold">worked</span> ... .
            </p>
            <p>
              He <span className="font-semibold">worked</span> ... .
            </p>
            <p>
              She <span className="font-semibold">worked</span> ... .
            </p>
            <p>
              It <span className="font-semibold">worked</span> ... .
            </p>
            <p>
              We <span className="font-semibold">worked</span> ... .
            </p>
            <p>
              You <span className="font-semibold">worked</span> ... .
            </p>
            <p>
              They <span className="font-semibold">worked</span> ... .
            </p>
          </div>

          <div className="mb-8">
            <h3 className="font-bold text-pink-700 uppercase mb-1">
              <span>Negative</span>
              <span lang="fa" dir="rtl" className="ml-8">
                خبری منفی
              </span>
            </h3>
            <p>
              I <span className="font-semibold">did</span>{" "}
              <span className="font-semibold text-red-700">not</span> work ... .
              / I<span className="font-semibold"> did</span>
              <span className="font-semibold text-red-700">n't</span> work ... .
            </p>
            <p>
              You <span className="font-semibold">did</span>{" "}
              <span className="font-semibold text-red-700">not</span> work ... .
              / You
              <span className="font-semibold"> did</span>
              <span className="font-semibold text-red-700">n't</span> work ... .
            </p>
            <p>
              He <span className="font-semibold">did</span>{" "}
              <span className="font-semibold text-red-700">not</span> work ... .
              / He
              <span className="font-semibold"> did</span>
              <span className="font-semibold text-red-700">n't</span> work ... .
            </p>
            <p>
              She <span className="font-semibold">did</span>{" "}
              <span className="font-semibold text-red-700">not</span> work ... .
              / She
              <span className="font-semibold"> did</span>
              <span className="font-semibold text-red-700">n't</span> work ... .
            </p>
            <p>
              It <span className="font-semibold">did</span>{" "}
              <span className="font-semibold text-red-700">not</span> work ... .
              / It
              <span className="font-semibold"> did</span>
              <span className="font-semibold text-red-700">n't</span> work ... .
            </p>
            <p>
              We <span className="font-semibold">did</span>{" "}
              <span className="font-semibold text-red-700">not</span> work ... .
              / We
              <span className="font-semibold"> did</span>
              <span className="font-semibold text-red-700">n't</span> work ... .
            </p>
            <p>
              You <span className="font-semibold">did</span>{" "}
              <span className="font-semibold text-red-700">not</span> work ... .
              / You
              <span className="font-semibold"> did</span>
              <span className="font-semibold text-red-700">n't</span> work ... .
            </p>
            <p>
              They <span className="font-semibold">did</span>{" "}
              <span className="font-semibold text-red-700">not</span> work ... .
              / They
              <span className="font-semibold"> did</span>
              <span className="font-semibold text-red-700">n't</span> work ... .
            </p>
          </div>

          <div className="mb-8">
            <h3 className="font-bold text-pink-700 uppercase mb-1">
              <span>Positive Questions</span>
              <span lang="fa" dir="rtl" className="ml-8">
                سوالی مثبت
              </span>
            </h3>
            <p>
              <span className="font-semibold">Did</span> I work ... ?
            </p>
            <p>
              <span className="font-semibold">Did</span> you work ... ?
            </p>
            <p>
              <span className="font-semibold">Did</span> he work ... ?
            </p>
            <p>
              <span className="font-semibold">Did</span> she work ... ?
            </p>
            <p>
              <span className="font-semibold">Did</span> it work ... ?
            </p>
            <p>
              <span className="font-semibold">Did</span> we work ... ?
            </p>
            <p>
              <span className="font-semibold">Did</span> you work... ?
            </p>
            <p>
              <span className="font-semibold">Did</span> they work ... ?
            </p>
          </div>

          <div className="mb-8">
            <h3 className="font-bold text-pink-700 uppercase mb-1">
              <span>Negative Questions</span>
              <span lang="fa" dir="rtl" className="ml-8">
                سوالی منفی
              </span>
            </h3>
            <p>
              <span className="font-semibold">Did</span> I{" "}
              <span className="font-semibold text-red-700">not</span> work ...?
              / <span className="font-semibold">Did</span>
              <span className="font-semibold text-red-700">n't</span> I work ...
              ?
            </p>
            <p>
              <span className="font-semibold">Did</span> you{" "}
              <span className="font-semibold text-red-700">not</span> work ... ?
              / <span className="font-semibold">Did</span>
              <span className="font-semibold text-red-700">n't</span> you work
              ... ?
            </p>
            <p>
              <span className="font-semibold">Did</span> he{" "}
              <span className="font-semibold text-red-700">not</span> work ... ?
              / <span className="font-semibold">Did</span>
              <span className="font-semibold text-red-700">n't</span> he work
              ... ?
            </p>
            <p>
              <span className="font-semibold">Did</span> she{" "}
              <span className="font-semibold text-red-700">not</span> work ... ?
              / <span className="font-semibold">Did</span>
              <span className="font-semibold text-red-700">n't</span> she work
              ... ?
            </p>
            <p>
              <span className="font-semibold">Did</span> it{" "}
              <span className="font-semibold text-red-700">not</span> work ... ?
              / <span className="font-semibold">Did</span>
              <span className="font-semibold text-red-700">n't</span> it work
              ... ?
            </p>
            <p>
              <span className="font-semibold">Did</span> we{" "}
              <span className="font-semibold text-red-700">not</span> work ... ?
              / <span className="font-semibold">Did</span>
              <span className="font-semibold text-red-700">n't</span> we work
              ... ?
            </p>
            <p>
              <span className="font-semibold">Did</span> you{" "}
              <span className="font-semibold text-red-700">not</span> work ... ?
              / <span className="font-semibold">Did</span>
              <span className="font-semibold text-red-700">n't</span> you work
              ... ?
            </p>
            <p>
              <span className="font-semibold">Did</span> they{" "}
              <span className="font-semibold text-red-700">not</span> work ... ?
              / <span className="font-semibold">Did</span>
              <span className="font-semibold text-red-700">n't</span> they work
              ... ?
            </p>
          </div>

          <div className="pb-8">
            <h3 className="font-bold text-pink-700 uppercase mb-1">
              <span>Short Answers</span>
              <span lang="fa" dir="rtl" className="ml-8">
                پاسخ کوتاه
              </span>
            </h3>
            <p>
              Yes, I <span className="font-semibold">did</span>. / No, I
              <span className="font-semibold"> did</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, you <span className="font-semibold">did</span>. / No, you{" "}
              <span className="font-semibold">did</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, he <span className="font-semibold">did</span>. / No, he{" "}
              <span className="font-semibold">did</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, she <span className="font-semibold">did</span>. / No, she{" "}
              <span className="font-semibold">did</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, it <span className="font-semibold">did</span>. / No, it{" "}
              <span className="font-semibold">did</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, we <span className="font-semibold">did</span>. / No, we{" "}
              <span className="font-semibold">did</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, you <span className="font-semibold">did</span>. / No, you{" "}
              <span className="font-semibold">did</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, they <span className="font-semibold">did</span>. / No, they{" "}
              <span className="font-semibold">did</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
          </div>

          <p lang="fa" dir="rtl" className="flex mb-4">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="mr-3">
              برای منفی و سوالی کردن جمله ها در این زمان از فعل کمکی did استفاده
              می کنیم. همانطور که مشاهده می کنید، وقتی did در جمله استفاده می
              شود فعل اصلی جمله به صورت مصدر ساده و بدون "ed-" است.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-8">
            <p lang="fa" dir="rtl" className="font-semibold pb-4">
              مثال:
            </p>{" "}
            <p className="flex pb-1">
              <span className="w-10 font-bold">+</span>
              <span className="italic">We stayed at home last night.</span>
            </p>
            <p lang="fa" dir="rtl" className="mb-2">
              ما دیشب خانه ماندیم.
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">-</span>
              <span className="italic">We didn't stay at home last night.</span>
            </p>
            <p lang="fa" dir="rtl" className="mb-2">
              دیشب خانه نماندیم.
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">+?</span>
              <span className="italic">Did you stay at home last night?</span>
            </p>
            <p lang="fa" dir="rtl" className="mb-2">
              آیا دیشب خانه ماندید؟
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">-?</span>
              <span className="italic">
                Didn't you stay at home last night? / Did you not stay at home
                last night?
              </span>
            </p>
            <p lang="fa" dir="rtl" className="mb-2">
              آیا دیشب خانه نماندید؟
            </p>
            <p className="flex">
              <span className="w-10 font-bold"></span>
              <span className="italic">Yes, we did. / No, we didn't.</span>
            </p>
          </div>

          <p lang="fa" dir="rtl" className="flex mb-4">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="mr-3">
              در فعل های با قاعده ای که به حرف "y" بعد از یک حرف صامت ختم می
              شوند، در هنگام اضافه شدن "ed-" در زمان Simple Past، حرف "y" به "i"
              تبدیل می شود.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-8">
            <p className="font-semibold pb-4 italic">Examples:</p>{" "}
            <p className="pb-1 italic flex">
              <span>
                stu<span className="underline">d</span>
                <span className="font-bold">y</span>
              </span>
              <span>
                <img src={LongArrow} alt="arrow" className="w-8 mx-2 pt-1" />
              </span>
              <span>
                stu<span className="underline">d</span>
                <span className="font-bold">ied</span>
              </span>
            </p>
            <p className="pb-1 italic flex">
              <span>
                t<span className="underline">r</span>
                <span className="font-bold">y</span>
              </span>
              <span>
                <img src={LongArrow} alt="arrow" className="w-8 mx-2 pt-1" />
              </span>
              <span>
                t<span className="underline">r</span>
                <span className="font-bold">ied</span>
              </span>
            </p>
          </div>

          <p lang="fa" dir="rtl" className="flex mb-4">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="mr-3">
              در فعل های با قاعده ای که به حرف "e" ختم می شوند، در زمان Simple
              Past فقط یک "d-" به انتهای فعل اضافه می کنیم.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-8">
            <p className="font-semibold pb-4 italic">Examples:</p>{" "}
            <p className="pb-1 italic flex">
              <span>
                decid
                <span className="underline">e</span>
              </span>
              <span>
                <img src={LongArrow} alt="arrow" className="w-8 mx-2 pt-1" />
              </span>
              <span>
                decid<span className="underline">e</span>
                <span className="font-bold">d</span>
              </span>
            </p>
            <p className="pb-1 italic flex">
              <span>
                lov<span className="underline">e</span>
              </span>
              <span>
                <img src={LongArrow} alt="arrow" className="w-8 mx-2 pt-1" />
              </span>
              <span>
                lov<span className="underline">e</span>
                <span className="font-bold">d</span>
              </span>
            </p>
          </div>

          <p lang="fa" dir="rtl" className="flex mb-4">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="mr-3">
              قانون املایی دیگر مربوط به فعل های باقاعده تک سیلابی ای است که به
              یک حرف صامت + یک حرف مصوت + یک حرف صامت ختم می شوند. وقتی در زمان
              Simple Past این فعل ها "ed-" می گیرند، حرف آخرشان تکرار می شود.
              لازم به ذکر است که این قانون برای فعل هایی که به یک از حرف های "w"
              ،"x" و "y" ختم می شوند صدق نمی کند.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-8">
            <p lang="fa" dir="rtl" className="font-semibold pb-4">
              مثال:
            </p>{" "}
            <p className="pb-1 italic flex">
              <span>plan</span>
              <span>
                <img src={LongArrow} alt="arrow" className="w-8 mx-2 pt-1" />
              </span>
              <span>
                plan<span className="font-bold">n</span>
                ed
              </span>
            </p>
            <p className="pb-1 italic flex">
              <span>stop</span>
              <span>
                <img src={LongArrow} alt="arrow" className="w-8 mx-2 pt-1" />
              </span>
              <span>
                stop
                <span className="font-bold">p</span>ed
              </span>
            </p>
            <p lang="fa" dir="rtl" className="my-2">
              اما
            </p>
            <p className="pb-1 italic flex">
              <span>show</span>
              <span>
                <img src={LongArrow} alt="arrow" className="w-8 mx-2 pt-1" />
              </span>
              <span>showed</span>
            </p>
            <p className="pb-1 italic flex">
              <span>fix</span>
              <span>
                <img src={LongArrow} alt="arrow" className="w-8 mx-2 pt-1" />
              </span>
              <span>fixed</span>
            </p>
            <p className="pb-1 italic flex">
              <span>play</span>
              <span>
                <img src={LongArrow} alt="arrow" className="w-8 mx-2 pt-1" />
              </span>
              <span>played</span>
            </p>
          </div>

          <p lang="fa" dir="rtl" className="flex mb-4">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="mr-3">
              در افعال باقاعده ای که به صدای /t/ یا /d/ ختم می شوند، "ed-" صدای
              /ɪd/ می دهد.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-8">
            <p lang="fa" dir="rtl" className="font-semibold pb-4">
              مثال:
            </p>{" "}
            <p className="pb-1 italic flex">
              <span>decided</span>
              <span className="ml-8 not-italic">
                /dɪˈsaɪ<span className="font-bold">d</span>
                <mark>ɪd</mark>/
              </span>
            </p>
            <p className="pb-1 italic flex">
              <span>wanted</span>
              <span className="ml-8 not-italic">
                /ˈwɑːn<span className="font-bold">t</span>
                <mark>ɪd</mark>/
              </span>
            </p>
          </div>

          <p lang="fa" dir="rtl" className="flex mb-4">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="mr-3">
              در افعال باقاعده ای که به یکی از صداهای /p/ ،/f/ ،/k/ ،/ʧ/ ،/ʃ/ یا
              /s/ ختم می شوند، "ed-" صدای /t/ می دهد.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-8">
            <p lang="fa" dir="rtl" className="font-semibold pb-6">
              مثال:
            </p>{" "}
            <p className="pb-1 italic flex">
              <span>stopped</span>
              <span className="ml-8 not-italic">
                /stɑː<span className="font-bold">p</span>
                <mark>t</mark>/
              </span>
            </p>
            <p className="pb-1 italic flex">
              <span>laughed</span>
              <span className="ml-8 not-italic">
                /læ<span className="font-bold">f</span>
                <mark>t</mark>/
              </span>
            </p>
            <p className="pb-1 italic flex">
              <span>picked</span>
              <span className="ml-8 not-italic">
                /pɪ<span className="font-bold">k</span>
                <mark>t</mark>/
              </span>
            </p>
            <p className="pb-1 italic flex">
              <span>watched</span>
              <span className="ml-8 not-italic">
                /wɑː<span className="font-bold">tʃ</span>
                <mark>t</mark>/
              </span>
            </p>
            <p className="pb-1 italic flex">
              <span>washed</span>
              <span className="ml-8 not-italic">
                /wɑː<span className="font-bold">ʃ</span>
                <mark>t</mark>/
              </span>
            </p>
            <p className="pb-1 italic flex">
              <span>passed</span>
              <span className="ml-8 not-italic">
                /pæ<span className="font-bold">s</span>
                <mark>t</mark>/
              </span>
            </p>
          </div>
        </div>
      </div>
      <ExerciseCard questions={questions} instruction={instruction} />
    </>
  );
};
