import { useState } from "react";
import { Link } from "react-router-dom";
import { LanguageSwitch, ExerciseCard, useTitle } from "../../../components";
import Bulb from "../../../assets/images/bulb.png";
import LongArrow from "../../../assets/images/arrowLongLight.svg";

export const PresentSimpleElementary = ({ title }) => {
  const [farsi, setFarsi] = useState(false);
  const instruction = (
    <span>
      Complete the sentences using the correct form of the verbs in parentheses.
    </span>
  );
  const questions = [
    {
      id: 1,
      question: (
        <span>
          <span>"What ____ your husband ____?" (do)</span>
          <span className="ml-8">
            "He's an engineer. He ____ for a big mulitnational company." (work)
          </span>
        </span>
      ),
      answer: (
        <span className="font-normal">
          <span>
            "What <span className="font-bold">does</span> your husband{" "}
            <span className="font-bold">do</span>?"
          </span>
          <span className="ml-8">
            "He's an engineer. He <span className="font-bold">works</span> for a
            big multinational company."
          </span>
        </span>
      ),
    },
    {
      id: 2,
      question: (
        <span>
          <span>"Do you ____ your job?" (like)</span>
          <span className="pl-8">"Yes, I ____. I ____ my job." (love)</span>
        </span>
      ),
      answer: (
        <span>
          <span>
            "Do you <span className="font-bold">like</span> your job?
          </span>
          <span className="pl-8">
            "Yes, I <span className="font-bold">do</span>. I{" "}
            <span className="font-bold">love</span> my job."
          </span>
        </span>
      ),
    },
    {
      id: 3,
      question: (
        <span>
          <span>"When ____ your children ____ to bed?" (go)</span>
          <span className="ml-8">
            "My daughter ____ to bed very late, but my son ____. He usually ____
            at about 10 p.m." (go / sleep)
          </span>
        </span>
      ),
      answer: (
        <span>
          <span>
            "When <span className="font-bold">do</span> your children{" "}
            <span className="font-bold">go</span> to bed?"
          </span>
          <span className="ml-8">
            "My daughter <span className="font-bold">goes</span> to bed very
            late, but my son <span className="font-bold">doesn't</span>. He
            usually <span className="font-bold">sleeps</span> at 10 p.m."
          </span>
        </span>
      ),
    },
    {
      id: 4,
      question: (
        <span>
          My neighbor has a dog. It ____ a lot, but it's very friendly. We ____
          a dog ourselves, so my children usually ____ with it. (bark / have /
          play)
        </span>
      ),
      answer: (
        <span>
          My neighbor has a dog. It <span className="font-bold">barks</span> a
          lot, but it's very friendly. We{" "}
          <span className="font-bold">don't have</span> a dog ourselves, so my
          children usually <span className="font-bold">play</span> with it.
        </span>
      ),
    },
  ];

  useTitle(title);

  return (
    <>
      {/* -------------------- Breadcrumb Menu -------------------- */}
      <div data-aos="fade" data-aos-duration="1500">
        <nav className="flex pl-3 pt-2 text-gray-400 text-sm font-semibold">
          <Link to="/" className="flex duration-300 hover:text-indigo-950">
            <span className="pt-0.5 md:pr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
            </span>
            <span className="max-md:hidden">Home</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link to="/grammar" className="duration-300 hover:text-indigo-950">
            <span>Grammar</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link
            to="/grammar/elementary"
            className="duration-300 hover:text-indigo-950"
          >
            <span>A1 - A2</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <span className="text-indigo-950">Present Simple</span>
        </nav>
      </div>

      <div data-aos="zoom-in" data-aos-duration="800">
        {/* -------------------- Language Switch -------------------- */}
        <LanguageSwitch farsi={farsi} setFarsi={setFarsi} />

        {/* -------------------- Body in English -------------------- */}
        <div
          className={`duration-300 ${
            farsi ? "hidden opacity-0" : ""
          } my-7 mx-5 text-indigo-950 text-xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            The Present Simple
          </h1>
          <div className="mb-16">
            <p className="mb-2">
              We use the Present Simple tense to talk about:
            </p>
            <div className="flex">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  fill="#BE185D"
                  className="bi bi-dot"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                </svg>
              </span>
              <span className="mt-1">habits and routines</span>
            </div>

            <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-4">
              <p className="font-semibold pb-6 italic">Examples:</p>{" "}
              <p className="pb-1 italic">
                She usually <span className="font-bold">wakes up</span> at 7
                o'clock in the morning.
              </p>
              <p className="pb-1 italic">
                I <span className="font-bold">go</span> to the gym three times a
                week.
              </p>
            </div>
            <div className="flex">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  fill="#BE185D"
                  className="bi bi-dot"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                </svg>
              </span>
              <span className="mt-1">(natural) facts</span>
            </div>
            <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-4">
              <p className="font-semibold pb-6 italic">Examples:</p>{" "}
              <p className="pb-1 italic">
                Water <span className="font-bold">boils</span> at 100 degrees
                Celsius.
              </p>
              <p className="pb-1 italic">
                The Earth <span className="font-bold">moves</span> around the
                Sun.
              </p>
            </div>
            <div className="flex">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  fill="#BE185D"
                  className="bi bi-dot"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                </svg>
              </span>
              <span className="mt-1">permanent situations</span>
            </div>
            <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-4">
              <p className="font-semibold pb-6 italic">Examples:</p>{" "}
              <p className="pb-1 italic">
                We <span className="font-bold">come</span> from Iran.
              </p>
              <p className="pb-1 italic">
                He <span className="font-bold">has</span> two sisters.
              </p>
            </div>
          </div>

          <p className="mb-9">
            Let's take a look at the different forms of the Present Simple tense
            for the verb "work".
          </p>
          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase">Positive</h3>
            <p>
              I <span className="font-semibold">work</span> ... .
            </p>
            <p>
              You <span className="font-semibold">work</span> ... .
            </p>
            <p>
              He{" "}
              <span className="font-semibold">
                work<span className="underline">s</span>
              </span>{" "}
              ... .
            </p>
            <p>
              She{" "}
              <span className="font-semibold">
                work<span className="underline">s</span>
              </span>{" "}
              ... .
            </p>
            <p>
              It{" "}
              <span className="font-semibold">
                work<span className="underline">s</span>
              </span>{" "}
              ... .
            </p>
            <p>
              We <span className="font-semibold">work</span> ... .
            </p>
            <p>
              You <span className="font-semibold">work</span> ... .
            </p>
            <p>
              They <span className="font-semibold">work</span> ... .
            </p>
          </div>

          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase">Negative</h3>
            <p>
              I <span className="font-semibold">do</span>{" "}
              <span className="font-semibold text-red-700">not</span> work ... .
              / I<span className="font-semibold"> do</span>
              <span className="font-semibold text-red-700">n't</span> work ... .
            </p>
            <p>
              You <span className="font-semibold">do</span>{" "}
              <span className="font-semibold text-red-700">not</span> work ... .
              / You
              <span className="font-semibold"> do</span>
              <span className="font-semibold text-red-700">n't</span> work ... .
            </p>
            <p>
              He <span className="font-semibold">does</span>{" "}
              <span className="font-semibold text-red-700">not</span> work ... .
              / He
              <span className="font-semibold"> does</span>
              <span className="font-semibold text-red-700">n't</span> work ... .
            </p>
            <p>
              She <span className="font-semibold">does</span>{" "}
              <span className="font-semibold text-red-700">not</span> work ... .
              / She
              <span className="font-semibold"> does</span>
              <span className="font-semibold text-red-700">n't</span> work ... .
            </p>
            <p>
              It <span className="font-semibold">does</span>{" "}
              <span className="font-semibold text-red-700">not</span> work ... .
              / It
              <span className="font-semibold"> does</span>
              <span className="font-semibold text-red-700">n't</span> work ... .
            </p>
            <p>
              We <span className="font-semibold">do</span>{" "}
              <span className="font-semibold text-red-700">not</span> work ... .
              / We
              <span className="font-semibold"> do</span>
              <span className="font-semibold text-red-700">n't</span> work ... .
            </p>
            <p>
              You <span className="font-semibold">do</span>{" "}
              <span className="font-semibold text-red-700">not</span> work ... .
              / You
              <span className="font-semibold"> do</span>
              <span className="font-semibold text-red-700">n't</span> work ... .
            </p>
            <p>
              They <span className="font-semibold">do</span>{" "}
              <span className="font-semibold text-red-700">not</span> work ... .
              / They
              <span className="font-semibold"> do</span>
              <span className="font-semibold text-red-700">n't</span> work ... .
            </p>
          </div>

          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase">
              Positive Questions
            </h3>
            <p>
              <span className="font-semibold">Do</span> I work ... ?
            </p>
            <p>
              <span className="font-semibold">Do</span> you work ... ?
            </p>
            <p>
              <span className="font-semibold">Does</span> he work ... ?
            </p>
            <p>
              <span className="font-semibold">Does</span> she work ... ?
            </p>
            <p>
              <span className="font-semibold">Does</span> it work ... ?
            </p>
            <p>
              <span className="font-semibold">Do</span> we work ... ?
            </p>
            <p>
              <span className="font-semibold">Do</span> you work... ?
            </p>
            <p>
              <span className="font-semibold">Do</span> they work ... ?
            </p>
          </div>

          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase">
              Negative Questions
            </h3>
            <p>
              <span className="font-semibold">Do</span> I{" "}
              <span className="font-semibold text-red-700">not</span> work ...?
              / <span className="font-semibold">Do</span>
              <span className="font-semibold text-red-700">n't</span> I work ...
              ?
            </p>
            <p>
              <span className="font-semibold">Do</span> you{" "}
              <span className="font-semibold text-red-700">not</span> work ... ?
              / <span className="font-semibold">Do</span>
              <span className="font-semibold text-red-700">n't</span> you work
              ... ?
            </p>
            <p>
              <span className="font-semibold">Does</span> he{" "}
              <span className="font-semibold text-red-700">not</span> work ... ?
              / <span className="font-semibold">Does</span>
              <span className="font-semibold text-red-700">n't</span> he work
              ... ?
            </p>
            <p>
              <span className="font-semibold">Does</span> she{" "}
              <span className="font-semibold text-red-700">not</span> work ... ?
              / <span className="font-semibold">Does</span>
              <span className="font-semibold text-red-700">n't</span> she work
              ... ?
            </p>
            <p>
              <span className="font-semibold">Does</span> it{" "}
              <span className="font-semibold text-red-700">not</span> work ... ?
              / <span className="font-semibold">Does</span>
              <span className="font-semibold text-red-700">n't</span> it work
              ... ?
            </p>
            <p>
              <span className="font-semibold">Do</span> we{" "}
              <span className="font-semibold text-red-700">not</span> work ... ?
              / <span className="font-semibold">Do</span>
              <span className="font-semibold text-red-700">n't</span> we work
              ... ?
            </p>
            <p>
              <span className="font-semibold">Do</span> you{" "}
              <span className="font-semibold text-red-700">not</span> work ... ?
              / <span className="font-semibold">Do</span>
              <span className="font-semibold text-red-700">n't</span> you work
              ... ?
            </p>
            <p>
              <span className="font-semibold">Do</span> they{" "}
              <span className="font-semibold text-red-700">not</span> work ... ?
              / <span className="font-semibold">Do</span>
              <span className="font-semibold text-red-700">n't</span> they work
              ... ?
            </p>
          </div>

          <div className="pb-9">
            <h3 className="font-bold text-pink-700 uppercase">Short Answers</h3>
            <p>
              Yes, I <span className="font-semibold">do</span>. / No, I
              <span className="font-semibold"> do</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, you <span className="font-semibold">do</span>. / No, you{" "}
              <span className="font-semibold">do</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, he <span className="font-semibold">does</span>. / No, he{" "}
              <span className="font-semibold">does</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, she <span className="font-semibold">does</span>. / No, she{" "}
              <span className="font-semibold">does</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, it <span className="font-semibold">does</span>. / No, it{" "}
              <span className="font-semibold">does</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, we <span className="font-semibold">do</span>. / No, we{" "}
              <span className="font-semibold">do</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, you <span className="font-semibold">do</span>. / No, you{" "}
              <span className="font-semibold">do</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, they <span className="font-semibold">do</span>. / No, they{" "}
              <span className="font-semibold">do</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
          </div>

          <p className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              As you can see, we use the auxiliary verbs "do" or "does" to make
              negative sentences and questions in the Present Simple tense.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-9">
            <p className="font-semibold pb-6 italic">Examples:</p>{" "}
            <p className="flex pb-1">
              <span className="w-10 font-bold">+</span>
              <span className="italic">She works for a big company.</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">-</span>
              <span className="italic">
                She doesn't work for a big company.
              </span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">+?</span>
              <span className="italic">Does she work for a big company?</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">-?</span>
              <span className="italic">
                Doesn't she work for a big company? / Does she not work for a
                big company?
              </span>
            </p>
            <p className="flex pb-6">
              <span className="w-10 font-bold"></span>
              <span className="italic">Yes, she does. / No, she doesn't.</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">+</span>
              <span className="italic">They live in a modern apartment.</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">-</span>
              <span className="italic">
                They don't live in a modern apartment.
              </span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">+?</span>
              <span className="italic">
                Do they live in a modern apartment?
              </span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">-?</span>
              <span className="italic">
                Don't they live in a modern apartment? / Do they not live in a
                modern apartment?
              </span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold"></span>
              <span className="italic">Yes, they do. / No, they don't.</span>
            </p>
          </div>

          <p className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              As you can see, when making the Simple Present tense with a third
              person singular subject (he / she / it), the general rule is to
              add an "s" to the base form of the verb. However, if the verb ends
              in "<span className="font-bold">s</span>", "
              <span className="font-bold">x</span>", "
              <span className="font-bold">z</span>", "
              <span className="font-bold">sh</span>", or "
              <span className="font-bold">ch</span>", we add "
              <span className="font-bold">es</span>" instead.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-4">
            <p className="font-semibold pb-6 italic">Examples:</p>{" "}
            <p className="pb-1 italic">
              She tea<span className="underline">ch</span>
              <span className="font-bold">es</span> physics.
            </p>
            <p className="pb-1 italic">
              He usually wa<span className="underline">sh</span>
              <span className="font-bold">es</span> the dishes in the evening.
            </p>
            <p className="pb-1 italic">
              My mechanic always fi<span className="underline">x</span>
              <span className="font-bold">es</span> my car very well.
            </p>
            <p className="pb-1 italic">
              Their doorbell bu<span className="underline">zz</span>
              <span className="font-bold">es</span> loudly.
            </p>
            <p className="pb-1 italic">
              He ki<span className="underline">ss</span>
              <span className="font-bold">es</span> his children when he comes
              back home.
            </p>
          </div>

          <p className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              Also, when the verb in a sentence ends in a "
              <span className="font-bold">y</span>" which is preceded by a{" "}
              <span className="underline">consonant</span>, with a third person
              singular subject (he / she / it), we change the final "
              <span className="font-bold">y</span>" to "
              <span className="font-bold">i</span>" and add "
              <span className="font-bold">es</span>" to the end of the verb.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-4">
            <p className="font-semibold pb-6 italic">Examples:</p>{" "}
            <p className="pb-1 italic flex">
              <span>
                stu<span className="underline">d</span>
                <span className="font-bold">y</span>
              </span>
              <span>
                <img src={LongArrow} alt="arrow" className="w-8 mx-2 pt-1" />
              </span>
              <span>
                stu<span className="underline">d</span>
                <span className="font-bold">ies</span>
              </span>
            </p>
            <p className="pb-1 italic flex">
              <span>
                t<span className="underline">r</span>
                <span className="font-bold">y</span>
              </span>
              <span>
                <img src={LongArrow} alt="arrow" className="w-8 mx-2 pt-1" />
              </span>
              <span>
                t<span className="underline">r</span>
                <span className="font-bold">ies</span>
              </span>
            </p>
          </div>
        </div>

        {/* ---------- Body in Farsi ---------- */}
        <div
          className={`duration-300 ${
            farsi ? "" : "hidden opacity-0"
          } my-7 mx-5 text-indigo-950 text-xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            The Present Simple
          </h1>

          <p lang="fa" dir="rtl" className="mb-9">
            در شرایط زیر فعل جمله از این زمان پیروی می کند:
          </p>
          <div lang="fa" dir="rtl" className="flex">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot pb-1"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="mt-1 -mr-2">
              عادت ها و کارهای تکراری روزمره یا روتین
            </span>
          </div>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-4">
            <p lang="fa" dir="rtl" className="font-semibold pb-6">
              مثال:
            </p>{" "}
            <p className="pb-1 italic">
              She usually <span className="font-bold">wakes up</span> at 7
              o'clock in the morning.
            </p>
            <p lang="fa" dir="rtl" className="pb-1">
              او معمولا ساعت هفت صبح از خواب بیدار می شود.{" "}
            </p>
            <p className="pb-1 italic">
              I <span className="font-bold">go</span> to the gym three times a
              week.
            </p>
            <p lang="fa" dir="rtl" className="pb-1">
              من هفته ای سه بار می روم باشگاه.
            </p>
          </div>
          <div lang="fa" dir="rtl" className="flex">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot pb-1"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="mt-1 -mr-2">حقیقت های علمی یا طبیعی</span>
          </div>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-4">
            <p lang="fa" dir="rtl" className="font-semibold pb-6">
              مثال:
            </p>{" "}
            <p className="pb-1 italic">
              Water <span className="font-bold">boils</span> at 100 degrees
              Celsius.
            </p>
            <p lang="fa" dir="rtl" className="pb-1">
              آب در دمای صد درجه سانتیگراد می جوشد.
            </p>
            <p className="pb-1 italic">
              The Earth <span className="font-bold">moves</span> around the Sun.
            </p>
            <p lang="fa" dir="rtl" className="pb-1">
              زمین به دور خورشید می گردد.
            </p>
          </div>
          <div lang="fa" dir="rtl" className="flex">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot pb-1"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="mt-1 -mr-2">شرایط دائمی یا ابدی</span>
          </div>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-16">
            <p lang="fa" dir="rtl" className="font-semibold pb-6">
              مثال:
            </p>{" "}
            <p className="pb-1 italic">
              We <span className="font-bold">come</span> from Iran.
            </p>
            <p lang="fa" dir="rtl" className="pb-1">
              ما ایرانی هستیم.
            </p>
            <p className="pb-1 italic">
              He <span className="font-bold">has</span> two sisters.
            </p>
            <p lang="fa" dir="rtl" className="pb-1">
              او دو خواهر دارد.
            </p>
          </div>
          <p lang="fa" dir="rtl" className="mb-9">
            در ادامه شکل های مختلف این زمان را برای فعل work مشاهده می کنید.
          </p>
          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase">
              Positive{" "}
              <span lang="fa" dir="rtl" className="ml-5">
                خبری مثبت
              </span>
            </h3>
            <p>
              I <span className="font-semibold">work</span> ... .
            </p>
            <p>
              You <span className="font-semibold">work</span> ... .
            </p>
            <p>
              He{" "}
              <span className="font-semibold">
                work<span className="underline">s</span>
              </span>{" "}
              ... .
            </p>
            <p>
              She{" "}
              <span className="font-semibold">
                work<span className="underline">s</span>
              </span>{" "}
              ... .
            </p>
            <p>
              It{" "}
              <span className="font-semibold">
                work<span className="underline">s</span>
              </span>{" "}
              ... .
            </p>
            <p>
              We <span className="font-semibold">work</span> ... .
            </p>
            <p>
              You <span className="font-semibold">work</span> ... .
            </p>
            <p>
              They <span className="font-semibold">work</span> ... .
            </p>
          </div>
          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase">
              Negative{" "}
              <span lang="fa" dir="rtl" className="ml-5">
                خبری منفی
              </span>
            </h3>
            <p>
              I <span className="font-semibold">do</span>{" "}
              <span className="font-semibold text-red-700">not</span> work ... .
              / I<span className="font-semibold"> do</span>
              <span className="font-semibold text-red-700">n't</span> work ... .
            </p>
            <p>
              You <span className="font-semibold">do</span>{" "}
              <span className="font-semibold text-red-700">not</span> work ... .
              / You
              <span className="font-semibold"> do</span>
              <span className="font-semibold text-red-700">n't</span> work ... .
            </p>
            <p>
              He <span className="font-semibold">does</span>{" "}
              <span className="font-semibold text-red-700">not</span> work ... .
              / He
              <span className="font-semibold"> does</span>
              <span className="font-semibold text-red-700">n't</span> work ... .
            </p>
            <p>
              She <span className="font-semibold">does</span>{" "}
              <span className="font-semibold text-red-700">not</span> work ... .
              / She
              <span className="font-semibold"> does</span>
              <span className="font-semibold text-red-700">n't</span> work ... .
            </p>
            <p>
              It <span className="font-semibold">does</span>{" "}
              <span className="font-semibold text-red-700">not</span> work ... .
              / It
              <span className="font-semibold"> does</span>
              <span className="font-semibold text-red-700">n't</span> work ... .
            </p>
            <p>
              We <span className="font-semibold">do</span>{" "}
              <span className="font-semibold text-red-700">not</span> work ... .
              / We
              <span className="font-semibold"> do</span>
              <span className="font-semibold text-red-700">n't</span> work ... .
            </p>
            <p>
              You <span className="font-semibold">do</span>{" "}
              <span className="font-semibold text-red-700">not</span> work ... .
              / You
              <span className="font-semibold"> do</span>
              <span className="font-semibold text-red-700">n't</span> work ... .
            </p>
            <p>
              They <span className="font-semibold">do</span>{" "}
              <span className="font-semibold text-red-700">not</span> work ... .
              / They
              <span className="font-semibold"> do</span>
              <span className="font-semibold text-red-700">n't</span> work ... .
            </p>
          </div>

          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase">
              Positive Questions{" "}
              <span lang="fa" dir="rtl" className="ml-5">
                سوالی مثبت
              </span>
            </h3>
            <p>
              <span className="font-semibold">Do</span> I work ... ?
            </p>
            <p>
              <span className="font-semibold">Do</span> you work ... ?
            </p>
            <p>
              <span className="font-semibold">Does</span> he work ... ?
            </p>
            <p>
              <span className="font-semibold">Does</span> she work ... ?
            </p>
            <p>
              <span className="font-semibold">Does</span> it work ... ?
            </p>
            <p>
              <span className="font-semibold">Do</span> we work ... ?
            </p>
            <p>
              <span className="font-semibold">Do</span> you work... ?
            </p>
            <p>
              <span className="font-semibold">Do</span> they work ... ?
            </p>
          </div>

          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase">
              Negative Questions{" "}
              <span lang="fa" dir="rtl" className="ml-5">
                سوالی منفی
              </span>
            </h3>
            <p>
              <span className="font-semibold">Do</span> I{" "}
              <span className="font-semibold text-red-700">not</span> work ...?
              / <span className="font-semibold">Do</span>
              <span className="font-semibold text-red-700">n't</span> I work ...
              ?
            </p>
            <p>
              <span className="font-semibold">Do</span> you{" "}
              <span className="font-semibold text-red-700">not</span> work ... ?
              / <span className="font-semibold">Do</span>
              <span className="font-semibold text-red-700">n't</span> you work
              ... ?
            </p>
            <p>
              <span className="font-semibold">Does</span> he{" "}
              <span className="font-semibold text-red-700">not</span> work ... ?
              / <span className="font-semibold">Does</span>
              <span className="font-semibold text-red-700">n't</span> he work
              ... ?
            </p>
            <p>
              <span className="font-semibold">Does</span> she{" "}
              <span className="font-semibold text-red-700">not</span> work ... ?
              / <span className="font-semibold">Does</span>
              <span className="font-semibold text-red-700">n't</span> she work
              ... ?
            </p>
            <p>
              <span className="font-semibold">Does</span> it{" "}
              <span className="font-semibold text-red-700">not</span> work ... ?
              / <span className="font-semibold">Does</span>
              <span className="font-semibold text-red-700">n't</span> it work
              ... ?
            </p>
            <p>
              <span className="font-semibold">Do</span> we{" "}
              <span className="font-semibold text-red-700">not</span> work ... ?
              / <span className="font-semibold">Do</span>
              <span className="font-semibold text-red-700">n't</span> we work
              ... ?
            </p>
            <p>
              <span className="font-semibold">Do</span> you{" "}
              <span className="font-semibold text-red-700">not</span> work ... ?
              / <span className="font-semibold">Do</span>
              <span className="font-semibold text-red-700">n't</span> you work
              ... ?
            </p>
            <p>
              <span className="font-semibold">Do</span> they{" "}
              <span className="font-semibold text-red-700">not</span> work ... ?
              / <span className="font-semibold">Do</span>
              <span className="font-semibold text-red-700">n't</span> they work
              ... ?
            </p>
          </div>

          <div className="pb-9">
            <h3 className="font-bold text-pink-700 uppercase">
              Short Answers{" "}
              <span lang="fa" dir="rtl" className="ml-5">
                پاسخ کوتاه
              </span>
            </h3>
            <p>
              Yes, I <span className="font-semibold">do</span>. / No, I
              <span className="font-semibold"> do</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, you <span className="font-semibold">do</span>. / No, you{" "}
              <span className="font-semibold">do</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, he <span className="font-semibold">does</span>. / No, he{" "}
              <span className="font-semibold">does</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, she <span className="font-semibold">does</span>. / No, she{" "}
              <span className="font-semibold">does</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, it <span className="font-semibold">does</span>. / No, it{" "}
              <span className="font-semibold">does</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, we <span className="font-semibold">do</span>. / No, we{" "}
              <span className="font-semibold">do</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, you <span className="font-semibold">do</span>. / No, you{" "}
              <span className="font-semibold">do</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, they <span className="font-semibold">do</span>. / No, they{" "}
              <span className="font-semibold">do</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
          </div>
          <p lang="fa" dir="rtl" className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pr-3">
              همانطور که مشاهده می کنید، برای منفی و سوالی کردن جملات در این
              زمان از فعل کمکی do یا does استفاده می کنیم.
            </span>
          </p>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-6">
              مثال:
            </p>{" "}
            <p className="flex">
              <span className="w-10 font-bold">+</span>
              <span className="italic">She works for a big company.</span>
            </p>
            <p lang="fa" dir="rtl" className="pb-1">
              او برای یک شرکت بزرگ کار می کند.
            </p>
            <p className="flex">
              <span className="w-10 font-bold">-</span>
              <span className="italic">
                She doesn't work for a big company.
              </span>
            </p>
            <p lang="fa" dir="rtl" className="pb-1">
              او برای یک شرکت بزرگ کار نمی کند.
            </p>
            <p className="flex">
              <span className="w-10 font-bold">+?</span>
              <span className="italic">Does she work for a big company?</span>
            </p>
            <p lang="fa" dir="rtl" className="pb-1">
              آیا او برای یک شرکت بزرگ کار می کند؟
            </p>
            <p className="flex">
              <span className="w-10 font-bold">-?</span>
              <span className="italic w-fit">
                Doesn't she work for a big company? / Does she not work for a
                big company?
              </span>
            </p>
            <p lang="fa" dir="rtl" className="pb-1">
              آیا او برای یک شرکت بزرگ کار نمی کند؟
            </p>
            <p className="flex">
              <span className="w-10 font-bold"></span>
              <span className="italic">Yes, she does. / No, she doesn't.</span>
            </p>
            <p lang="fa" dir="rtl" className="pb-16">
              بله، او برای یک شرکت بزرگ کار می کند. / نه، او برای یک شرکت بزرگ
              کار نمی کند.
            </p>
            <p className="flex">
              <span className="w-10 font-bold">+</span>
              <span className="italic">They live in a modern apartment.</span>
            </p>
            <p lang="fa" dir="rtl" className="pb-1">
              آنها در یک آپارتمان مدرن زندگی می کنند.
            </p>
            <p className="flex">
              <span className="w-10 font-bold">-</span>
              <span className="italic">
                They don't live in a modern apartment.
              </span>
            </p>
            <p lang="fa" dir="rtl" className="pb-1">
              آنها در یک آپارتمان مدرن زندگی نمی کنند.
            </p>
            <p className="flex">
              <span className="w-10 font-bold">+?</span>
              <span className="italic">
                Do they live in a modern apartment?
              </span>
            </p>
            <p lang="fa" dir="rtl" className="pb-1">
              آیا آنها در یک آپارتمان مدرن زندگی می کنند؟
            </p>
            <p className="flex">
              <span className="w-10 font-bold">-?</span>
              <span className="italic w-fit">
                Don't they live in a modern apartment? / Do they not live in a
                modern apartment?
              </span>
            </p>
            <p lang="fa" dir="rtl" className="pb-1">
              آیا آنها در یک آپارتمان مدرن زندگی نمی کنند؟
            </p>
            <p className="flex">
              <span className="w-10 font-bold"></span>
              <span className="italic">Yes, they do. / No, they don't.</span>
            </p>
            <p lang="fa" dir="rtl" className="pb-1">
              بله، آنها در یک آپارتمان مدرن زندگی می کنند. // نه، آنها در یک
              آپارتمان مدرن زندگی نمی کنند.
            </p>
          </div>

          <p lang="fa" dir="rtl" className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              همانطور که مشاهده می کنید، قاعده کلی این است که برای فاعل های سوم
              شخص مفرد، یعنی he ،she و it به انتهای فعل جمله یک s سوم شخص اضافه
              می کنیم. اما اگر فعل جمله به یکی از صداهای حرف های ch ،sh ،s ،x یا
              z ختم شود، با فاعل های سوم شخص مفرد به انتهای فعل es اضافه می
              کنیم.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-4">
            <p className="font-semibold pb-6 italic">Examples:</p>{" "}
            <p className="pb-1 italic">
              She tea<span className="underline">ch</span>
              <span className="font-bold">es</span> physics.
            </p>
            <p lang="fa" dir="rtl" className="mb-4">
              او فیزیک درس می دهد.
            </p>
            <p className="pb-1 italic">
              He usually wa<span className="underline">sh</span>
              <span className="font-bold">es</span> the dishes in the evening.
            </p>
            <p lang="fa" dir="rtl" className="mb-4">
              او معمولا شب ها ظرف ها را می شورد.
            </p>
            <p className="pb-1 italic">
              My mechanic always fi<span className="underline">x</span>
              <span className="font-bold">es</span> my car very well.
            </p>
            <p lang="fa" dir="rtl" className="mb-4">
              مکانیکم همیشه ماشینم را خیلی خوب تعمیر می کند.
            </p>
            <p className="pb-1 italic">
              Their doorbell bu<span className="underline">zz</span>
              <span className="font-bold">es</span> loudly.
            </p>
            <p lang="fa" dir="rtl" className="mb-4">
              صدای زنگ درشان خیلی بلند است.
            </p>
            <p className="pb-1 italic">
              He ki<span className="underline">ss</span>
              <span className="font-bold">es</span> his children when he comes
              back home.
            </p>
            <p lang="fa" dir="rtl" className="mb-4">
              وقتی بر می گردد خانه فرزندانش را می بوسد.
            </p>
          </div>

          <p lang="fa" dir="rtl" className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              نکته دیگر اینکه اگر فعل جمله به حرف{" "}
              <span className="font-bold">y</span> ختم شده باشد و قبل از{" "}
              <span className="font-bold">y</span> یک{" "}
              <span className="underline">حرف بی صدا یا صامت</span> داشته باشیم،
              با فاعل های سوم شخص مفرد (it / she / she) حرف{" "}
              <span className="font-bold">y</span> به{" "}
              <span className="font-bold">i</span> تبدیل می شود و در انتهای فعل{" "}
              <span className="font-bold">es</span> اضافه می کنیم.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-4">
            <p lang="fa" dir="rtl" className="font-semibold pb-6">
              مثال:
            </p>{" "}
            <p className="pb-1 italic flex">
              <span>
                stu<span className="underline">d</span>
                <span className="font-bold">y</span>
              </span>
              <span>
                <img src={LongArrow} alt="arrow" className="w-8 mx-2 pt-1" />
              </span>
              <span>
                stu<span className="underline">d</span>
                <span className="font-bold">ies</span>
              </span>
            </p>
            <p className="pb-1 italic flex">
              <span>
                t<span className="underline">r</span>
                <span className="font-bold">y</span>
              </span>
              <span>
                <img src={LongArrow} alt="arrow" className="w-8 mx-2 pt-1" />
              </span>
              <span>
                t<span className="underline">r</span>
                <span className="font-bold">ies</span>
              </span>
            </p>
          </div>
        </div>
      </div>
      <ExerciseCard questions={questions} instruction={instruction} />
    </>
  );
};
