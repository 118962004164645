import { AllRoutes } from "./routes/AllRoutes";
import { Header, Footer } from "./components";
import Analytics from "./Analytics";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Header />
      <main className="bg-white">
        <Analytics /> {/* This component will track page views */}
        <AllRoutes />
      </main>
      <Footer />
    </div>
  );
}

export default App;
