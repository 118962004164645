import { useState } from "react";
import { Link } from "react-router-dom";
import { LanguageSwitch, ExerciseCard, useTitle } from "../../../components";
import Bulb from "../../../assets/images/bulb.png";
import LinkImg from "../../../assets/images/link.svg";

export const AuxiliaryVerbsElementary = ({ title }) => {
  const [farsi, setFarsi] = useState(false);
  const instruction = (
    <span>
      Complete the sentences with the correct auxiliary verb:{" "}
      <span className="italic">am</span>, <span className="italic">is</span>,{" "}
      <span className="italic">are</span>, <span className="italic">was</span>,
      <span className="italic"> were</span>, <span className="italic">do</span>,{" "}
      <span className="italic">does</span>, <span className="italic">can</span>,{" "}
      <span className="italic">should</span>,{" "}
      <span className="italic">may</span>.
    </span>
  );
  const questions = [
    {
      id: 1,
      question: <span>____ you at school yesterday?</span>,
      answer: (
        <span>
          <span className="font-bold">Were</span> you at school yesterday?
        </span>
      ),
    },
    {
      id: 2,
      question: <span>She ____ speak German well. (not able)</span>,
      answer: (
        <span>
          She <span className="font-bold">can't / cannot</span> speak German
          well.
        </span>
      ),
    },
    {
      id: 3,
      question: <span>____ they like football?</span>,
      answer: (
        <span>
          <span className="font-bold">Do</span> they like football?
        </span>
      ),
    },
    {
      id: 4,
      question: <span>I ____ not feel well today.</span>,
      answer: (
        <span>
          I <span className="font-bold">don't / do not</span> feel well today.
        </span>
      ),
    },
    {
      id: 5,
      question: <span>We ____ go to the park tomorrow. (possibility)</span>,
      answer: (
        <span>
          We <span className="font-bold">may</span> go to the park tomorrow.
        </span>
      ),
    },
    {
      id: 6,
      question: <span>____ he have a pet?</span>,
      answer: (
        <span>
          <span className="font-bold">Does</span> he have a pet?
        </span>
      ),
    },
    {
      id: 7,
      question: <span>You ____ eat more vegetables. (advice)</span>,
      answer: (
        <span>
          You <span className="font-bold">should</span> eat more vegetables.
        </span>
      ),
    },
    {
      id: 8,
      question: <span>They ____ at home now.</span>,
      answer: (
        <span>
          They <span className="font-bold">are / aren't / are not</span> at home
          now.
        </span>
      ),
    },
    {
      id: 9,
      question: <span>I ____ sorry for being late.</span>,
      answer: (
        <span>
          I <span className="font-bold">am</span> sorry for being late.
        </span>
      ),
    },
    {
      id: 10,
      question: <span>You ____ use your phone in class. (not allowed)</span>,
      answer: (
        <span>
          You <span className="font-bold">can't / cannot</span> use your phone
          in class.
        </span>
      ),
    },
  ];

  useTitle(!farsi ? title : "صفات عالی / برترین");

  return (
    <>
      {/* -------------------- Breadcrumb Menu -------------------- */}
      <div data-aos="fade" data-aos-duration="1500">
        <nav className="flex pl-3 pt-2 text-gray-400 text-sm font-semibold">
          <Link to="/" className="flex duration-300 hover:text-indigo-950">
            <span className="pt-0.5 md:pr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
            </span>
            <span className="max-md:hidden">Home</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link to="/grammar" className="duration-300 hover:text-indigo-950">
            <span>Grammar</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link
            to="/grammar/elementary"
            className="duration-300 hover:text-indigo-950"
          >
            <span>A1 - A2</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <span className="text-indigo-950">Auxiliary Verbs</span>
        </nav>
      </div>

      <div data-aos="zoom-in" data-aos-duration="800">
        {/* -------------------- Language Switch -------------------- */}
        <LanguageSwitch farsi={farsi} setFarsi={setFarsi} />

        {/* -------------------- Body in English -------------------- */}
        <div
          className={`duration-300 ${
            farsi ? "hidden opacity-0" : ""
          } my-7 mx-5 text-indigo-950 text-xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            Auxiliary Verbs
          </h1>
          <p className="mb-6">
            The place of auxiliary verbs is usually before the main verb in a
            positive sentence. We use auxiliary verbs to make negative sentences
            and questions.
          </p>
          <h2 className="mb-4 text-2xl font-bold">Negative Sentences</h2>
          <p className="mb-4">
            To make a negative sentence, we usually put "not" after the
            <span className="font-bold"> auxiliary verb</span>.
          </p>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-8">
            <p className="font-semibold mb-4">Examples:</p>
            <p className="mb-2">
              She <span className="font-bold">is</span> not /{" "}
              <span className="font-bold">is</span>n't my classmate.
            </p>
            <p className="mb-2">
              They <span className="font-bold">do</span> not /{" "}
              <span className="font-bold">do</span>n't live in London.
            </p>
            <p className="mb-1">
              You <span className="font-bold">should</span> not /{" "}
              <span className="font-bold">should</span>n't smoke here.
            </p>
          </div>

          <h2 className="mb-4 text-2xl font-bold">Questions</h2>
          <p className="mb-4">
            To make a question, we usually put the{" "}
            <span className="font-bold">auxiliary verb</span> before the
            subject.
          </p>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-8">
            <p className="font-semibold mb-4">Examples:</p>
            <p className="mb-2">
              <span className="font-bold">Were</span> you at home last night?
            </p>
            <p className="mb-2">
              <span className="font-bold">Does</span> he have a car?
            </p>
            <p className="mb-1">
              <span className="font-bold">Can</span> I open the window?
            </p>
          </div>

          <p className="flex mb-4">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              "Do" and "does" are only used in negative sentences and questions.
              We don't usually use them in positive sentences.
            </span>
          </p>

          <p className="flex mb-4">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              The verbs "am", "is", "are" (Present Simple), and "was", "were"
              (Past Simple) can act as both the main verb and the auxiliary
              verb.
            </span>
          </p>
          <p className="flex mb-4">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              Some auxiliary verbs like "can", "may", "should" etc. add extra
              meaning to the sentence.
            </span>
          </p>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-16">
            <p className="font-semibold mb-4">Examples:</p>
            <p className="mb-2">
              It <span className="font-bold">may</span> rain tonight.
              (possibility)
            </p>
            <p className="mb-2">
              I <span className="font-bold">can</span> play the piano well.
              (Ability)
            </p>
            <p className="mb-1">
              You <span className="font-bold">shouldn’t</span> eat a lot at
              night. (Advice)
            </p>
          </div>

          <div className="mb-8">
            <p className="font-bold mb-1">RELATED TOPIC</p>
            <p className="text-fuchsia-900">
              <img className="w-5 h-5 inline mr-2" src={LinkImg} alt="link" />
              <Link to="/grammar/elementary/questions" target="_blank">
                Questions <span className="font-light">(Elementary)</span>
              </Link>
            </p>
          </div>
        </div>

        {/* ---------- Body in Farsi ---------- */}
        <div
          className={`duration-300 ${
            farsi ? "" : "hidden opacity-0"
          } my-7 mx-5 text-indigo-950 text-xl md:text-xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            Superlatives <br />
            <span lang="fa" dir="rtl" className="text-2xl">
              افعال کمکی
            </span>
          </h1>
          <p lang="fa" dir="rtl" className="mb-6">
            جای افعال کمکی در جملات خبری مثبت معمولا قبل از فعل اصلی است. برای
            منفی و سوالی کردن جملات از افعال کمکی استفاده می کنیم.
          </p>
          <h2 lang="fa" dir="rtl" className="mb-4 text-2xl font-bold">
            جملات خبری منفی
          </h2>
          <p lang="fa" dir="rtl" className="mb-4">
            برای منفی کردن جملات کلمه "not" را بعد از{" "}
            <span className="font-extrabold">فعل کمکی</span> قرار می دهیم.
          </p>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-8">
            <p lang="fa" dir="rtl" className="font-semibold mb-4 not-italic">
              مثال:
            </p>
            <p className="mb-1">
              She <span className="font-bold">is</span> not /{" "}
              <span className="font-bold">is</span>n't my classmate.
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              او همکلاسی من نیست.
            </p>
            <p className="mb-1">
              They <span className="font-bold">do</span> not /{" "}
              <span className="font-bold">do</span>n't live in London.
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              آنها در لندن زندگی نمی کنند.
            </p>
            <p className="mb-1">
              You <span className="font-bold">should</span> not /{" "}
              <span className="font-bold">should</span>n't smoke here.
            </p>
            <p lang="fa" dir="rtl" className="mb-1 not-italic">
              نباید اینجا سیگار بکشی.
            </p>
          </div>

          <h2 lang="fa" dir="rtl" className="mb-4 text-2xl font-bold">
            جملات سوالی
          </h2>
          <p lang="fa" dir="rtl" className="mb-4">
            برای سوالی کردن جملات{" "}
            <span className="font-extrabold">فعل کمکی</span> را قبل از فاعل قرار
            می دهیم.
          </p>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-8">
            <p lang="fa" dir="rtl" className="font-semibold mb-4 not-italic">
              مثال:
            </p>
            <p className="mb-1">
              <span className="font-bold">Were</span> you at home last night?
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              آیا تو دیشب خانه بودی؟
            </p>
            <p className="mb-1">
              <span className="font-bold">Does</span> he have a car?
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              آیا او ماشین دارد؟
            </p>
            <p className="mb-1">
              <span className="font-bold">Can</span> I open the window?
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              (آیا) می توانم پنجره را باز کنم؟
            </p>
          </div>

          <p dir="rtl" className="flex mb-4">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span lang="fa" className="pr-3">
              افعال کمکی "do" و "does" را فقط در جملات منفی و سوالی استفاده می
              کنیم. این افعال کمکی معمولا در جملات خبری مثبت استفاده نمی شوند.
            </span>
          </p>

          <p dir="rtl" className="flex mb-4">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span lang="fa" className="pl-3">
              مشتقات فعل "to be"، یعنی "are" ،"is" ،"am" در زمان Simple Present
              و "was" و "were" در زمان Simple Past هم نقش فعل اصلی و هم نقش فعل
              کمکی را بازی می کنند.
            </span>
          </p>
          <p dir="rtl" className="flex mb-4">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span lang="fa" className="pl-3">
              برخی افعال کمکی مانند "may" ،"should" ،"can" و ... معنی جلمه را
              نیز کمی تغییر می دهند.
            </span>
          </p>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-16">
            <p className="font-semibold mb-4">Examples:</p>
            <p className="mb-1">
              It <span className="font-bold">may</span> rain tonight.
              (possibility)
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              امشب <mark>ممکن است</mark> باران ببارد.
            </p>
            <p className="mb-1">
              I <span className="font-bold">can</span> play the piano well.
              (Ability)
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              من <mark>می توانم</mark> خوب پیانو بنوازم.
            </p>
            <p className="mb-1">
              You <span className="font-bold">shouldn’t</span> eat a lot at
              night. (Advice)
            </p>
            <p lang="fa" dir="rtl" className="mb-1 not-italic">
              شب ها <mark>نباید</mark> زیاد غذا بخوری.
            </p>
          </div>

          <div className="mb-8">
            <p className="font-bold mb-1">RELATED TOPIC</p>
            <p className="text-fuchsia-900">
              <img className="w-5 h-5 inline mr-2" src={LinkImg} alt="link" />
              <Link to="/grammar/elementary/questions" target="_blank">
                Questions <span className="font-light">(Elementary)</span>
              </Link>
            </p>
          </div>
        </div>
      </div>
      <ExerciseCard questions={questions} instruction={instruction} />
    </>
  );
};
