export const Pagination = ({
  totalCount,
  pageSize,
  currentPage,
  handleChangePage,
}) => {
  const pages = Math.ceil(totalCount / pageSize);
  const maxVisiblePages = 5;

  // Calculate startPage dynamically
  let startPage = Math.max(
    1,
    Math.min(
      currentPage - Math.floor(maxVisiblePages / 2),
      pages - maxVisiblePages + 1
    )
  );

  const displayedPages = Array.from(
    { length: Math.min(maxVisiblePages, pages) },
    (_, i) => startPage + i
  );

  // Disable the left arrow when on the first page
  const isFirstPage = currentPage === 1;

  // Disable the right arrow when on the last page
  const isLastPage = currentPage === pages;

  return (
    <div className="flex w-fit my-14 border border-pink-200 mx-auto rounded-lg text-gray-500 shadow-md text-center">
      {/* Left Arrow */}
      <span
        onClick={() => handleChangePage(Math.max(1, currentPage - 1))}
        className={`p-2 border-r border-pink-200 hover:bg-pink-50 hover:text-indigo-950 cursor-pointer ${
          isFirstPage ? "text-gray-300 cursor-not-allowed" : ""
        }`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="size-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m18.75 4.5-7.5 7.5 7.5 7.5m-6-15L5.25 12l7.5 7.5"
          />
        </svg>
      </span>

      {/* Page Numbers */}
      {displayedPages.map((p) => (
        <span
          key={p}
          onClick={() => handleChangePage(p)}
          className={`py-2 px-4 border-r border-pink-200 hover:bg-pink-50 hover:text-indigo-950 cursor-pointer ${
            p === currentPage ? "bg-pink-100" : ""
          }`}
        >
          {p}
        </span>
      ))}

      {/* Right Arrow */}
      <span
        onClick={() => handleChangePage(Math.min(pages, currentPage + 1))}
        className={`p-2 cursor-pointer hover:bg-pink-50 hover:text-indigo-950 rounded-r-lg ${
          isLastPage ? "text-gray-300 cursor-not-allowed" : ""
        }`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="size-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5"
          />
        </svg>
      </span>
    </div>
  );
};
