import { useState } from "react";
import { Link } from "react-router-dom";
import { LanguageSwitch, ExerciseCard, useTitle } from "../../../components";
import Bulb from "../../../assets/images/bulb.png";
import ArrowLongRight from "../../../assets/images/arrowLongLight.svg";

export const ComparativesElementary = ({ title }) => {
  const [farsi, setFarsi] = useState(false);
  const instruction = (
    <span>Use the words in each prompt to write a comparative sentence.</span>
  );
  const questions = [
    {
      id: 1,
      question: <span>An elephant / a cow / big</span>,
      answer: <span>An elephant is bigger than a cow.</span>,
    },
    {
      id: 2,
      question: <span>A lion / a cat / dangerous</span>,
      answer: <span>A lion is more dangerous than a cat.</span>,
    },
    {
      id: 3,
      question: <span>A mountain / a hill / high</span>,
      answer: <span>A mountain is higher than a hill.</span>,
    },
    {
      id: 4,
      question: <span>A whale / a dolphin / large</span>,
      answer: <span>A whale is larger than a dolphin.</span>,
    },
    {
      id: 5,
      question: <span>A train / a car / long</span>,
      answer: <span>A train is longer than a car.</span>,
    },
    {
      id: 6,
      question: <span>A giraffe / a zebra / tall</span>,
      answer: <span>A giraffe is taller than a zebra.</span>,
    },
    {
      id: 7,
      question: <span>Gold / silver / expensive</span>,
      answer: <span>Gold is more expensive than silver.</span>,
    },
  ];

  useTitle(!farsi ? title : "صفات تفضیلی");

  return (
    <>
      {/* -------------------- Breadcrumb Menu -------------------- */}
      <div data-aos="fade" data-aos-duration="1500">
        <nav className="flex pl-3 pt-2 text-gray-400 text-sm font-semibold">
          <Link to="/" className="flex duration-300 hover:text-indigo-950">
            <span className="pt-0.5 md:pr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
            </span>
            <span className="max-md:hidden">Home</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link to="/grammar" className="duration-300 hover:text-indigo-950">
            <span>Grammar</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link
            to="/grammar/elementary"
            className="duration-300 hover:text-indigo-950"
          >
            <span>A1 - A2</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <span className="text-indigo-950">Comparatives</span>
        </nav>
      </div>

      <div data-aos="zoom-in" data-aos-duration="800">
        {/* -------------------- Language Switch -------------------- */}
        <LanguageSwitch farsi={farsi} setFarsi={setFarsi} />

        {/* -------------------- Body in English -------------------- */}
        <div
          className={`duration-300 ${
            farsi ? "hidden opacity-0" : ""
          } my-7 mx-5 text-indigo-950 text-xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            Comparatives
          </h1>
          <p className="mb-4">
            We use comparative adjectives to compare <mark>two</mark> people,
            things, or groups.
          </p>
          <p className="mb-9">There are three groups of adjectives:</p>

          <div className="flex mb-4">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="pt-1 font-bold">
              One-syllable adjectives, and two-syllable adjectives that end in
              "-y"
            </span>
          </div>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-7">
            <p className="font-semibold pb-3">Examples:</p>{" "}
            <p className="flex pb-2">
              <span>
                tall + <span className="font-bold">er</span>
              </span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                tall<span className="font-bold">er</span>
              </span>
            </p>
            <p className="flex pb-2">
              <span>
                fine + <span className="font-bold">er</span>
              </span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                fin
                <span className="font-bold">
                  <mark>e</mark>r
                </span>
              </span>
            </p>
            <p className="flex pb-2">
              <span>
                big + <span className="font-bold">er</span>
              </span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                bi<mark>gg</mark>
                <span className="font-bold">er</span>
              </span>
            </p>
            <p className="flex pb-2">
              <span>
                happy + <span className="font-bold">er</span>
              </span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                happ<mark>i</mark>
                <span className="font-bold">er</span>
              </span>
            </p>
          </div>

          <p className="flex mb-5">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              In one-syllable adjectives that end with a
              consonant-vowel-consonant (CVC) pattern, such as "big", we double
              the final consonant before adding "-er" to form the comparative.
            </span>
          </p>

          <p className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              In one-syllable and two-syllable adjectives that end in "y", such
              as "happy", we change the final "y" to "i" before adding "-er" to
              form the comparative.
            </span>
          </p>

          <div className="flex mb-4">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="pt-1 font-bold">
              Two-syllable and longer adjectives
            </span>
          </div>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-3">Examples:</p>{" "}
            <p className="flex pb-2">
              <span>
                <span className="font-bold">more</span> + modern
              </span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                <span className="font-bold">more</span> modern
              </span>
            </p>
            <p className="flex pb-2">
              <span>
                <span className="font-bold">more</span> + expensive
              </span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                <span className="font-bold">more</span> expensive
              </span>
            </p>
          </div>

          <div className="flex mb-4">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="pt-1 font-bold">Irregular Adjectives</span>
          </div>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-3">Examples:</p>{" "}
            <p className="flex pb-2">
              <span>
                <span>good</span>
              </span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                <span className="font-bold">better</span>
              </span>
            </p>
            <p className="flex pb-2">
              <span>
                <span>bad</span>
              </span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                <span className="font-bold">worse</span>
              </span>
            </p>
            <p className="flex pb-2">
              <span>
                <span>far</span>
              </span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                <span className="font-bold">farther / further</span>
              </span>
            </p>
            <p className="flex pb-2">
              <span>
                <span>little</span>
              </span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                <span className="font-bold">less</span>
              </span>
            </p>
          </div>

          <p className="flex mb-5">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              We usually use the preposition "than" after a comparative
              structure.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-20">
            <p className="font-semibold pb-3">Examples:</p>{" "}
            <p className="mb-2">She's taller than her sister.</p>
            <p className="mb-2">This book is more interesting than that one.</p>
          </div>
        </div>

        {/* ---------- Body in Farsi ---------- */}
        <div
          className={`duration-300 ${
            farsi ? "" : "hidden opacity-0"
          } my-7 mx-5 text-indigo-950 text-xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            Comparatives <br />
            <span lang="fa" dir="rtl" className="text-2xl">
              صفات تفضیلی
            </span>
          </h1>
          <p lang="fa" dir="rtl" className="mb-4">
            برای مقایسه <mark>دو</mark> فرد، چیز یا گروه می توانیم از صفات
            تفضیلی یا صفت های برتر استفاده کنیم.
          </p>
          <p lang="fa" dir="rtl" className="mb-9">
            برای این منظور صفت ها را به سه دسته تقسیم می کنیم:
          </p>

          <div lang="fa" dir="rtl" className="flex mb-4">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="pt-1 font-bold">
              صفت های تک سیلابی، و صفت های دو سیلابی ای که به "y" ختم می شوند:
            </span>
          </div>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-7">
            <p lang="fa" dir="rtl" className="font-semibold pb-3 not-italic">
              مثال:
            </p>{" "}
            <p className="flex pb-1">
              <span>
                tall + <span className="font-bold">er</span>
              </span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                tall<span className="font-bold">er</span>
              </span>
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              بلند<span className="font-bold">تر</span>
            </p>
            <p className="flex pb-1">
              <span>
                fine + <span className="font-bold">er</span>
              </span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                fin
                <span className="font-bold">
                  <mark>e</mark>r
                </span>
              </span>
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              به<span className="font-bold">تر</span>
            </p>
            <p className="flex pb-1">
              <span>
                big + <span className="font-bold">er</span>
              </span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                bi<mark>gg</mark>
                <span className="font-bold">er</span>
              </span>
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              بزرگ<span className="font-bold">تر</span>
            </p>
            <p className="flex pb-1">
              <span>
                happy + <span className="font-bold">er</span>
              </span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                happ<mark>i</mark>
                <span className="font-bold">er</span>
              </span>
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              شاد<span className="font-bold">تر</span>
            </p>
          </div>

          <p lang="fa" dir="rtl" className="flex mb-5">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              در صفت های تک سیلابی ای مانند "big" که به یک حرف صامت + یک حرف
              مصوت + یک حرف صامت ختم می شوند، وقتی "er-" اضافه می شود، حرف آخر
              تکرار می شود.
            </span>
          </p>

          <p lang="fa" dir="rtl" className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              در صفت های تک سیلابی و دو سیلابی که به "y" ختم می شوند، مانند
              "happy"، وقتی "er-" اضافه می شود، "y" به "i" تبدیل می شود.
            </span>
          </p>

          <div lang="fa" dir="rtl" className="flex mb-4">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="pt-1 font-bold">
              صفت های دو سیلابی و طولانی تر
            </span>
          </div>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-3 not-italic">
              مثال:
            </p>{" "}
            <p className="flex pb-1">
              <span>
                <span className="font-bold">more</span> + modern
              </span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                <span className="font-bold">more</span> modern
              </span>
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              پیشرفته <span className="font-bold">تر</span>
            </p>
            <p className="flex pb-2">
              <span>
                <span className="font-bold">more</span> + expensive
              </span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                <span className="font-bold">more</span> expensive
              </span>
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              گران <span className="font-bold">تر</span>
            </p>
          </div>

          <div dir="rtl" className="flex mb-4">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span lang="fa" className="pt-1 font-bold">
              صفات بی قاعده
            </span>
          </div>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-3 not-italic">
              مثال:
            </p>{" "}
            <p className="flex pb-1">
              <span>
                <span>good</span>
              </span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                <span className="font-bold">better</span>
              </span>
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              به<span className="font-bold">تر</span>
            </p>
            <p className="flex pb-1">
              <span>
                <span>bad</span>
              </span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                <span className="font-bold">worse</span>
              </span>
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              بد<span className="font-bold">تر</span>
            </p>
            <p className="flex pb-1">
              <span>
                <span>far</span>
              </span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                <span className="font-bold">farther / further</span>
              </span>
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              دور<span className="font-bold">تر</span>
            </p>
            <p className="flex pb-2">
              <span>
                <span>little</span>
              </span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                <span className="font-bold">less</span>
              </span>
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              کم<span className="font-bold">تر</span>
            </p>
          </div>

          <p lang="fa" dir="rtl" className="flex mb-5">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              معمولا بعد از صفت های برتر از حرف اضافه than به معنی «از» استفاده
              می کنیم.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-20">
            <p lang="fa" dir="rtl" className="font-semibold pb-3 not-italic">
              مثال:
            </p>{" "}
            <p className="mb-1">She's taller than her sister.</p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              او قد اش از خواهرش بلند تر است.
            </p>
            <p className="mb-1 not-italic">
              This book is more interesting than that one.
            </p>
            <p lang="fa" dir="rtl" className="mb-1 not-italic">
              این کتاب از آن کتاب جالب تر است.
            </p>
          </div>
        </div>
      </div>
      <ExerciseCard questions={questions} instruction={instruction} />
    </>
  );
};
