import { useState } from "react";
import { Link } from "react-router-dom";

import { useTitle, Questions, Pagination } from "../../components";
import { IELTSWritingCommonMistakesList } from "../../data/IELTSWritingCommonMistakesData";

export const IELTSWritingCommonMistakes = ({ title }) => {
  useTitle(title);

  const [currentPage, setCurrentPage] = useState(1);

  const pageSize = 10;

  const paginate = () => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return IELTSWritingCommonMistakesList.slice(startIndex, endIndex);
  };

  const slicedCommonMistakes = paginate();

  const handleChangePage = (p) => {
    setCurrentPage(p);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div>
      {/* ---------- Breadcrumb Menu ---------- */}
      <nav
        className="flex pl-3 pt-2 text-gray-400 text-sm font-semibold"
        data-aos="fade"
        data-aos-duration="1500"
      >
        <Link to="/" className="flex duration-300 hover:text-indigo-950">
          <span className="pt-0.5 md:pr-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
              />
            </svg>
          </span>
          <span className="max-md:hidden">Home</span>
        </Link>

        <Link
          to="/commonMistakes"
          className="flex duration-300 hover:text-indigo-950"
        >
          <span className="pt-0.5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <span>Common Mistakes</span>
        </Link>

        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m8.25 4.5 7.5 7.5-7.5 7.5"
            />
          </svg>
        </span>
        <span className="text-indigo-950">IELTS Writing</span>
      </nav>

      {/* ---------- Body ---------- */}
      <div data-aos="zoom-in" data-aos-duration="800">
        <div className="mx-5">
          <h1 className="text-pink-700 text-4xl sm:text-5xl font-bold drop-shadow text-center my-8 sm:mt-9 sm:mb-14">
            Common Mistakes in IELTS Writing
          </h1>
          <p className="text-2xl font-bold mb-12 sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12">
            Find and correct the mistakes in the sentences below. Then, click on
            "answer" to check your corrections.
          </p>
          <div className="text-xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12">
            <Questions questions={slicedCommonMistakes} />
          </div>
        </div>
      </div>

      <Pagination
        totalCount={IELTSWritingCommonMistakesList.length}
        pageSize={pageSize}
        currentPage={currentPage}
        handleChangePage={handleChangePage}
      />
    </div>
  );
};
