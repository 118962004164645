const IELTSWritingCommonMistakesList = [
  {
    id: 1,
    question: (
      <span>
        most of scientific reports have demonstrated that using computers can
        have negative effects on children.
      </span>
    ),
    answer: (
      <span>
        <span className="font-bold">most</span> scientific reports have
        demonstrated that using computers can have negative effects on children.
      </span>
    ),
    explanation: (
      <span>
        <span className="font-bold">most + plural / uncountable noun</span>{" "}
        <br />
        <span className="inline-block mb-4">
          when we’re talking about a group of things or people, or something in
          general
        </span>
        <br />
        <span className="font-bold">
          most of + the / these / your ... + plural / uncountable noun
        </span>{" "}
        <br />
        <span>
          when we’re referring to a specific group of people or things, or
          something in particular
        </span>
      </span>
    ),
  },
  {
    id: 2,
    question: (
      <span>
        It can have several positive effects; eventhough a few studies have
        revealed some disadvantages as well.
      </span>
    ),
    answer: (
      <span>
        It can have several positive effects
        <span className="font-bold">(,) even though</span> a few studies have
        revealed some disadvantages as well.
      </span>
    ),
    explanation: (
      <span>
        <span className="block mb-3">
          “Even though” must be written as two separate words with a space in
          between.
        </span>
        <span>
          We don’t usually use a semicolon before "even though." Instead, it is
          typically preceded by a comma or no punctuation
        </span>
      </span>
    ),
  },
  {
    id: 3,
    question: <span>These issues can be categorized as mental problem.</span>,
    answer: (
      <span>
        These issues can be categorized as mental{" "}
        <span className="font-bold">problems</span>.
      </span>
    ),
    explanation: (
      <span>
        “Problem” is a countable noun. Because “issues” is plural, “problem”
        creates a mismatch and should be plural too.
      </span>
    ),
  },
  {
    id: 4,
    question: (
      <span>
        Obesity is of one of the most serious consequence of overusing
        computers.
      </span>
    ),
    answer: (
      <span>
        Obesity is one of the most serious{" "}
        <span className="font-bold">consequences</span> of overusing computers.
      </span>
    ),
    explanation: <span>We need a plural noun after “one of”.</span>,
  },
  {
    id: 5,
    question: (
      <span>Overusing computers can have a bad effect on eye sight.</span>
    ),
    answer: (
      <span>
        Overusing computers can have a bad effect on{" "}
        <span className="font-bold">eyesight</span>.
      </span>
    ),
    explanation: (
      <span>
        “Eyesight” should be written as one word with no space in between.
      </span>
    ),
  },
  {
    id: 6,
    question: <span>The treatment of eye muscles can take long time.</span>,
    answer: (
      <span>
        The treatment of eye muscles can take{" "}
        <span className="font-bold">a</span> long time.
      </span>
    ),
    explanation: (
      <span>
        In the expression “<span className="font-bold">take a long time</span>”,
        “time” is a singular noun referring to a period of time during which we
        do something or something happens.
      </span>
    ),
  },
  {
    id: 7,
    question: (
      <span>
        Several studies have been shown that using electronic devices can affect
        our brains.
      </span>
    ),
    answer: (
      <span>
        Several studies <span className="font-bold">have shown</span> that using
        electronic devices can affect our brains.
      </span>
    ),
    explanation: (
      <span>
        The sentence is in the active voice because “Several studies” is both
        the subject and the agent of the verb.
      </span>
    ),
  },
  {
    id: 8,
    question: (
      <span>
        Using tablets and another electronic devices can be harmful to kids.
      </span>
    ),
    answer: (
      <span>
        Using tablets and <span className="font-bold">other</span> electronic
        devices can be harmful to kids.
      </span>
    ),
    explanation: (
      <span>
        <span className="block font-bold">
          another + singular countable noun
        </span>
        <span className="block font-bold">other + plural noun</span>
      </span>
    ),
  },
  {
    id: 9,
    question: (
      <span>Children are not capable to cope with such difficulties.</span>
    ),
    answer: (
      <span>
        Children are not capable <span className="font-bold">of coping</span>{" "}
        with such difficulties.
      </span>
    ),
    explanation: (
      <span className="font-bold">be capable of (doing) something</span>
    ),
  },
  {
    id: 10,
    question: <span>Computer knowledge are really crucial today.</span>,
    answer: (
      <span>
        Computer knowledge <span className="font-bold">is</span> really crucial
        today.
      </span>
    ),
    explanation: <span>“Knowledge” is an uncountable noun.</span>,
  },
  {
    id: 11,
    question: (
      <span>
        The people think that it is not fair are mostly the inhabitants of
        developing countries.
      </span>
    ),
    answer: (
      <span>
        The people <span className="font-bold">who</span> think that it is not
        fair are mostly the inhabitants of developing countries.
      </span>
    ),
    explanation: (
      <span>
        Add the relative pronoun "who" after "the people" to properly connect
        the subject (the people) with the relative clause (think that it is not
        fair).
      </span>
    ),
  },
  {
    id: 12,
    question: (
      <span>
        This can lead not to notice the distinct aptitudes of adolescents.
      </span>
    ),
    answer: (
      <span>
        This can lead <span className="font-bold">to not noticing</span> the
        distinct aptitudes of adolescents.
      </span>
    ),
    explanation: (
      <span>
        <span className="block">
          <span className="font-bold">lead to (doing) something</span> (v) =
          result in ...
        </span>
        <span className="block">
          The negative form of a gerund (doing) is "not doing".
        </span>
      </span>
    ),
  },
  {
    id: 13,
    question: <span>It motivates them to focus in sports.</span>,
    answer: (
      <span>
        It motivates them to focus <span className="font-bold">on</span> sports.
      </span>
    ),
    explanation: (
      <span>
        <span className="font-bold">focus on (doing) something / somebody</span>{" "}
        (v)
      </span>
    ),
  },
  {
    id: 14,
    question: <span>This can improve society in term of health.</span>,
    answer: (
      <span>
        This can improve society in <span className="font-bold">terms</span> of
        health.
      </span>
    ),
    explanation: <span className="font-bold">in terms of ...</span>,
  },
  {
    id: 15,
    question: (
      <span>
        Concentrating on the fields that directly related to our life is a
        better idea.
      </span>
    ),
    answer: (
      <span>
        Concentrating on the fields that <span className="font-bold">are</span>{" "}
        directly related to our life is a better idea.
      </span>
    ),
    explanation: (
      <span className="font-bold">
        <span className="underline">be</span> related to ... (v)
      </span>
    ),
  },
  {
    id: 16,
    question: <span>Both of these views have their own cons and pros.</span>,
    answer: (
      <span>
        Both of these views have their own{" "}
        <span className="font-bold">pros and cons</span>.
      </span>
    ),
    explanation: (
      <span>
        The phrase "<span className="font-bold">pros and cons</span>" is usually
        used in this fixed order.
      </span>
    ),
  },
  {
    id: 17,
    question: (
      <span>
        Trying many different fields and activities can avoid being good at any
        of them.
      </span>
    ),
    answer: (
      <span>
        Trying many different fields and activities can{" "}
        <span className="font-bold">prevent you from</span> being good at any of
        them.
      </span>
    ),
    explanation: (
      <span>
        <span className="block">
          <span className="font-bold">avoid (doing) something</span> (v): to
          prevent something <span className="underline">bad</span> from
          happening
        </span>
        <span className="block italic mb-2">
          <span className="font-bold">e.g.</span> We're trying to avoid
          conflict.
        </span>
        <span className="block">
          <span className="font-bold">
            prevent somebody (from) doing something
          </span>{" "}
          (v): to stop somebody from doing something
        </span>
      </span>
    ),
  },
  {
    id: 18,
    question: (
      <span>
        Resisting against such desires can result in better achievements.
      </span>
    ),
    answer: (
      <span>
        <span className="font-bold">Resisting</span> such desires can result in
        better achievements.
      </span>
    ),
    explanation: (
      <span>
        <span className="block">
          Usually, the verb "resist" is not followed by a preposition.
        </span>
        <span className="block font-bold">resist (doing) something</span>
      </span>
    ),
  },
  {
    id: 19,
    question: <span>Inheritance can affect on individuals' development.</span>,
    answer: (
      <span>
        Inheritance can <span className="font-bold">affect</span> individuals'
        development.
      </span>
    ),
    explanation: (
      <span>
        <span className="block">
          The verb "affect" is not usually followed by a preposition.
        </span>
        <span className="block">
          <span className="font-bold">affect somebody / something</span> (v)
        </span>
      </span>
    ),
  },
  {
    id: 20,
    question: <span>They have the same personality to some extend.</span>,
    answer: (
      <span>
        They have the same personality to some{" "}
        <span className="font-bold">
          exten<span className="underline">t</span>
        </span>
        .
      </span>
    ),
    explanation: (
      <span>
        <span className="block mb-2">
          "Extend" is a verb meaning making something longer or larger. "Extent"
          is a noun meaning degree or range.
        </span>
        <span className="block font-bold">to some extent</span>
      </span>
    ),
  },
  {
    id: 21,
    question: (
      <span>
        Life experiences can change our attitudes towards every aspects of life.
      </span>
    ),
    answer: (
      <span>
        Life experiences can change our attitudes towards every{" "}
        <span className="font-bold">aspect</span> of life.
      </span>
    ),
    explanation: <span className="font-bold">every + sinuglar noun</span>,
  },
  {
    id: 22,
    question: <span>We're totally different with each other.</span>,
    answer: (
      <span>
        We're totally different <span className="font-bold">from</span> each
        other.
      </span>
    ),
    explanation: (
      <span className="font-bold">different from somebody / something</span>
    ),
  },
  {
    id: 23,
    question: (
      <span>
        Inheritance characteristics that are related to our genes are fixed.
      </span>
    ),
    answer: (
      <span>
        <span className="font-bold">Inherited / Hereditary</span>{" "}
        characteristics that are related to our genes are fixed.
      </span>
    ),
    explanation: (
      <span>
        "Inheritance" is a noun. "Inherited" and "hereditary" are adjectives
        that have similar meanings. Since "characteristics" is a noun, you need
        an adjective (like "inherited" or "hereditary") to describe it.
      </span>
    ),
  },
  {
    id: 24,
    question: (
      <span>
        There are several factors to consider in order to come across this
        problem.
      </span>
    ),
    answer: (
      <span>
        There are several factors to consider in order to{" "}
        <span className="font-bold">deal with / face</span> this problem.
      </span>
    ),
    explanation: (
      <span>
        <span className="block">
          The verb "come across" typically means{" "}
          <span className="italic">
            to encounter or find something by chance
          </span>
          .
        </span>
        <span className="italic">
          <span className="font-bold">e.g.</span> I came across these old photos
          in the closet.
        </span>
      </span>
    ),
  },
];

export { IELTSWritingCommonMistakesList };
