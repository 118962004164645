import { useState } from "react";
import { Link } from "react-router-dom";
import { LanguageSwitch, ExerciseCard, useTitle } from "../../../components";
import Bulb from "../../../assets/images/bulb.png";

export const CanElementary = ({ title }) => {
  const [farsi, setFarsi] = useState(false);

  const instruction = (
    <div>
      <p className="mb-2">
        Complete each sentence with the correct form of "can" and a verb from
        the list below.
      </p>{" "}
      <p className="border-2 px-2 max-w-fit">
        open | tell | see | play | use | catch
      </p>
    </div>
  );
  const questions = [
    {
      id: 1,
      question: <span>I'm sorry, I ____ you on the weekend. I'm so busy.</span>,
      answer: (
        <span>
          I'm sorry, I <span className="font-bold">can't see</span> you on the
          weekend. I'm so busy.
        </span>
      ),
    },
    {
      id: 2,
      question: (
        <span>
          Excuse me, I think I'm lost. ____ I ____ your cell phone to call my
          parents?
        </span>
      ),
      answer: (
        <span>
          Excuse me, I think I'm lost. <span className="font-bold">Can</span> I{" "}
          <span className="font-bold">use</span> your cell phone to call my
          parents?
        </span>
      ),
    },
    {
      id: 3,
      question: <span>It's too hot. ____ I ____ the window?</span>,
      answer: (
        <span>
          It's too hot. <span className="font-bold">Can</span> I{" "}
          <span className="font-bold">open</span> the window?
        </span>
      ),
    },
    {
      id: 4,
      question: (
        <span>The dog is so smart. It ____ the ball when I throw it.</span>
      ),
      answer: (
        <span>
          The dog is so smart. It <span className="font-bold">can catch</span>{" "}
          the ball when I throw it.
        </span>
      ),
    },
    {
      id: 5,
      question: <span>We ____ together after school if you want.</span>,
      answer: (
        <span>
          We <span className="font-bold">can play</span> together after school
          if you want.
        </span>
      ),
    },
    {
      id: 6,
      question: (
        <span>____ you ____ me how to get to the library from here?</span>
      ),
      answer: (
        <span>
          <span className="font-bold">Can</span> you{" "}
          <span className="font-bold">tell</span> me how to get to the library
          from here?
        </span>
      ),
    },
  ];

  useTitle(title);

  return (
    <>
      {/* -------------------- Breadcrumb Menu -------------------- */}
      <div data-aos="fade" data-aos-duration="1500">
        <nav className="flex pl-3 pt-2 text-gray-400 text-sm font-semibold">
          <Link to="/" className="flex duration-300 hover:text-indigo-950">
            <span className="pt-0.5 md:pr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
            </span>
            <span className="max-md:hidden">Home</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link to="/grammar" className="duration-300 hover:text-indigo-950">
            <span>Grammar</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link
            to="/grammar/elementary"
            className="duration-300 hover:text-indigo-950"
          >
            <span>A1 - A2</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <span className="text-indigo-950">Can</span>
        </nav>
      </div>

      <div data-aos="zoom-in" data-aos-duration="800">
        {/* -------------------- Language Switch -------------------- */}
        <LanguageSwitch farsi={farsi} setFarsi={setFarsi} />

        {/* -------------------- Body in English -------------------- */}
        <div
          className={`duration-300 ${
            farsi ? "hidden opacity-0" : ""
          } my-7 mx-5 text-indigo-950 text-xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            Can
          </h1>
          <p className="mb-6">
            We use the auxiliary verb "can" in these situations:
          </p>

          <div className="flex">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="mt-1">To talk about abilities</span>
          </div>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-4">
            <p className="font-semibold pb-6 italic">Examples:</p>{" "}
            <p className="mb-3 italic">She can swim very well.</p>
            <p className="pb-1 italic">He can speak five languages.</p>
          </div>

          <div className="flex mt-7">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="mt-1">To talk about possibilities</span>
          </div>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-4">
            <p className="font-semibold pb-6 italic">Examples:</p>{" "}
            <p className="mb-3 italic">Mistakes can happen to anyone.</p>
            <p className="pb-1 italic">
              Friends can help you feel better when you're sad.
            </p>
          </div>

          <div className="flex mt-7">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="mt-1">To talk about permissions</span>
          </div>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-4">
            <p className="font-semibold pb-6 italic">Examples:</p>{" "}
            <p className="mb-3 italic">You can't smoke here.</p>
            <p className="pb-1 italic">Can I take a photograph?</p>
          </div>

          <div className="flex mt-7">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="mt-1">To make friendly requests</span>
          </div>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-4">
            <p className="font-semibold pb-6 italic">Examples:</p>{" "}
            <p className="mb-3 italic">Can you help me with my homework?</p>
            <p className="pb-1 italic">Can you open the window, please?</p>
          </div>

          <p className="mt-12 mb-7">
            Let's take a look at the different forms of the auxiliary verb "can"
            in positive and negative statements and questions.
          </p>
          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase mb-2">Positive</h3>
            <p>
              I <span className="font-semibold">can</span> do ... .
            </p>
            <p>
              You <span className="font-semibold">can</span> do ... .
            </p>
            <p>
              He <span className="font-semibold">can</span> do ... .
            </p>
            <p>
              She <span className="font-semibold">can</span> do ... .
            </p>
            <p>
              It <span className="font-semibold">can</span> do ... .
            </p>
            <p>
              We <span className="font-semibold">can</span> do ... .
            </p>
            <p>
              You <span className="font-semibold">can</span> do ... .
            </p>
            <p>
              They <span className="font-semibold">can</span> do ... .
            </p>
          </div>

          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase mb-2">Negative</h3>
            <p>
              I <span className="font-semibold">can</span>
              <span className="font-semibold text-red-700">not</span> do ... . /
              I<span className="font-semibold"> can</span>
              <span className="font-semibold text-red-700">'t</span> do ... .
            </p>
            <p>
              You <span className="font-semibold">can</span>
              <span className="font-semibold text-red-700">not</span> do ... . /
              You
              <span className="font-semibold"> can</span>
              <span className="font-semibold text-red-700">'t</span> do ... .
            </p>
            <p>
              He <span className="font-semibold">can</span>
              <span className="font-semibold text-red-700">not</span> do ... . /
              He
              <span className="font-semibold"> can</span>
              <span className="font-semibold text-red-700">'t</span> do ... .
            </p>
            <p>
              She <span className="font-semibold">can</span>
              <span className="font-semibold text-red-700">not</span> do ... . /
              She
              <span className="font-semibold"> can</span>
              <span className="font-semibold text-red-700">'t</span> do ... .
            </p>
            <p>
              It <span className="font-semibold">can</span>
              <span className="font-semibold text-red-700">not</span> do ... . /
              It
              <span className="font-semibold"> can</span>
              <span className="font-semibold text-red-700">'t</span> do ... .
            </p>
            <p>
              We <span className="font-semibold">can</span>
              <span className="font-semibold text-red-700">not</span> do ... . /
              We
              <span className="font-semibold"> can</span>
              <span className="font-semibold text-red-700">'t</span> do ... .
            </p>
            <p>
              You <span className="font-semibold">can</span>
              <span className="font-semibold text-red-700">not</span> do ... . /
              You
              <span className="font-semibold"> can</span>
              <span className="font-semibold text-red-700">'t</span> do ... .
            </p>
            <p>
              They <span className="font-semibold">can</span>
              <span className="font-semibold text-red-700">not</span> do ... . /
              They
              <span className="font-semibold"> can</span>
              <span className="font-semibold text-red-700">'t</span> do ... .
            </p>
          </div>

          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase mb-2">
              Positive Questions
            </h3>
            <p>
              <span className="font-semibold">Can</span> I do ... ?
            </p>
            <p>
              <span className="font-semibold">Can</span> you do ... ?
            </p>
            <p>
              <span className="font-semibold">Can</span> he do ... ?
            </p>
            <p>
              <span className="font-semibold">Can</span> she do ... ?
            </p>
            <p>
              <span className="font-semibold">Can</span> it do ... ?
            </p>
            <p>
              <span className="font-semibold">Can</span> we do ... ?
            </p>
            <p>
              <span className="font-semibold">Can</span> you do... ?
            </p>
            <p>
              <span className="font-semibold">Can</span> they do ... ?
            </p>
          </div>

          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase mb-2">
              Negative Questions
            </h3>
            <p>
              <span className="font-semibold">Can</span> I{" "}
              <span className="font-semibold text-red-700">not</span> do ...? /{" "}
              <span className="font-semibold">Can</span>
              <span className="font-semibold text-red-700">'t</span> I do ... ?
            </p>
            <p>
              <span className="font-semibold">Can</span> you{" "}
              <span className="font-semibold text-red-700">not</span> do ... ? /{" "}
              <span className="font-semibold">Can</span>
              <span className="font-semibold text-red-700">'t</span> you do ...
              ?
            </p>
            <p>
              <span className="font-semibold">Can</span> he{" "}
              <span className="font-semibold text-red-700">not</span> do ... ? /{" "}
              <span className="font-semibold">Can</span>
              <span className="font-semibold text-red-700">'t</span> he do ... ?
            </p>
            <p>
              <span className="font-semibold">Can</span> she{" "}
              <span className="font-semibold text-red-700">not</span> do ... ? /{" "}
              <span className="font-semibold">Can</span>
              <span className="font-semibold text-red-700">'t</span> she do ...
              ?
            </p>
            <p>
              <span className="font-semibold">Can</span> it{" "}
              <span className="font-semibold text-red-700">not</span> do ... ? /{" "}
              <span className="font-semibold">Can</span>
              <span className="font-semibold text-red-700">'t</span> it do ... ?
            </p>
            <p>
              <span className="font-semibold">Can</span> we{" "}
              <span className="font-semibold text-red-700">not</span> do ... ? /{" "}
              <span className="font-semibold">Can</span>
              <span className="font-semibold text-red-700">'t</span> we do ... ?
            </p>
            <p>
              <span className="font-semibold">Can</span> you{" "}
              <span className="font-semibold text-red-700">not</span> do ... ? /{" "}
              <span className="font-semibold">Can</span>
              <span className="font-semibold text-red-700">'t</span> you do ...
              ?
            </p>
            <p>
              <span className="font-semibold">Can</span> they{" "}
              <span className="font-semibold text-red-700">not</span> do ... ? /{" "}
              <span className="font-semibold">Can</span>
              <span className="font-semibold text-red-700">'t</span> they do ...
              ?
            </p>
          </div>

          <div className="pb-9">
            <h3 className="font-bold text-pink-700 uppercase mb-2">
              Short Answers
            </h3>
            <p>
              Yes, I <span className="font-semibold">can</span>. / No, I
              <span className="font-semibold"> can</span>
              <span className="font-semibold text-red-700">'t</span>.
            </p>
            <p>
              Yes, you <span className="font-semibold">can</span>. / No, you{" "}
              <span className="font-semibold">can</span>
              <span className="font-semibold text-red-700">'t</span>.
            </p>
            <p>
              Yes, he <span className="font-semibold">can</span>. / No, he{" "}
              <span className="font-semibold">can</span>
              <span className="font-semibold text-red-700">'t</span>.
            </p>
            <p>
              Yes, she <span className="font-semibold">can</span>. / No, she{" "}
              <span className="font-semibold">can</span>
              <span className="font-semibold text-red-700">'t</span>.
            </p>
            <p>
              Yes, it <span className="font-semibold">can</span>. / No, it{" "}
              <span className="font-semibold">can</span>
              <span className="font-semibold text-red-700">'t</span>.
            </p>
            <p>
              Yes, we <span className="font-semibold">can</span>. / No, we{" "}
              <span className="font-semibold">can</span>
              <span className="font-semibold text-red-700">'t</span>.
            </p>
            <p>
              Yes, you <span className="font-semibold">can</span>. / No, you{" "}
              <span className="font-semibold">can</span>
              <span className="font-semibold text-red-700">'t</span>.
            </p>
            <p>
              Yes, they <span className="font-semibold">can</span>. / No, they{" "}
              <span className="font-semibold">can</span>
              <span className="font-semibold text-red-700">'t</span>.
            </p>
          </div>

          <p className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              As you can see, the auxiliary verb "can" is used to make negative
              sentences and questions.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-9">
            <p className="font-semibold pb-6 italic">Examples:</p>{" "}
            <p className="flex mb-3">
              <span className="w-10 font-bold">+</span>
              <span className="italic">She can play the piano.</span>
            </p>
            <p className="flex mb-3">
              <span className="w-10 font-bold">-</span>
              <span className="italic">She cannot / can't play the piano.</span>
            </p>
            <p className="flex mb-3">
              <span className="w-10 font-bold">+?</span>
              <span className="italic">Can she play the piano?</span>
            </p>
            <p className="flex mb-3">
              <span className="w-10 font-bold">-?</span>
              <span className="italic">
                Can't she play the piano? / Can she not play the piano?
              </span>
            </p>
            <p className="flex pb-6">
              <span className="w-10 font-bold"></span>
              <span className="italic">Yes, she can. / No, she can't.</span>
            </p>
          </div>

          <p className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              As you can see, after the auxiliary verb 'can,' we use{" "}
              <mark>the base form of a verb</mark>, which does not take either
              the third person singular "-s", "-ed", or "-ing", and we cannot
              put "to" before it either.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-4">
            <p className="font-semibold pb-6 italic">Example:</p>{" "}
            <p className="pb-1 italic">
              She can<span className="line-through text-red-600">s</span> play
              <span className="line-through text-red-600">s</span> the piano
              well.
            </p>
          </div>

          <p className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              Also, you can see that in negative sentences it is more common to
              write "ca
              <span className="font-bold">nn</span>ot" as one word with no space
              in between.
            </span>
          </p>
        </div>

        {/* ---------- Body in Farsi ---------- */}
        <div
          className={`duration-300 ${
            farsi ? "" : "hidden opacity-0"
          } my-7 mx-5 text-indigo-950 text-xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            Can
          </h1>
          <p lang="fa" dir="rtl" className="mb-6">
            فعل کمکی can را می توانیم در شرایط زیر استفاده کنیم:
          </p>

          <div lang="fa" dir="rtl" className="flex">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="mt-1">
              وقتی می خواهیم راجع به یک <mark>توانایی</mark> صحبت کنیم
            </span>
          </div>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-4">
            <p lang="fa" dir="rtl" className="font-semibold pb-6">
              مثال:
            </p>{" "}
            <p className="mb-1 italic">She can swim very well.</p>
            <p className="mb-3" lang="fa" dir="rtl">
              او می تواند خیلی خوب شنا کند.
            </p>
            <p className="pb-1 italic">He can speak five languages.</p>
            <p className="mb-3" lang="fa" dir="rtl">
              او می تواند به پنج زبان صحبت کند.
            </p>
          </div>

          <div lang="fa" dir="rtl" className="flex mt-7">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="mt-1">
              وقتی می خواهیم راجع به موضوعی صحبت کنیم که <mark>امکان</mark> روی
              دادنش وجود دارد
            </span>
          </div>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-4">
            <p lang="fa" dir="rtl" className="font-semibold pb-6 not-italic">
              مثال:
            </p>{" "}
            <p className="mb-1 italic">Mistakes can happen to anyone.</p>
            <p className="mb-3" lang="fa" dir="rtl">
              اشتباه برای هر کسی می تواند پیش بیآید.
            </p>
            <p className="pb-1 italic">
              Friends can help you feel better when you're sad.
            </p>
            <p className="mb-3" lang="fa" dir="rtl">
              وقتی غمگین هستی، دوستان می توانند کمک کنند حالت بهتر شود.
            </p>
          </div>

          <div lang="fa" dir="rtl" className="flex mt-7">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="mt-1">
              وقتی می خواهیم برای انجام کاری <mark>اجازه</mark> بگیریم یا در
              مورد <mark>اجازه</mark> داشتن یا نداشتن برای انجام کاری صحبت کنیم
            </span>
          </div>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-4">
            <p lang="fa" dir="rtl" className="font-semibold pb-6 not-italic">
              مثال:
            </p>{" "}
            <p className="mb-1 italic">You can't smoke here.</p>
            <p className="mb-3" lang="fa" dir="rtl">
              اینجا نمی توانی (اجازه نداری) سیگار بکشی.
            </p>
            <p className="pb-1 italic">Can I take a photograph?</p>
            <p className="mb-3" lang="fa" dir="rtl">
              آیا می توانم (اجازه دارم) یک عکس بگیرم؟
            </p>
          </div>

          <div lang="fa" dir="rtl" className="flex mt-7">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="mt-1">
              وقتی می خواهیم از کسی <mark>خواهشی</mark> بکنیم
            </span>
          </div>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-4">
            <p lang="fa" dir="rtl" className="font-semibold pb-6 not-italic">
              مثال:
            </p>{" "}
            <p className="mb-1 italic">Can you help me with my homework?</p>
            <p className="mb-3" lang="fa" dir="rtl">
              (آیا) می توانی / شود کمکم کنی تکالیفم را انجام دهم؟
            </p>
            <p className="pb-1 italic">Can you open the window, please?</p>
            <p className="mb-3" lang="fa" dir="rtl">
              می توانی / ممکن است (لطفا) پنجره را باز کنی؟
            </p>
          </div>

          <p lang="fa" dir="rtl" className="mt-12 mb-7">
            در ادامه شکل های مختلف فعل کمکی can را در جملات خبری و سوالی مثبت و
            منفی مشاهده می کنید.
          </p>
          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase mb-2">
              <span>Positive</span>
              <span lang="fa" dir="rtl" className="ml-6">
                خبری مثبت
              </span>
            </h3>
            <p>
              I <span className="font-semibold">can</span> do ... .
            </p>
            <p>
              You <span className="font-semibold">can</span> do ... .
            </p>
            <p>
              He <span className="font-semibold">can</span> do ... .
            </p>
            <p>
              She <span className="font-semibold">can</span> do ... .
            </p>
            <p>
              It <span className="font-semibold">can</span> do ... .
            </p>
            <p>
              We <span className="font-semibold">can</span> do ... .
            </p>
            <p>
              You <span className="font-semibold">can</span> do ... .
            </p>
            <p>
              They <span className="font-semibold">can</span> do ... .
            </p>
          </div>

          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase mb-2">
              <span>Negative</span>
              <span lang="fa" dir="rtl" className="ml-6">
                خبری منفی
              </span>
            </h3>
            <p>
              I <span className="font-semibold">can</span>
              <span className="font-semibold text-red-700">not</span> do ... . /
              I<span className="font-semibold"> can</span>
              <span className="font-semibold text-red-700">'t</span> do ... .
            </p>
            <p>
              You <span className="font-semibold">can</span>
              <span className="font-semibold text-red-700">not</span> do ... . /
              You
              <span className="font-semibold"> can</span>
              <span className="font-semibold text-red-700">'t</span> do ... .
            </p>
            <p>
              He <span className="font-semibold">can</span>
              <span className="font-semibold text-red-700">not</span> do ... . /
              He
              <span className="font-semibold"> can</span>
              <span className="font-semibold text-red-700">'t</span> do ... .
            </p>
            <p>
              She <span className="font-semibold">can</span>
              <span className="font-semibold text-red-700">not</span> do ... . /
              She
              <span className="font-semibold"> can</span>
              <span className="font-semibold text-red-700">'t</span> do ... .
            </p>
            <p>
              It <span className="font-semibold">can</span>
              <span className="font-semibold text-red-700">not</span> do ... . /
              It
              <span className="font-semibold"> can</span>
              <span className="font-semibold text-red-700">'t</span> do ... .
            </p>
            <p>
              We <span className="font-semibold">can</span>
              <span className="font-semibold text-red-700">not</span> do ... . /
              We
              <span className="font-semibold"> can</span>
              <span className="font-semibold text-red-700">'t</span> do ... .
            </p>
            <p>
              You <span className="font-semibold">can</span>
              <span className="font-semibold text-red-700">not</span> do ... . /
              You
              <span className="font-semibold"> can</span>
              <span className="font-semibold text-red-700">'t</span> do ... .
            </p>
            <p>
              They <span className="font-semibold">can</span>
              <span className="font-semibold text-red-700">not</span> do ... . /
              They
              <span className="font-semibold"> can</span>
              <span className="font-semibold text-red-700">'t</span> do ... .
            </p>
          </div>

          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase mb-2">
              <span>Positive Questions</span>
              <span lang="fa" dir="rtl" className="ml-6">
                سوالی مثبت
              </span>
            </h3>
            <p>
              <span className="font-semibold">Can</span> I do ... ?
            </p>
            <p>
              <span className="font-semibold">Can</span> you do ... ?
            </p>
            <p>
              <span className="font-semibold">Can</span> he do ... ?
            </p>
            <p>
              <span className="font-semibold">Can</span> she do ... ?
            </p>
            <p>
              <span className="font-semibold">Can</span> it do ... ?
            </p>
            <p>
              <span className="font-semibold">Can</span> we do ... ?
            </p>
            <p>
              <span className="font-semibold">Can</span> you do... ?
            </p>
            <p>
              <span className="font-semibold">Can</span> they do ... ?
            </p>
          </div>

          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase mb-2">
              <span>Negative Questions</span>
              <span lang="fa" dir="rtl" className="ml-6">
                سوالی منفی
              </span>
            </h3>
            <p>
              <span className="font-semibold">Can</span> I{" "}
              <span className="font-semibold text-red-700">not</span> do ...? /{" "}
              <span className="font-semibold">Can</span>
              <span className="font-semibold text-red-700">'t</span> I do ... ?
            </p>
            <p>
              <span className="font-semibold">Can</span> you{" "}
              <span className="font-semibold text-red-700">not</span> do ... ? /{" "}
              <span className="font-semibold">Can</span>
              <span className="font-semibold text-red-700">'t</span> you do ...
              ?
            </p>
            <p>
              <span className="font-semibold">Can</span> he{" "}
              <span className="font-semibold text-red-700">not</span> do ... ? /{" "}
              <span className="font-semibold">Can</span>
              <span className="font-semibold text-red-700">'t</span> he do ... ?
            </p>
            <p>
              <span className="font-semibold">Can</span> she{" "}
              <span className="font-semibold text-red-700">not</span> do ... ? /{" "}
              <span className="font-semibold">Can</span>
              <span className="font-semibold text-red-700">'t</span> she do ...
              ?
            </p>
            <p>
              <span className="font-semibold">Can</span> it{" "}
              <span className="font-semibold text-red-700">not</span> do ... ? /{" "}
              <span className="font-semibold">Can</span>
              <span className="font-semibold text-red-700">'t</span> it do ... ?
            </p>
            <p>
              <span className="font-semibold">Can</span> we{" "}
              <span className="font-semibold text-red-700">not</span> do ... ? /{" "}
              <span className="font-semibold">Can</span>
              <span className="font-semibold text-red-700">'t</span> we do ... ?
            </p>
            <p>
              <span className="font-semibold">Can</span> you{" "}
              <span className="font-semibold text-red-700">not</span> do ... ? /{" "}
              <span className="font-semibold">Can</span>
              <span className="font-semibold text-red-700">'t</span> you do ...
              ?
            </p>
            <p>
              <span className="font-semibold">Can</span> they{" "}
              <span className="font-semibold text-red-700">not</span> do ... ? /{" "}
              <span className="font-semibold">Can</span>
              <span className="font-semibold text-red-700">'t</span> they do ...
              ?
            </p>
          </div>

          <div className="pb-9">
            <h3 className="font-bold text-pink-700 uppercase mb-2">
              <span>Short Answers</span>
              <span lang="fa" dir="rtl" className="ml-6">
                پاسخ کوتاه
              </span>
            </h3>
            <p>
              Yes, I <span className="font-semibold">can</span>. / No, I
              <span className="font-semibold"> can</span>
              <span className="font-semibold text-red-700">'t</span>.
            </p>
            <p>
              Yes, you <span className="font-semibold">can</span>. / No, you{" "}
              <span className="font-semibold">can</span>
              <span className="font-semibold text-red-700">'t</span>.
            </p>
            <p>
              Yes, he <span className="font-semibold">can</span>. / No, he{" "}
              <span className="font-semibold">can</span>
              <span className="font-semibold text-red-700">'t</span>.
            </p>
            <p>
              Yes, she <span className="font-semibold">can</span>. / No, she{" "}
              <span className="font-semibold">can</span>
              <span className="font-semibold text-red-700">'t</span>.
            </p>
            <p>
              Yes, it <span className="font-semibold">can</span>. / No, it{" "}
              <span className="font-semibold">can</span>
              <span className="font-semibold text-red-700">'t</span>.
            </p>
            <p>
              Yes, we <span className="font-semibold">can</span>. / No, we{" "}
              <span className="font-semibold">can</span>
              <span className="font-semibold text-red-700">'t</span>.
            </p>
            <p>
              Yes, you <span className="font-semibold">can</span>. / No, you{" "}
              <span className="font-semibold">can</span>
              <span className="font-semibold text-red-700">'t</span>.
            </p>
            <p>
              Yes, they <span className="font-semibold">can</span>. / No, they{" "}
              <span className="font-semibold">can</span>
              <span className="font-semibold text-red-700">'t</span>.
            </p>
          </div>

          <p lang="fa" dir="rtl" className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pr-3">
              همانطور که مشاهده می کنید، فعل کمکی can خودش برای منفی و سوالی
              کردن جملات استفاده می شود.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-6 not-italic">
              مثال:
            </p>{" "}
            <p className="flex mb-3">
              <span className="w-10 font-bold">+</span>
              <span className="italic">She can play the piano.</span>
            </p>
            <p className="flex mb-3">
              <span className="w-10 font-bold">-</span>
              <span className="italic">She cannot / can't play the piano.</span>
            </p>
            <p className="flex mb-3">
              <span className="w-10 font-bold">+?</span>
              <span className="italic">Can she play the piano?</span>
            </p>
            <p className="flex mb-3">
              <span className="w-10 font-bold">-?</span>
              <span className="italic">
                Can't she play the piano? / Can she not play the piano?
              </span>
            </p>
            <p className="flex pb-6">
              <span className="w-10 font-bold"></span>
              <span className="italic">Yes, she can. / No, she can't.</span>
            </p>
          </div>

          <p lang="fa" dir="rtl" className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pr-3">
              همانطور که در تمام مثال ها می بینید، بعد از فعل کمکی can شکل ساده
              فعل را استفاده می کنیم که نه ing- می گیرد، نه ed-، نه s- سوم شخص
              مفرد و نه قبلش to می آید.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-4">
            <p lang="fa" dir="rtl" className="font-semibold pb-6 not-italic">
              مثال:
            </p>{" "}
            <p className="pb-1 italic">
              She can<span className="line-through text-red-600">s</span> play
              <span className="line-through text-red-600">s</span> the piano
              well.
            </p>
          </div>

          <p lang="fa" dir="rtl" className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              همچنین مشاهده می کنید که در جملات منفی مصطلح تر است "ca
              <span className="font-bold">nn</span>ot" را به صورت یک کلمه،
              چسبیده و بدون فاصله بنویسیم.
            </span>
          </p>
        </div>
      </div>
      <ExerciseCard questions={questions} instruction={instruction} />
    </>
  );
};
