import { useState } from "react";
import Bulb from "../../assets/images/bulb.png";

export const Questions = ({ questions }) => {
  const [questionStates, setQuestionsStates] = useState(
    questions.map(() => false)
  );

  const toggleAnswer = (index) => {
    const updatedStates = [...questionStates];
    updatedStates[index] = !updatedStates[index];
    setQuestionsStates(updatedStates);
  };

  return (
    <>
      {questions.map((q, index) => (
        <div key={q.id}>
          <p className="mb-5">
            <span className="font-medium pr-3">{q.id}</span>
            <span>{q.question}</span>
          </p>
          <button
            onClick={() => toggleAnswer(index)}
            className="flex flex-row bg-fuchsia-900 hover:bg-fuchsia-700 px-3 py-2 text-white rounded-lg mb-5 cursor-pointer"
          >
            <span className="pr-2 pb-1">answer</span>
            <span>
              {questionStates[index] ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="size-6 mt-1"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="size-6 mt-1"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
              )}
            </span>
          </button>
          <div
            className={`transition-all duration-700 ${
              questionStates[index]
                ? "max-h-screen"
                : "overflow-hidden max-h-0 opacity-0"
            }`}
          >
            <p className="bg-pink-50 text-fuchsia-800 px-3 py-2 mb-5 rounded-lg">
              {q.answer}
            </p>

            {q.explanation && (
              <p className="flex mb-5 border border-fuchsia-800 rounded-lg p-3">
                <img
                  className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
                  src={Bulb}
                  alt="Bulb"
                />
                <span className="pl-3">{q.explanation}</span>
              </p>
            )}
          </div>
        </div>
      ))}
    </>
  );
};
