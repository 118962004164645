import { useState } from "react";
import { Link } from "react-router-dom";
import { LanguageSwitch, ExerciseCard, useTitle } from "../../../components";
import Bulb from "../../../assets/images/bulb.png";

export const PossessiveAdjectivesElementary = ({ title }) => {
  const [farsi, setFarsi] = useState(false);
  const instruction = "Complete the sentences with a possessive adjective.";
  const questions = [
    {
      id: 1,
      question: "She is our classmate. ____ name is Sara.",
      answer: (
        <span>
          She is our classmate. <span className="font-bold">Her</span> name is
          Sara.
        </span>
      ),
    },
    {
      id: 2,
      question: "Our neighbors are from Germany. ____ names are Max and Emma.",
      answer: (
        <span>
          Our neighbors are from Germany.{" "}
          <span className="font-bold">Their</span> names are Max and Emma.
        </span>
      ),
    },
    {
      id: 3,
      question: "Paris is famous for ____ romantic atmosphere.",
      answer: (
        <span>
          Paris is famous for
          <span className="font-bold"> its</span> romantic atmosphere.
        </span>
      ),
    },
    {
      id: 4,
      question: "We're in the same class. ____ teacher is Ms. Roberts.",
      answer: (
        <span>
          We're in the same class.
          <span className="font-bold"> Our</span> teacher is Ms. Roberts.
        </span>
      ),
    },
    {
      id: 5,
      question: "Jimmy, can you spell ____ last name, please?",
      answer: (
        <span>
          Jimmy, can you spell
          <span className="font-bold"> your</span> last name, please?
        </span>
      ),
    },
    {
      id: 6,
      question: "My name is Robert, but ____ friends call me Bob.",
      answer: (
        <span>
          My name is Robert, but
          <span className="font-bold"> my</span> friends call me Bob.
        </span>
      ),
    },
  ];

  useTitle(!farsi ? title : "صفات ملکی");
  return (
    <>
      <div data-aos="fade" data-aos-duration="1500">
        {/* ---------- Breadcrumb Menu ---------- */}
        <nav className="flex pl-3 pt-2 text-gray-400 text-sm font-semibold">
          <Link to="/" className="flex duration-300 hover:text-indigo-950">
            <span className="pt-0.5 md:pr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
            </span>
            <span className="max-md:hidden">Home</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link to="/grammar" className="duration-300 hover:text-indigo-950">
            <span>Grammar</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link
            to="/grammar/elementary"
            className="duration-300 hover:text-indigo-950"
          >
            <span>A1 - A2</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <span className="text-indigo-950">Possessive Adjectives</span>
        </nav>
      </div>

      <div data-aos="zoom-in" data-aos-duration="800">
        {/* ---------- Language Switch ---------- */}
        <LanguageSwitch farsi={farsi} setFarsi={setFarsi} />

        {/* ---------- Body in English ---------- */}
        <div
          className={`duration-300 ${
            farsi ? "hidden opacity-0" : ""
          } my-7 mx-5 text-indigo-950 text-xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            Possessive Adjectives
          </h1>
          <p className="mb-5">
            Possessive adjectives show who or what owns something. In other
            words, they tell us who or what something belongs to.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-3">Example:</p>{" "}
            <p className="pb-1">
              <span className="font-bold">My</span> book is blue.
            </p>
            <p className="pb-1">
              Is that <span className="font-bold">your</span> pencil?
            </p>
            <p className="pb-1">
              The dog is wagging <span className="font-bold">its</span> tale.
            </p>
            <p className="pb-1">
              We need to clean up <span className="font-bold">our</span> room.
            </p>
          </div>

          <p className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              Remember that we must always put a noun after a possessive
              adjective.
            </span>
          </p>
          <p className="mb-9">
            These are the{" "}
            <span className="font-bold">possessive adjectives</span> in English:
          </p>

          <div className="grid grid-cols-2 border border-indigo-950 w-64 mx-auto text-center mb-9">
            <div className="col-span-2 uppercase py-1 text-pink-700 font-bold border-b border-indigo-950">
              Possessive Adjectives
            </div>
            <div className="py-1 font-semibold border-b border-r border-indigo-950">
              Singular
            </div>
            <div className="py-1 font-semibold border-b border-indigo-950">
              Plural
            </div>
            <div className="py-1 border-b border-r border-indigo-950">my</div>
            <div className="py-1 border-b border-indigo-950">our</div>
            <div className="py-1 border-b border-r border-indigo-950">your</div>
            <div className="py-1 border-b border-indigo-950">your</div>
            <div className="py-1 border-r border-indigo-950">
              his
              <br />
              her
              <br />
              its
              <br />
            </div>
            <div className="py-1 flex items-center justify-center">their</div>
          </div>
        </div>

        {/* ---------- Body in Farsi ---------- */}
        <div
          className={`duration-300 ${
            farsi ? "" : "hidden opacity-0"
          } my-7 mx-5 text-indigo-950 text-xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            Possessive Adjectives <br />
            <span lang="fa" dir="rtl" className="text-2xl">
              صفات ملکی
            </span>
          </h1>
          <p lang="fa" dir="rtl" className="mb-6">
            صفت ملکی کلمه ایست که نشان می دهد چیزی به کسی یا چیزی تعلق دارد.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-3">
              مثال:
            </p>{" "}
            <p className="pb-1 italic">
              <span className="font-bold">My</span> book is blue.
            </p>
            <p lang="fa" dir="rtl" className="pb-2">
              کتاب <span className="font-bold">من</span> آبی ست.
            </p>
            <p className="pb-1 italic">
              Is that <span className="font-bold">your</span> pencil?
            </p>
            <p lang="fa" dir="rtl" className="pb-2">
              آیا آن مداد <span className="font-bold">تو</span> است؟
            </p>
            <p className="pb-1 italic">
              The dog is wagging <span className="font-bold">its</span> tale.
            </p>
            <p lang="fa" dir="rtl" className="pb-2">
              آن سگ دارد دم<span className="font-bold">ش</span> را تکان می دهد.
            </p>
            <p className="pb-1 italic">
              We need to clean up <span className="font-bold">our</span> room.
            </p>
            <p lang="fa" dir="rtl" className="pb-2">
              ما باید اتاق<span className="font-bold">مان</span> را تمیز کنیم.
            </p>
          </div>

          <p lang="fa" dir="rtl" className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              یادمان باشد بعد از یک صفت ملکی همیشه باید یک اسم داشته باشیم.
            </span>
          </p>

          <p lang="fa" dir="rtl" className="pb-9">
            در جدول زیر <span className="font-bold">صفات ملکی</span> در زبان
            انگلیسی را مشاهده می کنید.
          </p>
          <div className="grid grid-cols-2 border border-indigo-950 w-80 mx-auto text-center mb-9">
            <div className="col-span-2 uppercase py-1 text-pink-700 font-bold border-b border-indigo-950">
              Subject Pronouns
              <br />
              <span lang="fa" dir="rtl">
                صفات ملکی
              </span>
            </div>
            <div className="py-1 font-semibold border-b border-r border-indigo-950">
              Singular
              <br />
              <span lang="fa" dir="rtl">
                مفرد
              </span>
            </div>
            <div className="py-1 font-semibold border-b border-indigo-950">
              Plural
              <br />
              <span lang="fa" dir="rtl">
                جمع
              </span>
            </div>
            <div className="py-1 border-b border-r border-indigo-950">
              <span className="font-medium">I</span>
              <span lang="fa" dir="rtl" className="pl-3">
                من / م
              </span>
            </div>
            <div className="py-1 border-b border-indigo-950">
              <span className="font-medium">we</span>
              <span lang="fa" dir="rtl" className="pl-3">
                ما / مان
              </span>
            </div>
            <div className="py-1 border-b border-r border-indigo-950">
              <span className="font-medium">you</span>
              <span lang="fa" dir="rtl" className="pl-3">
                تو / ت
              </span>
            </div>
            <div className="py-1 border-b border-indigo-950">
              <span className="font-medium">you</span>
              <span lang="fa" dir="rtl" className="pl-3">
                شما / تان
              </span>
            </div>
            <div className="py-1 border-r border-indigo-950">
              <span className="font-medium">he</span>
              <span lang="fa" dir="rtl" className="pl-3">
                او (مرد) / ش
              </span>
              <br />
              <span className="font-medium">she</span>{" "}
              <span lang="fa" dir="rtl" className="pl-3">
                او (زن) / ش
              </span>
              <br />
              <span className="font-medium">it</span>
              <span lang="fa" dir="rtl" className="pl-3">
                این / آن / ش
              </span>
            </div>
            <div className="py-1 flex flex-col items-center justify-center">
              <span className="font-medium">they</span>

              <span lang="fa" dir="rtl">
                آنها / ایشان / شان
              </span>
            </div>
          </div>
        </div>
      </div>
      <ExerciseCard questions={questions} instruction={instruction} />
    </>
  );
};
