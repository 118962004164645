import { useState } from "react";
import { Link } from "react-router-dom";
import { LanguageSwitch, ExerciseCard, useTitle } from "../../../components";

export const V2ingElementary = ({ title }) => {
  const [farsi, setFarsi] = useState(false);
  const instruction = (
    <span>
      Complete the sentences by writing the correct form of the verbs in
      parentheses.
    </span>
  );
  const questions = [
    {
      id: 1,
      question: <span>I ____ to strangers. (avoid / talk)</span>,
      answer: (
        <span>
          I <span className="font-bold">avoid talking</span> to strangers.
        </span>
      ),
    },
    {
      id: 2,
      question: <span>She ____ the cookies. (deny / take)</span>,
      answer: (
        <span>
          She <span className="font-bold">denies taking</span> the cookies.
        </span>
      ),
    },
    {
      id: 3,
      question: <span>I ____ my homework three hours ago. (finish / do)</span>,
      answer: (
        <span>
          I <span className="font-bold">finished doing</span> my homework three
          hours ago.
        </span>
      ),
    },
    {
      id: 4,
      question: <span>I ____ to classical music. (not / enjoy / listen)</span>,
      answer: (
        <span>
          I <span className="font-bold">don't enjoy listening</span> to
          classical music.
        </span>
      ),
    },
    {
      id: 5,
      question: <span>____ you ____ for people? (mind / wait)</span>,
      answer: (
        <span>
          <span className="font-bold">Do</span> you{" "}
          <span className="font-bold">mind waiting</span> for people?
        </span>
      ),
    },
  ];

  useTitle(title);

  return (
    <>
      {/* -------------------- Breadcrumb Menu -------------------- */}
      <div data-aos="fade" data-aos-duration="1500">
        <nav className="flex pl-3 pt-2 text-gray-400 text-sm font-semibold">
          <Link to="/" className="flex duration-300 hover:text-indigo-950">
            <span className="pt-0.5 md:pr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
            </span>
            <span className="max-md:hidden">Home</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link to="/grammar" className="duration-300 hover:text-indigo-950">
            <span>Grammar</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link
            to="/grammar/elementary"
            className="duration-300 hover:text-indigo-950"
          >
            <span>A1 - A2</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <span className="text-indigo-950">V.1 + V.2-ing</span>
        </nav>
      </div>

      <div data-aos="zoom-in" data-aos-duration="800">
        {/* -------------------- Language Switch -------------------- */}
        <LanguageSwitch farsi={farsi} setFarsi={setFarsi} />

        {/* -------------------- Body in English -------------------- */}
        <div
          className={`duration-300 ${
            farsi ? "hidden opacity-0" : ""
          } my-7 mx-5 text-indigo-950 text-xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            V.1 + V.2-ing
          </h1>
          <p className="mb-9">
            After some verbs like "<span className="font-bold">enjoy</span>", "
            <span className="font-bold">don't mind</span>", "
            <span className="font-bold">avoid</span>", "
            <span className="font-bold">deny</span>", "
            <span className="font-bold">finish</span>" etc., if there is a
            second verb, it takes the "<span className="font-bold">-ing</span>"
            form or is a <span className="underline">gerund</span>.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-3">Examples:</p>{" "}
            <p className="pb-2">
              I enjoy play<span className="font-bold">ing</span> football.
            </p>
            <p className="pb-2">
              He doesn't mind do<span className="font-bold">ing</span> the
              dishes.
            </p>
            <p className="pb-2">
              I finished read<span className="font-bold">ing</span> the book
              yesterday.
            </p>
            <p className="pb-2">
              We avoid eat<span className="font-bold">ing</span> junk food.
            </p>
            <p className="pb-2">
              He denied break<span className="font-bold">ing</span> the window.
            </p>
          </div>

          <p className="mb-9">
            <span>
              For special spelling rules when adding '-ing' to a verb, refer to{" "}
              <Link
                to="/grammar/elementary/presentContinuous"
                target="_blank"
                className="underline text-fuchsia-900"
              >
                the Present Continuous (A1 - A2)
                <span className="inline-block pl-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-4"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"
                    />
                  </svg>
                </span>
              </Link>{" "}
              section on this website, where these rules are explained in
              detail.
            </span>
          </p>
        </div>

        {/* ---------- Body in Farsi ---------- */}
        <div
          className={`duration-300 ${
            farsi ? "" : "hidden opacity-0"
          } my-7 mx-5 text-indigo-950 text-xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            V.1 + V.2-ing
          </h1>
          <p lang="fa" dir="rtl" className="mb-9">
            در زبان انگلیسی اگر بعد از برخی فعل ها مانند enjoy ،avoid ،don't
            mind ،deny و finish فعل دیگری داشته باشیم، فعل دوم "ing-" می گیرد یا
            به اصطلاح gerund می شود.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-3 not-italic">
              مثال:
            </p>{" "}
            <p className="pb-1">
              I enjoy play<span className="font-bold">ing</span> football.
            </p>
            <p lang="fa" dir="rtl" className="mb-3 not-italic">
              من از فوتبال بازی کردن لذت می برم.
            </p>
            <p className="pb-1">
              He doesn't mind do<span className="font-bold">ing</span> the
              dishes.
            </p>
            <p lang="fa" dir="rtl" className="mb-3 not-italic">
              او با شستن ظرف ها مشکلی ندارد.
            </p>
            <p className="pb-1">
              I finished read<span className="font-bold">ing</span> the book
              yesterday.
            </p>
            <p lang="fa" dir="rtl" className="mb-3 not-italic">
              دیروز خواندن این کتاب را تمام کردم.
            </p>
            <p className="pb-1">
              We avoid eat<span className="font-bold">ing</span> junk food.
            </p>
            <p lang="fa" dir="rtl" className="mb-3 not-italic">
              ما از خوردن هله هوله پرهیز می کنیم.
            </p>
            <p className="pb-1">
              He denied break<span className="font-bold">ing</span> the window.
            </p>
            <p lang="fa" dir="rtl" className="mb-1 not-italic">
              او منکر شکستن پنجره شد.
            </p>
          </div>

          <p lang="fa" dir="rtl" className="mb-9">
            <span>
              برای مشاهده توضیحات مربوط به تغییرات املایی که ممکن است در فعل ها
              بعد از گرفتن "ing-" ایجاد شود می توانید به بخش{" "}
              <Link
                to="/grammar/elementary/presentContinuous"
                target="_blank"
                className="underline text-fuchsia-900"
              >
                the Present Continuous (A1 - A2)
                <span className="inline-block pr-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-4"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"
                    />
                  </svg>
                </span>
              </Link>{" "}
              در این وبسایت رجوع کنید.
            </span>
          </p>
        </div>
      </div>
      <ExerciseCard questions={questions} instruction={instruction} />
    </>
  );
};
