import { useState } from "react";
import { Link } from "react-router-dom";
import { LanguageSwitch, ExerciseCard, useTitle } from "../../../components";
import Bulb from "../../../assets/images/bulb.png";

export const ToHave = ({ title }) => {
  const [farsi, setFarsi] = useState(false);
  const instruction = (
    <span>
      Complete the mini-dialogues below with the correct form of the verb{" "}
      <span className="underline">have</span>,{" "}
      <span className="underline">do</span> or{" "}
      <span className="underline">does</span>. Use contractions where possible.
    </span>
  );
  const questions = [
    {
      id: 1,
      question: (
        <span>
          <span>"____ you ____ a brother?"</span>
          <span className="ml-8">"No, I ____, but I ____ two sisters."</span>
        </span>
      ),
      answer: (
        <span className="font-normal">
          <span>
            "<span className="font-bold">Do</span> you{" "}
            <span className="font-bold">have</span> a brother?"
          </span>
          <span className="ml-8">
            "No, I<span className="font-bold"> don't</span>, but I
            <span className="font-bold"> have</span> two sisters."
          </span>
        </span>
      ),
    },
    {
      id: 2,
      question: (
        <span>
          <span>"How many friends ____ he ____?"</span>
          <span className="pl-8">"I'm afraid, he ____ any friends."</span>
        </span>
      ),
      answer: (
        <span>
          <span>
            "How many friends <span className="font-bold">does</span> he
            <span className="font-bold"> have</span>?
          </span>
          <span className="pl-8">
            "I'm afraid, he <span className="font-bold">doesn't have</span> any
            friends."
          </span>
        </span>
      ),
    },
    {
      id: 3,
      question: (
        <span>
          <span>"____ you ____ a pet?"</span>
          <span className="ml-8">
            "No, I ____. I ____ any pets, but my sister ____ two cats."
          </span>
        </span>
      ),
      answer: (
        <span>
          <span>
            "<span className="font-bold">Do</span> you{" "}
            <span className="font-bold">have</span> a pet?"
          </span>
          <span className="ml-8">
            "No, I <span className="font-bold">don't</span>. I{" "}
            <span className="font-bold">don't have</span> any pets, but my
            sister <span className="font-bold">has</span> two cats."
          </span>
        </span>
      ),
    },
    {
      id: 4,
      question: (
        <span>
          <span>"____ she ____ a car?"</span>
          <span className="ml-8">"Yes, she ____. She ____ a Mercedes."</span>
        </span>
      ),
      answer: (
        <span>
          <span>
            "<span className="font-bold">Does</span> she{" "}
            <span className="font-bold">have</span> a car?"
          </span>
          <span className="ml-8">
            "Yes, she <span className="font-bold">does</span>. She{" "}
            <span className="font-bold">has</span> a Mercedes."
          </span>
        </span>
      ),
    },
  ];

  useTitle(title);

  return (
    <>
      {/* -------------------- Breadcrumb Menu -------------------- */}
      <div data-aos="fade" data-aos-duration="1500">
        <nav className="flex pl-3 pt-2 text-gray-400 text-sm font-semibold">
          <Link to="/" className="flex duration-300 hover:text-indigo-950">
            <span className="pt-0.5 md:pr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
            </span>
            <span className="max-md:hidden">Home</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link to="/grammar" className="duration-300 hover:text-indigo-950">
            <span>Grammar</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link
            to="/grammar/elementary"
            className="duration-300 hover:text-indigo-950"
          >
            <span>A1 - A2</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <span className="text-indigo-950">To Have</span>
        </nav>
      </div>

      <div data-aos="zoom-in" data-aos-duration="800">
        {/* -------------------- Language Switch -------------------- */}
        <LanguageSwitch farsi={farsi} setFarsi={setFarsi} />

        {/* -------------------- Body in English -------------------- */}
        <div
          className={`duration-300 ${
            farsi ? "hidden opacity-0" : ""
          } my-7 mx-5 text-indigo-950 text-xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            To Have
          </h1>
          <p className="mb-9">
            The verb "to have" has special forms. We change its form depending
            on the subject, which is called conjugation. For example, we say "I
            have," "she has," "they have," etc.
          </p>

          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase">Positive</h3>
            <p>
              I <span className="font-semibold">have</span> ... .
            </p>
            <p>
              You <span className="font-semibold">have</span> ... .
            </p>
            <p>
              He <span className="font-semibold">has</span> ... .
            </p>
            <p>
              She <span className="font-semibold">has</span> ... .
            </p>
            <p>
              It <span className="font-semibold">has</span> ... .
            </p>
            <p>
              We <span className="font-semibold">have</span> ... .
            </p>
            <p>
              You <span className="font-semibold">have</span> ... .
            </p>
            <p>
              They <span className="font-semibold">have</span> ... .
            </p>
          </div>

          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase">Negative</h3>
            <p>
              I <span className="font-semibold">do</span>{" "}
              <span className="font-semibold text-red-700">not</span> have ... .
              / I<span className="font-semibold"> do</span>
              <span className="font-semibold text-red-700">n't</span> have ... .
            </p>
            <p>
              You <span className="font-semibold">do</span>{" "}
              <span className="font-semibold text-red-700">not</span> have ... .
              / You
              <span className="font-semibold"> do</span>
              <span className="font-semibold text-red-700">n't</span> have ... .
            </p>
            <p>
              He <span className="font-semibold">does</span>{" "}
              <span className="font-semibold text-red-700">not</span> have ... .
              / He
              <span className="font-semibold"> does</span>
              <span className="font-semibold text-red-700">n't</span> have ... .
            </p>
            <p>
              She <span className="font-semibold">does</span>{" "}
              <span className="font-semibold text-red-700">not</span> have ... .
              / She
              <span className="font-semibold"> does</span>
              <span className="font-semibold text-red-700">n't</span> have ... .
            </p>
            <p>
              It <span className="font-semibold">does</span>{" "}
              <span className="font-semibold text-red-700">not</span> have ... .
              / It
              <span className="font-semibold"> does</span>
              <span className="font-semibold text-red-700">n't</span> have ... .
            </p>
            <p>
              We <span className="font-semibold">do</span>{" "}
              <span className="font-semibold text-red-700">not</span> have ... .
              / We
              <span className="font-semibold"> do</span>
              <span className="font-semibold text-red-700">n't</span> have ... .
            </p>
            <p>
              You <span className="font-semibold">do</span>{" "}
              <span className="font-semibold text-red-700">not</span> have ... .
              / You
              <span className="font-semibold"> do</span>
              <span className="font-semibold text-red-700">n't</span> have ... .
            </p>
            <p>
              They <span className="font-semibold">do</span>{" "}
              <span className="font-semibold text-red-700">not</span> have ... .
              / They
              <span className="font-semibold"> do</span>
              <span className="font-semibold text-red-700">n't</span> have ... .
            </p>
          </div>

          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase">
              Positive Questions
            </h3>
            <p>
              <span className="font-semibold">Do</span> I have ... ?
            </p>
            <p>
              <span className="font-semibold">Do</span> you have ... ?
            </p>
            <p>
              <span className="font-semibold">Does</span> he have ... ?
            </p>
            <p>
              <span className="font-semibold">Does</span> she have ... ?
            </p>
            <p>
              <span className="font-semibold">Does</span> it have ... ?
            </p>
            <p>
              <span className="font-semibold">Do</span> we have ... ?
            </p>
            <p>
              <span className="font-semibold">Do</span> you have... ?
            </p>
            <p>
              <span className="font-semibold">Do</span> they have ... ?
            </p>
          </div>

          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase">
              Negative Questions
            </h3>
            <p>
              <span className="font-semibold">Do</span> I{" "}
              <span className="font-semibold text-red-700">not</span> have ...?
              / <span className="font-semibold">Do</span>
              <span className="font-semibold text-red-700">n't</span> I have ...
              ?
            </p>
            <p>
              <span className="font-semibold">Do</span> you{" "}
              <span className="font-semibold text-red-700">not</span> have ... ?
              / <span className="font-semibold">Do</span>
              <span className="font-semibold text-red-700">n't</span> you have
              ... ?
            </p>
            <p>
              <span className="font-semibold">Does</span> he{" "}
              <span className="font-semibold text-red-700">not</span> have ... ?
              / <span className="font-semibold">Does</span>
              <span className="font-semibold text-red-700">n't</span> he have
              ... ?
            </p>
            <p>
              <span className="font-semibold">Does</span> she{" "}
              <span className="font-semibold text-red-700">not</span> have ... ?
              / <span className="font-semibold">Does</span>
              <span className="font-semibold text-red-700">n't</span> she have
              ... ?
            </p>
            <p>
              <span className="font-semibold">Does</span> it{" "}
              <span className="font-semibold text-red-700">not</span> have ... ?
              / <span className="font-semibold">Does</span>
              <span className="font-semibold text-red-700">n't</span> it have
              ... ?
            </p>
            <p>
              <span className="font-semibold">Do</span> we{" "}
              <span className="font-semibold text-red-700">not</span> have ... ?
              / <span className="font-semibold">Do</span>
              <span className="font-semibold text-red-700">n't</span> we have
              ... ?
            </p>
            <p>
              <span className="font-semibold">Do</span> you{" "}
              <span className="font-semibold text-red-700">not</span> have ... ?
              / <span className="font-semibold">Do</span>
              <span className="font-semibold text-red-700">n't</span> you have
              ... ?
            </p>
            <p>
              <span className="font-semibold">Do</span> they{" "}
              <span className="font-semibold text-red-700">not</span> have ... ?
              / <span className="font-semibold">Do</span>
              <span className="font-semibold text-red-700">n't</span> they have
              ... ?
            </p>
          </div>

          <div className="pb-9">
            <h3 className="font-bold text-pink-700 uppercase">Short Answers</h3>
            <p>
              Yes, I <span className="font-semibold">do</span>. / No, I
              <span className="font-semibold"> do</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, you <span className="font-semibold">do</span>. / No, you{" "}
              <span className="font-semibold">do</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, he <span className="font-semibold">does</span>. / No, he{" "}
              <span className="font-semibold">does</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, she <span className="font-semibold">does</span>. / No, she{" "}
              <span className="font-semibold">does</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, it <span className="font-semibold">does</span>. / No, it{" "}
              <span className="font-semibold">does</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, we <span className="font-semibold">do</span>. / No, we{" "}
              <span className="font-semibold">do</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, you <span className="font-semibold">do</span>. / No, you{" "}
              <span className="font-semibold">do</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, they <span className="font-semibold">do</span>. / No, they{" "}
              <span className="font-semibold">do</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
          </div>

          <p className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              As you can see, when we use the verb "have" as the main verb in a
              sentence, the auxiliary verb is either "do," or "does" in the
              Simple Present tense.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-9">
            <p className="font-semibold pb-6 italic">Examples:</p>{" "}
            <p className="flex pb-1">
              <span className="w-10 font-bold">+</span>
              <span className="italic">She has a big house.</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">-</span>
              <span className="italic">She doesn't have a big house.</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">+?</span>
              <span className="italic">Does she have a big house?</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">-?</span>
              <span className="italic">
                Doesn't she have a big house? / Does she not have a big house?
              </span>
            </p>
            <p className="flex pb-6">
              <span className="w-10 font-bold"></span>
              <span className="italic">Yes, she does. / No, she doesn't.</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">+</span>
              <span className="italic">They have a dog.</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">-</span>
              <span className="italic">They don't have a dog.</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">+?</span>
              <span className="italic">Do they have a dog?</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">-?</span>
              <span className="italic">
                Don't they have a dog? / Do they not have a dog?
              </span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold"></span>
              <span className="italic">Yes, they do. / No, they don't.</span>
            </p>
          </div>
        </div>

        {/* ---------- Body in Farsi ---------- */}
        <div
          className={`duration-300 ${
            farsi ? "" : "hidden opacity-0"
          } my-7 mx-5 text-indigo-950 text-xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            To Have <br />
            <span lang="fa" dir="rtl" className="text-2xl">
              داشتن
            </span>
          </h1>

          <p lang="fa" dir="rtl" className="mb-9">
            فعل have به معنی «داشتن» یکی از رایج ترین فعل ها در زبان انگلیسی
            است. نکته متفاوت در مورد این فعل این است که بر خلاف اغلب افعال در
            زبان انگلیسی برای فاعل های متفاوت صرف می شود.
          </p>
          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase">
              Positive{" "}
              <span lang="fa" dir="rtl" className="ml-5">
                خبری مثبت
              </span>
            </h3>
            <p>
              I <span className="font-semibold">have</span> ... .
            </p>
            <p>
              You <span className="font-semibold">have</span> ... .
            </p>
            <p>
              He <span className="font-semibold">has</span> ... .
            </p>
            <p>
              She <span className="font-semibold">has</span> ... .
            </p>
            <p>
              It <span className="font-semibold">has</span> ... .
            </p>
            <p>
              We <span className="font-semibold">have</span> ... .
            </p>
            <p>
              You <span className="font-semibold">have</span> ... .
            </p>
            <p>
              They <span className="font-semibold">have</span> ... .
            </p>
          </div>
          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase">
              Negative{" "}
              <span lang="fa" dir="rtl" className="ml-5">
                خبری منفی
              </span>
            </h3>
            <p>
              I <span className="font-semibold">do</span>{" "}
              <span className="font-semibold text-red-700">not</span> have ... .
              / I<span className="font-semibold"> do</span>
              <span className="font-semibold text-red-700">n't</span> have ... .
            </p>
            <p>
              You <span className="font-semibold">do</span>{" "}
              <span className="font-semibold text-red-700">not</span> have ... .
              / You
              <span className="font-semibold"> do</span>
              <span className="font-semibold text-red-700">n't</span> have ... .
            </p>
            <p>
              He <span className="font-semibold">does</span>{" "}
              <span className="font-semibold text-red-700">not</span> have ... .
              / He
              <span className="font-semibold"> does</span>
              <span className="font-semibold text-red-700">n't</span> have ... .
            </p>
            <p>
              She <span className="font-semibold">does</span>{" "}
              <span className="font-semibold text-red-700">not</span> have ... .
              / She
              <span className="font-semibold"> does</span>
              <span className="font-semibold text-red-700">n't</span> have ... .
            </p>
            <p>
              It <span className="font-semibold">does</span>{" "}
              <span className="font-semibold text-red-700">not</span> have ... .
              / It
              <span className="font-semibold"> does</span>
              <span className="font-semibold text-red-700">n't</span> have ... .
            </p>
            <p>
              We <span className="font-semibold">do</span>{" "}
              <span className="font-semibold text-red-700">not</span> have ... .
              / We
              <span className="font-semibold"> do</span>
              <span className="font-semibold text-red-700">n't</span> have ... .
            </p>
            <p>
              You <span className="font-semibold">do</span>{" "}
              <span className="font-semibold text-red-700">not</span> have ... .
              / You
              <span className="font-semibold"> do</span>
              <span className="font-semibold text-red-700">n't</span> have ... .
            </p>
            <p>
              They <span className="font-semibold">do</span>{" "}
              <span className="font-semibold text-red-700">not</span> have ... .
              / They
              <span className="font-semibold"> do</span>
              <span className="font-semibold text-red-700">n't</span> have ... .
            </p>
          </div>

          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase">
              Positive Questions{" "}
              <span lang="fa" dir="rtl" className="ml-5">
                سوالی مثبت
              </span>
            </h3>
            <p>
              <span className="font-semibold">Do</span> I have ... ?
            </p>
            <p>
              <span className="font-semibold">Do</span> you have ... ?
            </p>
            <p>
              <span className="font-semibold">Does</span> he have ... ?
            </p>
            <p>
              <span className="font-semibold">Does</span> she have ... ?
            </p>
            <p>
              <span className="font-semibold">Does</span> it have ... ?
            </p>
            <p>
              <span className="font-semibold">Do</span> we have ... ?
            </p>
            <p>
              <span className="font-semibold">Do</span> you have... ?
            </p>
            <p>
              <span className="font-semibold">Do</span> they have ... ?
            </p>
          </div>

          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase">
              Negative Questions{" "}
              <span lang="fa" dir="rtl" className="ml-5">
                سوالی منفی
              </span>
            </h3>
            <p>
              <span className="font-semibold">Do</span> I{" "}
              <span className="font-semibold text-red-700">not</span> have ...?
              / <span className="font-semibold">Do</span>
              <span className="font-semibold text-red-700">n't</span> I have ...
              ?
            </p>
            <p>
              <span className="font-semibold">Do</span> you{" "}
              <span className="font-semibold text-red-700">not</span> have ... ?
              / <span className="font-semibold">Do</span>
              <span className="font-semibold text-red-700">n't</span> you have
              ... ?
            </p>
            <p>
              <span className="font-semibold">Does</span> he{" "}
              <span className="font-semibold text-red-700">not</span> have ... ?
              / <span className="font-semibold">Does</span>
              <span className="font-semibold text-red-700">n't</span> he have
              ... ?
            </p>
            <p>
              <span className="font-semibold">Does</span> she{" "}
              <span className="font-semibold text-red-700">not</span> have ... ?
              / <span className="font-semibold">Does</span>
              <span className="font-semibold text-red-700">n't</span> she have
              ... ?
            </p>
            <p>
              <span className="font-semibold">Does</span> it{" "}
              <span className="font-semibold text-red-700">not</span> have ... ?
              / <span className="font-semibold">Does</span>
              <span className="font-semibold text-red-700">n't</span> it have
              ... ?
            </p>
            <p>
              <span className="font-semibold">Do</span> we{" "}
              <span className="font-semibold text-red-700">not</span> have ... ?
              / <span className="font-semibold">Do</span>
              <span className="font-semibold text-red-700">n't</span> we have
              ... ?
            </p>
            <p>
              <span className="font-semibold">Do</span> you{" "}
              <span className="font-semibold text-red-700">not</span> have ... ?
              / <span className="font-semibold">Do</span>
              <span className="font-semibold text-red-700">n't</span> you have
              ... ?
            </p>
            <p>
              <span className="font-semibold">Do</span> they{" "}
              <span className="font-semibold text-red-700">not</span> have ... ?
              / <span className="font-semibold">Do</span>
              <span className="font-semibold text-red-700">n't</span> they have
              ... ?
            </p>
          </div>

          <div className="pb-9">
            <h3 className="font-bold text-pink-700 uppercase">
              Short Answers{" "}
              <span lang="fa" dir="rtl" className="ml-5">
                پاسخ کوتاه
              </span>
            </h3>
            <p>
              Yes, I <span className="font-semibold">do</span>. / No, I
              <span className="font-semibold"> do</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, you <span className="font-semibold">do</span>. / No, you{" "}
              <span className="font-semibold">do</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, he <span className="font-semibold">does</span>. / No, he{" "}
              <span className="font-semibold">does</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, she <span className="font-semibold">does</span>. / No, she{" "}
              <span className="font-semibold">does</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, it <span className="font-semibold">does</span>. / No, it{" "}
              <span className="font-semibold">does</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, we <span className="font-semibold">do</span>. / No, we{" "}
              <span className="font-semibold">do</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, you <span className="font-semibold">do</span>. / No, you{" "}
              <span className="font-semibold">do</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, they <span className="font-semibold">do</span>. / No, they{" "}
              <span className="font-semibold">do</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
          </div>
          <p lang="fa" dir="rtl" className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pr-3">
              همانطور که در ساختار های بالا می بینیم، وقتی have فعل اصلی جمله
              مان است، در زمان Simple Present برای منفی و سوالی کردن جمله از فعل
              کمکی do یا does استفاده می کنیم.
            </span>
          </p>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-6">
              مثال:
            </p>{" "}
            <p className="flex">
              <span className="w-10 font-bold">+</span>
              <span className="italic">She has a big house.</span>
            </p>
            <p lang="fa" dir="rtl" className="pb-1">
              او خانه بزرگی دارد.
            </p>
            <p className="flex">
              <span className="w-10 font-bold">-</span>
              <span className="italic">She doesn't have a big house.</span>
            </p>
            <p lang="fa" dir="rtl" className="pb-1">
              او خانه بزرگی ندارد.
            </p>
            <p className="flex">
              <span className="w-10 font-bold">+?</span>
              <span className="italic">Does she have a big house?</span>
            </p>
            <p lang="fa" dir="rtl" className="pb-1">
              آیا او خانه بزرگی دارد؟
            </p>
            <p className="flex">
              <span className="w-10 font-bold">-?</span>
              <span className="italic w-fit">
                Doesn't she have a big house? / Does she not have a big house?
              </span>
            </p>
            <p lang="fa" dir="rtl" className="pb-1">
              آیا او خانه بزرگی ندارد؟
            </p>
            <p className="flex">
              <span className="w-10 font-bold"></span>
              <span className="italic">Yes, she does. / No, she doesn't.</span>
            </p>
            <p lang="fa" dir="rtl" className="pb-16">
              بله، او خانه بزرگی دارد. / نه، او خانه بزرگی ندارد.
            </p>
            <p className="flex">
              <span className="w-10 font-bold">+</span>
              <span className="italic">They have a dog.</span>
            </p>
            <p lang="fa" dir="rtl" className="pb-1">
              آنها یک سگ دارند.
            </p>
            <p className="flex">
              <span className="w-10 font-bold">-</span>
              <span className="italic">They don't have a dog.</span>
            </p>
            <p lang="fa" dir="rtl" className="pb-1">
              آنها سگ ندارند.
            </p>
            <p className="flex">
              <span className="w-10 font-bold">+?</span>
              <span className="italic">Do they have a dog?</span>
            </p>
            <p lang="fa" dir="rtl" className="pb-1">
              آیا آنها یک سگ دارند؟
            </p>
            <p className="flex">
              <span className="w-10 font-bold">-?</span>
              <span className="italic w-fit">
                Don't they have a dog? / Do they not have a dog?
              </span>
            </p>
            <p lang="fa" dir="rtl" className="pb-1">
              آیا آنها سگ ندارند؟
            </p>
            <p className="flex">
              <span className="w-10 font-bold"></span>
              <span className="italic">Yes, they do. / No, they don't.</span>
            </p>
            <p lang="fa" dir="rtl" className="pb-1">
              بله، آنها سگ دارند. / نه، آنها سگ ندارند.
            </p>
          </div>
        </div>
      </div>
      <ExerciseCard questions={questions} instruction={instruction} />
    </>
  );
};
