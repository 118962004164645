import { useState } from "react";
import { Link } from "react-router-dom";
import { LanguageSwitch, ExerciseCard, useTitle } from "../../../components";

export const ThisTheseThatThoseElementary = ({ title }) => {
  const [farsi, setFarsi] = useState(false);
  const instruction = (
    <span>
      Complete the sentences with '<span className="font-bold">this</span>', '
      <span className="font-bold">these</span>', '
      <span className="font-bold">that</span>' or '
      <span className="font-bold">those</span>'.
    </span>
  );
  const questions = [
    {
      id: 1,
      question: (
        <span>
          <span className="mr-8">"Who's ____ boy over there?"</span>
          <span>"He's my brother."</span>.
        </span>
      ),
      answer: (
        <span>
          <span className="mr-8">
            "Who's <span className="font-bold">that</span> boy over there?"
          </span>
          <span>"He's my brother."</span>.
        </span>
      ),
    },
    {
      id: 2,
      question: (
        <span>Can you please bring me ____ box from across the room?</span>
      ),
      answer: (
        <span>
          Can you please bring me <span className="font-bold">that</span> box
          from across the room?
        </span>
      ),
    },
    {
      id: 3,
      question: <span>Please, come in. ____ is my office.</span>,
      answer: (
        <span>
          Please, come in. <span className="font-bold">This</span> is my office.
        </span>
      ),
    },
    {
      id: 4,
      question: <span>You are in room 5 Mr. Jackson. ____ are your keys.</span>,
      answer: (
        <span>
          You are in room 5 Mr. Jackson.{" "}
          <span className="font-bold">These</span> are your keys.
        </span>
      ),
    },
    {
      id: 5,
      question: (
        <span>____ mountains in the distance are covered with snow.</span>
      ),
      answer: (
        <span>
          <span className="font-bold">Those</span> mountains in the distance are
          covered with snow.
        </span>
      ),
    },
  ];

  useTitle(title);

  return (
    <>
      {/* -------------------- Breadcrumb Menu -------------------- */}
      <div data-aos="fade" data-aos-duration="1500">
        <nav className="flex pl-3 pt-2 text-gray-400 text-sm font-semibold">
          <Link to="/" className="flex duration-300 hover:text-indigo-950">
            <span className="pt-0.5 md:pr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
            </span>
            <span className="max-md:hidden">Home</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link to="/grammar" className="duration-300 hover:text-indigo-950">
            <span>Grammar</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link
            to="/grammar/elementary"
            className="duration-300 hover:text-indigo-950"
          >
            <span>A1 - A2</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <span className="text-indigo-950">This / These / That / Those</span>
        </nav>
      </div>

      <div data-aos="zoom-in" data-aos-duration="800">
        {/* -------------------- Language Switch -------------------- */}
        <LanguageSwitch farsi={farsi} setFarsi={setFarsi} />

        {/* -------------------- Body in English -------------------- */}
        <div
          className={`duration-300 ${
            farsi ? "hidden opacity-0" : ""
          } my-7 mx-5 text-indigo-950 text-xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            This / These / That / Those
          </h1>
          <p className="mb-9">
            We use <span className="font-bold">this</span> and{" "}
            <span className="font-bold">these</span> to refer to things or
            people that are <span className="underline">near</span> us.
          </p>
          <p className="mb-2 pl-16 font-bold">this + a singular noun</p>
          <p className="mb-9 pl-16 font-bold">these + a plural noun</p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-3">Examples:</p>
            <p>this book</p>
            <p>
              these book<span className="underline">s</span>
            </p>
            <p>this girl</p>
            <p>
              these girl<span className="underline">s</span>
            </p>
          </div>

          <p className="mb-9">
            We use <span className="font-bold">that</span> and{" "}
            <span className="font-bold">those</span> to refer to things or
            people that are <span className="underline">not near</span> us.
          </p>
          <p className="mb-2 pl-16 font-bold">that + a singular noun</p>
          <p className="mb-9 pl-16 font-bold">those + a plural noun</p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-3">Examples:</p>
            <p>that bird</p>
            <p>
              those bird<span className="underline">s</span>
            </p>
            <p>that mountain</p>
            <p>
              those mountain<span className="underline">s</span>
            </p>
          </div>
        </div>

        {/* ---------- Body in Farsi ---------- */}
        <div
          className={`duration-300 ${
            farsi ? "" : "hidden opacity-0"
          } my-7 mx-5 text-indigo-950 text-xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            This / These / That / Those
          </h1>
          <p lang="fa" dir="rtl" className="mb-9">
            در زبان انگلیسی کلمات <span className="font-bold">this</span> و{" "}
            <span className="font-bold">these</span> را استفاده می کنیم تا به
            چیز ها یا افرادی که <span className="underline">نزدیک</span> ما
            هستند اشاره کنیم. بعد از this باید یک اسم مفرد بگذاریم و بعد از
            these باید یک اسم جمع بگذاریم.
          </p>
          <p className="mb-2 pl-16 font-bold">this + a singular noun</p>
          <p className="mb-9 pl-16 font-bold">these + a plural noun</p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-3 not-italic">
              مثال:
            </p>
            <p>this book</p>
            <p lang="fa" dir="rtl" className="not-italic mb-2">
              این کتاب
            </p>
            <p>
              these book<span className="underline">s</span>
            </p>
            <p lang="fa" dir="rtl" className="not-italic mb-2">
              این کتاب ها
            </p>
            <p>this girl</p>
            <p lang="fa" dir="rtl" className="not-italic mb-2">
              این دختر{" "}
            </p>
            <p>
              these girl<span className="underline">s</span>
            </p>
            <p lang="fa" dir="rtl" className="not-italic mb-2">
              این دختر ها
            </p>
          </div>

          <p lang="fa" dir="rtl" className="mb-9">
            کلمات <span className="font-bold">that</span> و{" "}
            <span className="font-bold">those</span> را استفاده می کنیم تا به
            چیز ها یا افرادی که از ما <span className="underline">دور</span>{" "}
            هستند اشاره کنیم. بعد از that باید یک اسم مفرد و بعد از those باید
            یک اسم جمع بگذاریم.
          </p>
          <p className="mb-2 pl-16 font-bold">that + a singular noun</p>
          <p className="mb-9 pl-16 font-bold">those + a plural noun</p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-3 not-italic">
              مثال:
            </p>
            <p>that bird</p>
            <p lang="fa" dir="rtl" className="not-italic mb-2">
              آن پرنده
            </p>
            <p>
              those bird<span className="underline">s</span>
            </p>
            <p lang="fa" dir="rtl" className="not-italic mb-2">
              آن پرنده ها
            </p>
            <p>that mountain</p>
            <p lang="fa" dir="rtl" className="not-italic mb-2">
              آن کوه
            </p>
            <p>
              those mountain<span className="underline">s</span>
            </p>
            <p lang="fa" dir="rtl" className="not-italic mb-2">
              آن کوه ها
            </p>
          </div>
        </div>
      </div>
      <ExerciseCard questions={questions} instruction={instruction} />
    </>
  );
};
