import { useState } from "react";
import { Link } from "react-router-dom";
import { LanguageSwitch, ExerciseCard, useTitle } from "../../../components";
import Bulb from "../../../assets/images/bulb.png";

export const LetsElementary = ({ title }) => {
  const [farsi, setFarsi] = useState(false);
  const instruction = (
    <div>
      <p className="mb-2">
        Complete the sentences with "let's" and a verb from the list below.
      </p>{" "}
      <p className="border-2 px-2 max-w-fit">solve | play | invite | stay | get | go</p>
    </div>
  );
  const questions = [
    {
      id: 1,
      question: <span>I'm so hungry. ____ a sandwich.</span>,
      answer: (
        <span>
          I'm so hungry. <span className="font-bold">Let's get</span> a
          sandwich.
        </span>
      ),
    },
    {
      id: 2,
      question: <span>It's such a lovely day. ____ to the beach.</span>,
      answer: (
        <span>
          It's such a lovely day. <span className="font-bold">Let's go</span> to
          the beach.
        </span>
      ),
    },
    {
      id: 3,
      question: <span>This problems seems difficult. ____ it together.</span>,
      answer: (
        <span>
          This problem seems difficult.{" "}
          <span className="font-bold">Let's solve</span> it together.
        </span>
      ),
    },
    {
      id: 4,
      question: (
        <span>
          It's very cold outside. ____ at home and watch a movie together.
        </span>
      ),
      answer: (
        <span>
          It's very cold outside.
          <span className="font-bold"> Let's stay</span> at home and watch a
          movie together.
        </span>
      ),
    },
    {
      id: 5,
      question: <span>I'm so bored. ____ a game.</span>,
      answer: (
        <span>
          I'm so bored.
          <span className="font-bold"> Let's play</span> a game.
        </span>
      ),
    },
    {
      id: 6,
      question: (
        <span>
          We haven't seen Jim and Sue for months. ____ them for lunch on Sunday.
        </span>
      ),
      answer: (
        <span>
          We haven't seen Jim and Sue for months.
          <span className="font-bold"> Let's invite</span> them for lunch on
          Sunday.
        </span>
      ),
    },
  ];

  useTitle(title);

  return (
    <>
      {/* -------------------- Breadcrumb Menu -------------------- */}
      <div data-aos="fade" data-aos-duration="1500">
        <nav className="flex pl-3 pt-2 text-gray-400 text-sm font-semibold">
          <Link to="/" className="flex duration-300 hover:text-indigo-950">
            <span className="pt-0.5 md:pr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
            </span>
            <span className="max-md:hidden">Home</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link to="/grammar" className="duration-300 hover:text-indigo-950">
            <span>Grammar</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link
            to="/grammar/elementary"
            className="duration-300 hover:text-indigo-950"
          >
            <span>A1 - A2</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <span className="text-indigo-950">Let's</span>
        </nav>
      </div>

      <div data-aos="zoom-in" data-aos-duration="800">
        {/* -------------------- Language Switch -------------------- */}
        <LanguageSwitch farsi={farsi} setFarsi={setFarsi} />

        {/* -------------------- Body in English -------------------- */}
        <div
          className={`duration-300 ${
            farsi ? "hidden opacity-0" : ""
          } my-7 mx-5 text-indigo-950 text-xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            Let's
          </h1>
          <p className="mb-9">We use the following structure:</p>
          <p className="mb-9 pl-16 font-bold">Let's + base form of a verb</p>
          <p className="mb-9">
            to make a friendly suggestion or offer an invitation about something
            that the listener and we can do together.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-12">
            <p className="font-semibold pb-3">Examples:</p>{" "}
            <p className="pb-2">Let's go for a walk.</p>
            <p className="pb-2">
              Let's go see that new animated movie on Saturday night.
            </p>
          </div>

          <p className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              To make this structure negative, simply add the word "not"
              immediately after "let's".
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-3">Examples:</p>{" "}
            <p className="pb-2">Let's not talk about it right now.</p>
            <p className="pb-2">Let's not waste any more time.</p>
          </div>
        </div>

        {/* ---------- Body in Farsi ---------- */}
        <div
          className={`duration-300 ${
            farsi ? "" : "hidden opacity-0"
          } my-7 mx-5 text-indigo-950 text-xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            Let's
          </h1>
          <p lang="fa" dir="rtl" className="mb-9">
            در زبان انگلیسی از ساختار زیر استفاده می کنیم تا به طرف مقابلمان
            دوستانه پیشنهاد دهیم یا دعوتش کنیم که با همدیگر کاری را انجام دهیم.
          </p>
          <p className="mb-9 text-center font-bold">
            Let's + base form of a verb
          </p>
          <p lang="fa" dir="rtl" className="mb-9">
            همانطور که می بینید، بعد از let's از شکل ساده فعل استفاده می کنیم.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-12">
            <p lang="fa" dir="rtl" className="font-semibold pb-3 not-italic">
              مثال:
            </p>{" "}
            <p className="pb-1">Let's go for a walk.</p>
            <p lang="fa" dir="rtl" className="pb-2 not-italic">
              بیا برویم قدم بزنیم.
            </p>
            <p className="pb-2">
              Let's go see that new animated movie on Saturday night.
            </p>
            <p lang="fa" dir="rtl" className="pb-2 not-italic">
              شنبه شب بیا برویم آن انیمیشن جدید را با هم ببینیم.
            </p>
          </div>

          <p lang="fa" dir="rtl" className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              برای منفی کردن این ساختار، کافی است کلمه not را بلافاصله بعد از
              let's بگذاریم.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-3">Examples:</p>{" "}
            <p className="pb-2">Let's not talk about it right now.</p>
            <p lang="fa" dir="rtl" className="pb-2 not-italic">
              بیا الان راجع بهش صحبت نکنیم.
            </p>
            <p className="pb-2">Let's not waste any more time.</p>
            <p lang="fa" dir="rtl" className="pb-2 not-italic">
              بیا دیگر بیشتر وقت تلف نکنیم.
            </p>
          </div>
        </div>
      </div>
      <ExerciseCard questions={questions} instruction={instruction} />
    </>
  );
};
