import { useState } from "react";
import { Link } from "react-router-dom";
import { LanguageSwitch, ExerciseCard, useTitle } from "../../../components";
import LinkImg from "../../../assets/images/link.svg";

export const ArticlesElementary = ({ title }) => {
  const [farsi, setFarsi] = useState(false);
  const instruction = (
    <span>Complete the sentences with "the", "a", "an" or "-".</span>
  );
  const questions = [
    {
      id: 1,
      question: (
        <span>
          We stayed in ____ hotel in Prague last summer. ____ hotel was very
          nice.
        </span>
      ),
      answer: (
        <span>
          We stayed in <span className="font-bold">a</span> hotel in Prague last
          summer. <span className="font-bold">The</span> hotel was very nice.
        </span>
      ),
    },
    {
      id: 2,
      question: <span>She’s studying to be ____ doctor.</span>,
      answer: (
        <span>
          She’s studying to be <span className="font-bold">a</span> doctor.
        </span>
      ),
    },
    {
      id: 3,
      question: <span>I take my dog for a walk twice ____ day.</span>,
      answer: (
        <span>
          I take my dog for a walk twice <span className="font-bold">a</span>{" "}
          day.
        </span>
      ),
    },
    {
      id: 4,
      question: <span>We had ____ lunch at a nice café yesterday.</span>,
      answer: <span>We had lunch at a nice café yesterday.</span>,
    },
    {
      id: 5,
      question: <span>My father works as ____ engineer.</span>,
      answer: (
        <span>
          My father works as <span className="font-bold">an</span> engineer.
        </span>
      ),
    },
    {
      id: 6,
      question: (
        <span>
          Can you call ____ children? They are playing in ____ garden.
        </span>
      ),
      answer: (
        <span>
          Can you call <span className="font-bold">the</span> children? They are
          playing in <span className="font-bold">the</span> garden.
        </span>
      ),
    },
    {
      id: 7,
      question: <span>____ Sugar is not good for your teeth.</span>,
      answer: <span>Sugar is not good for your teeth.</span>,
    },
    {
      id: 8,
      question: <span>____ Computers have changed the way we work.</span>,
      answer: <span>Computers have changed the way we work.</span>,
    },
  ];

  useTitle(title);

  return (
    <>
      {/* -------------------- Breadcrumb Menu -------------------- */}
      <div data-aos="fade" data-aos-duration="1500">
        <nav className="flex pl-3 pt-2 text-gray-400 text-sm font-semibold">
          <Link to="/" className="flex duration-300 hover:text-indigo-950">
            <span className="pt-0.5 md:pr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
            </span>
            <span className="max-md:hidden">Home</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link to="/grammar" className="duration-300 hover:text-indigo-950">
            <span>Grammar</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link
            to="/grammar/elementary"
            className="duration-300 hover:text-indigo-950"
          >
            <span>A1 - A2</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <span className="text-indigo-950">Articles</span>
        </nav>
      </div>

      <div data-aos="zoom-in" data-aos-duration="800">
        {/* -------------------- Language Switch -------------------- */}
        <LanguageSwitch farsi={farsi} setFarsi={setFarsi} />

        {/* -------------------- Body in English -------------------- */}
        <div
          className={`duration-300 ${
            farsi ? "hidden opacity-0" : ""
          } my-7 mx-5 text-indigo-950 text-xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            Articles
          </h1>
          <p className="mb-4">
            An article is a small word like "a", "an" or "the" that comes before
            a noun to show if it is specific or general.
          </p>
          <p className="mb-4">There are three types of articles:</p>
          <h2 className="font-bold mb-4 text-2xl">
            1. Indefinite Articles (a / an)
          </h2>
          <p className="mb-7">
            We use indefinite articles when mentioning something or someone for
            the first time or when referring to a singular, non-specific noun.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-7">
            <p className="font-semibold pb-4">Examples:</p>{" "}
            <p className="pb-2">
              We watched <span className="font-bold">an interesting film</span>{" "}
              last night.
            </p>
            <p className="pb-1">
              I need to buy <span className="font-bold">a new jacket</span>.
            </p>
          </div>

          <p className="mb-7">
            We also use "a" or "an" to say what something is or to describe
            someone's job.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-7">
            <p className="font-semibold pb-4">Examples:</p>{" "}
            <p className="pb-2">
              This is <span className="font-bold">an umbrella</span>.
            </p>
            <p className="pb-1">
              She's <span className="font-bold">a nurse</span>.
            </p>
          </div>

          <p className="mb-7">
            Additionally, indefinite articles appear in expressions of
            frequency.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-7">
            <p className="font-semibold pb-4">Examples:</p>{" "}
            <p className="pb-2">
              once <span className="font-bold">a day</span>
            </p>
            <p className="pb-1">
              three times <span className="font-bold">a month</span>
            </p>
          </div>

          <h2 className="font-bold mb-4 text-2xl">2. Definite Article (the)</h2>

          <p className="mb-7">
            We use "the" when the reader or listener already knows what we are
            talking about or when referring to something or someone specific.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-7">
            <p className="font-semibold pb-4">Examples:</p>{" "}
            <p className="pb-2">
              We watched an interesting film last night.{" "}
              <span className="font-bold">The film</span> was about the real
              life of a musician.
            </p>
            <p className="pb-1">
              Can you open <span className="font-bold">the window</span>,
              please?
            </p>
          </div>

          <p className="mb-7">
            We also use "the" when there is only one of something.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-7">
            <p className="font-semibold pb-4">Examples:</p>{" "}
            <p className="pb-2">
              <span className="font-bold">The Sun</span> rises in the east.
            </p>
            <p className="pb-1">
              I checked it on <span className="font-bold">the Internet</span>.
            </p>
          </div>

          <p className="mb-7">
            Additionally, "the" is used before superlative adjectives.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-7">
            <p className="font-semibold pb-4">Example:</p>{" "}
            <p className="pb-2">
              That is <span className="font-bold">the best</span> restaurant in
              town.
            </p>
          </div>

          <h2 className="font-bold mb-4 text-2xl">3. Zero Article (-)</h2>

          <p className="mb-4">There are times when we do not use an article:</p>

          <div className="flex mb-4">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="pt-1">
              Before plural or uncountable nouns in a general sense:
            </span>
          </div>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-7">
            <p className="font-semibold pb-4">Examples:</p>{" "}
            <p className="pb-2">
              <span className="font-bold">Women</span> are more interested in
              fashion than <span className="font-bold">men</span>.
            </p>
            <p className="pb-1">
              <span className="font-bold">Rice</span> is the main food in Iran.
            </p>
          </div>

          <div className="flex mb-4">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="pt-1">
              Before possessive adjectives (my, your, his...) and nouns that end
              in a possessive "s":
            </span>
          </div>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-7">
            <p className="font-semibold pb-4">Example:</p>{" "}
            <p className="pb-2">
              She is <span className="font-bold">my sister's friend</span>.
            </p>
          </div>

          <div className="flex mb-4">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="pt-1">Before meals:</span>
          </div>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-7">
            <p className="font-semibold pb-4">Example:</p>{" "}
            <p className="pb-2">
              We usually have <span className="font-bold">dinner</span> at 8
              p.m.
            </p>
          </div>

          <div className="flex mb-4">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="pt-1">
              In certain fixed expressions that should be memorized:
            </span>
          </div>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-7">
            <p className="font-semibold pb-4">Examples:</p>{" "}
            <p className="pb-2">
              go to <span className="font-bold">bed</span>
            </p>
            <p className="pb-2">
              go to <span className="font-bold">work</span>
            </p>
            <p className="pb-2">
              go <span className="font-bold">home</span>
            </p>
            <p className="pb-1">
              travel by <span className="font-bold">bus</span>
            </p>
          </div>

          <div className="mt-14 mb-8">
            <p className="font-bold mb-1">RELATED TOPIC</p>
            <p className="text-fuchsia-900">
              <img className="w-5 h-5 inline mr-2" src={LinkImg} alt="link" />
              <Link to="/grammar/elementary/aAnAndPlurals">
                A / An, Plurals <span className="font-light">(Elementary)</span>
              </Link>
            </p>
          </div>
        </div>

        {/* ---------- Body in Farsi ---------- */}
        <div
          className={`duration-300 ${
            farsi ? "" : "hidden opacity-0"
          } my-7 mx-5 text-indigo-950 text-xl md:text-xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            Articles
          </h1>
          <p lang="fa" dir="rtl" className="mb-4">
            در زبان انگلیسی به کلمات an ،a و the که قبل از اسم ها می آیند
            article گفته می شود. از آنها استفاده می کنیم تا نشان دهیم داریم به
            یک شخص یا چیز خاص اشاره می کنیم، یا داریم راجع به کسی یا چیزی به طور
            کلی صحبت می کنیم.
          </p>
          <p lang="fa" dir="rtl" className="mb-4">
            در زبان انگلیسی سه نوع article داریم:
          </p>
          <h2 className="font-bold mb-4 text-2xl">
            1. Indefinite Articles (a / an)
          </h2>
          <p lang="fa" dir="rtl" className="mb-7">
            وقتی اولین بار است به کسی یا چیزی اشاره می کنیم، یا داریم راجع به یک
            شخص یا یک چیز به طور کلی صحبت می کنیم از a یا an استفاده می کنیم.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-7">
            <p lang="fa" dir="rtl" className="font-semibold pb-4 not-italic">
              مثال:
            </p>{" "}
            <p className="pb-1">
              We watched <span className="font-bold">an interesting film</span>{" "}
              last night.
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              دیشب یک فیلم جالب تماشا کردیم.
            </p>
            <p className="pb-1">
              I need to buy <span className="font-bold">a new jacket</span>.
            </p>
            <p lang="fa" dir="rtl" className="mb-1 not-italic">
              باید یک کُت جدید بخرم.
            </p>
          </div>

          <p lang="fa" dir="rtl" className="mb-7">
            همچنین برای بیان اینکه یک چیز یا شغل یک شخص چیست از a یا an استفاده
            می کنیم.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-7">
            <p lang="fa" dir="rtl" className="font-semibold pb-4 not-italic">
              مثال:
            </p>{" "}
            <p className="pb-1">
              This is <span className="font-bold">an umbrella</span>.
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              این یک چتر است.
            </p>
            <p className="pb-1">
              She's <span className="font-bold">a nurse</span>.
            </p>
            <p lang="fa" dir="rtl" className="mb-1 not-italic">
              او یک پرستار است.
            </p>
          </div>

          <p lang="fa" dir="rtl" className="mb-7">
            همچنین a و an را در عبارت های قیدی که به تکرار منظم فعل دلالت می
            کنند نیز به کار می بریم.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-7">
            <p lang="fa" dir="rtl" className="font-semibold pb-4 not-italic">
              مثال:
            </p>{" "}
            <p className="pb-1">
              once <span className="font-bold">a day</span>
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              یک بار در روز
            </p>
            <p className="pb-1">
              three times <span className="font-bold">a month</span>
            </p>
            <p lang="fa" dir="rtl" className="mb-1 not-italic">
              ماهی سه بار
            </p>
          </div>

          <h2 className="font-bold mb-4 text-2xl">2. Definite Article (the)</h2>

          <p lang="fa" dir="rtl" className="mb-7">
            وقتی شنونده یا خواننده مان می داند داریم راجع به چه کسی یا چه چیزی
            صحبت می کنیم، یا به عبارت دیگر داریم درباره شخص یا چیز خاصی صحبت می
            کنیم از کلمه the استفاده می کنیم.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-7">
            <p lang="fa" dir="rtl" className="font-semibold pb-4 not-italic">
              مثال:
            </p>{" "}
            <p className="pb-1">
              We watched an interesting film last night.{" "}
              <span className="font-bold">The film</span> was about the real
              life of a musician.
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              دیشب یک فیلم جالب تماشا کردیم. این فیلم راجع به زندگی واقعی یک
              موسیقی دان بود.
            </p>
            <p className="pb-1">
              Can you open <span className="font-bold">the window</span>,
              please?
            </p>
            <p lang="fa" dir="rtl" className="mb-1 not-italic">
              ممکنه لطفا پنجره را باز کنی؟
            </p>
          </div>

          <p lang="fa" dir="rtl" className="mb-7">
            همچنین وقتی داریم راجع به کسی یا چیزی صحبت می کنیم که فقط یکی از آن
            وجود دارد، از کلمه the استفاده می کنیم.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-7">
            <p lang="fa" dir="rtl" className="font-semibold pb-4 not-italic">
              مثال:
            </p>{" "}
            <p className="pb-1">
              <span className="font-bold">The Sun</span> rises in the east.
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              خورشید از شرق طلوع می کند.
            </p>
            <p className="pb-1">
              I checked it on <span className="font-bold">the Internet</span>.
            </p>
            <p lang="fa" dir="rtl" className="mb-1 not-italic">
              در اینترنت چک اش کردم.
            </p>
          </div>

          <p lang="fa" dir="rtl" className="mb-7">
            نکته دیگر اینکه معمولا قبل از صفات عالی یا برترین از کلمه the
            استفاده می کنیم.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-7">
            <p lang="fa" dir="rtl" className="font-semibold pb-4 not-italic">
              مثال:
            </p>{" "}
            <p className="pb-1">
              That is <span className="font-bold">the best restaurant</span> in
              town.
            </p>
            <p lang="fa" dir="rtl" className="mb-1 not-italic">
              آن بهترین رستوران شهر است.
            </p>
          </div>

          <h2 className="font-bold mb-4 text-2xl">3. Zero Article (-)</h2>

          <p lang="fa" dir="rtl" className="mb-4">
            در شرایط زیر از an ،a یا the استفاده نمی کنیم:
          </p>

          <div dir="rtl" className="flex mb-4">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span lang="fa" className="pt-1">
              وقتی داریم راجع به یک اسم جمع یا غیر قابل شمارش در معنای کلی اش
              صحبت می کنیم:
            </span>
          </div>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-7">
            <p lang="fa" dir="rtl" className="font-semibold pb-4 not-italic">
              مثال:
            </p>{" "}
            <p className="pb-1">
              <span className="font-bold">Women</span> are more interested in
              fashion than <span className="font-bold">men</span>.
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              زنان بیشتر از مردان به مد علاقه دارند.
            </p>
            <p className="pb-1">
              <span className="font-bold">Rice</span> is the main food in Iran.
            </p>
            <p lang="fa" dir="rtl" className="mb-1 not-italic">
              برنج غذای اصلی مردم در ایران است.
            </p>
          </div>

          <div dir="rtl" className="flex mb-4">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span lang="fa" className="pt-1">
              قبل از صفات ملکی (his ،your ،my ...) و همینطور قبل از s مالکیت:
            </span>
          </div>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-7">
            <p lang="fa" dir="rtl" className="font-semibold pb-4 not-italic">
              مثال:
            </p>{" "}
            <p className="pb-1">
              She is <span className="font-bold">my sister's friend</span>.
            </p>
            <p lang="fa" dir="rtl" className="mb-1 not-italic">
              او دوست خواهرم است.
            </p>
          </div>

          <div dir="rtl" className="flex mb-4">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span lang="fa" className="pt-1">
              قبل از وعده های غذایی:
            </span>
          </div>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-7">
            <p lang="fa" dir="rtl" className="font-semibold pb-4 not-italic">
              مثال:
            </p>{" "}
            <p className="pb-1">
              We usually have <span className="font-bold">dinner</span> at 8
              p.m.
            </p>
            <p lang="fa" dir="rtl" className="mb-1 not-italic">
              ما معمولا ساعت 8 شب شام می خوریم.
            </p>
          </div>

          <div dir="rtl" className="flex mb-4">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span lang="fa" className="pt-1">
              در برخی عبارت های ثابت که باید حفظ شوند:
            </span>
          </div>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-7">
            <p lang="fa" dir="rtl" className="font-semibold pb-4 not-italic">
              مثال:
            </p>{" "}
            <p className="pb-1">
              go to <span className="font-bold">bed</span>
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              به رختخواب رفتن
            </p>
            <p className="pb-1">
              go to <span className="font-bold">work</span>
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              سر کار رفتن
            </p>
            <p className="pb-1">
              go <span className="font-bold">home</span>
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              خانه رفتن
            </p>
            <p className="pb-1">
              travel by <span className="font-bold">bus</span>
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              با اتوبوس سفر کردن
            </p>
          </div>

          <div className="mt-14 mb-8">
            <p className="font-bold mb-1">RELATED TOPIC</p>
            <p className="text-fuchsia-900">
              <img className="w-5 h-5 inline mr-2" src={LinkImg} alt="link" />
              <Link to="/grammar/elementary/aAnAndPlurals">
                A / An, Plurals <span className="font-light">(Elementary)</span>
              </Link>
            </p>
          </div>
        </div>
      </div>
      <ExerciseCard questions={questions} instruction={instruction} />
    </>
  );
};
