import { useState } from "react";
import { Link } from "react-router-dom";
import { LanguageSwitch, ExerciseCard, useTitle } from "../../../components";
import Bulb from "../../../assets/images/bulb.png";

export const ToBePresent = ({ title }) => {
  const [farsi, setFarsi] = useState(false);
  const instruction = (
    <span>
      Complete the mini-dialogues below with the correct forms of the verb{" "}
      <span className="underline">be</span>. Use contractions where possible.
    </span>
  );
  const questions = [
    {
      id: 1,
      question: (
        <span>
          <span>"____ you from Tokyo?"</span>
          <span className="ml-8">"No, I ____. I ____ Chinese."</span>
        </span>
      ),
      answer: (
        <span className="font-normal">
          <span>
            "<span className="font-bold">Are</span> you from Tokyo?"
          </span>
          <span className="ml-8">
            "No, I<span className="font-bold">'m not</span>. I
            <span className="font-bold">'m</span> Chinese."
          </span>
        </span>
      ),
    },
    {
      id: 2,
      question: (
        <span>
          <span>"Where ____ the other students? ____ they at the gym?"</span>
          <span className="pl-8">
            "No, they ____. They ____ at the swimming pool."
          </span>
        </span>
      ),
      answer: (
        <span>
          <span>
            "Where <span className="font-bold">are</span> the other students?{" "}
            <span className="font-bold">Are</span> they at the gym?"
          </span>
          <span className="pl-8">
            "No, they <span className="font-bold">aren't</span>. They{" "}
            <span className="font-bold">are</span> at the swimming pool."
          </span>
        </span>
      ),
    },
    {
      id: 3,
      question: (
        <span>
          <span>"Where ____ you from?"</span>
          <span className="ml-8">
            "I ____ from London. It ____ the capital of England."
          </span>
        </span>
      ),
      answer: (
        <span>
          <span>
            "Where <span className="font-bold">are</span> you from?"
          </span>
          <span className="ml-8">
            "I<span className="font-bold">'m</span> from London. It
            <span className="font-bold">'s</span> the capital of England."
          </span>
        </span>
      ),
    },
    {
      id: 4,
      question: (
        <span>
          <span>"____ you married?"</span>
          <span className="ml-8">
            "No, I ____, but my brother ____. He has two kids."
          </span>
        </span>
      ),
      answer: (
        <span>
          <span>
            "<span className="font-bold">Are</span> you married?"
          </span>
          <span className="ml-8">
            "No, I<span className="font-bold">'m not</span>, but my brother{" "}
            <span className="font-bold">is</span>. He has two kids."
          </span>
        </span>
      ),
    },
  ];

  useTitle(title);

  return (
    <>
      {/* -------------------- Breadcrumb Menu -------------------- */}
      <div data-aos="fade" data-aos-duration="1500">
        <nav className="flex pl-3 pt-2 text-gray-400 text-sm font-semibold">
          <Link to="/" className="flex duration-300 hover:text-indigo-950">
            <span className="pt-0.5 md:pr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
            </span>
            <span className="max-md:hidden">Home</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link to="/grammar" className="duration-300 hover:text-indigo-950">
            <span>Grammar</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link
            to="/grammar/elementary"
            className="duration-300 hover:text-indigo-950"
          >
            <span>A1 - A2</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <span className="text-indigo-950">To Be (Present)</span>
        </nav>
      </div>

      <div data-aos="zoom-in" data-aos-duration="800">
        {/* -------------------- Language Switch -------------------- */}
        <LanguageSwitch farsi={farsi} setFarsi={setFarsi} />

        {/* -------------------- Body in English -------------------- */}
        <div
          className={`duration-300 ${
            farsi ? "hidden opacity-0" : ""
          } my-7 mx-5 text-indigo-950 text-xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            To Be (Present)
          </h1>
          <p className="mb-9">
            The verb "to be" is a special verb in English that can show
            information about who, what, where, or how someone or something is.
            When we use "to be," we change its form depending on the subject,
            which is called conjugation. For example, we say "I am," "you are,"
            "she is," etc. "To be" is an important verb to learn because it
            helps us describe people, things, and situations.
          </p>

          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase">Positive</h3>
            <p>
              I <span className="font-semibold">am</span> / I
              <span className="font-semibold">'m</span> ... .
            </p>
            <p>
              You <span className="font-semibold">are</span> / You
              <span className="font-semibold">'re</span> ... .
            </p>
            <p>
              He <span className="font-semibold">is</span> / He
              <span className="font-semibold">'s</span> ... .
            </p>
            <p>
              She <span className="font-semibold">is</span> / She
              <span className="font-semibold">'s</span> ... .
            </p>
            <p>
              It <span className="font-semibold">is</span> / It
              <span className="font-semibold">'s</span> ... .
            </p>
            <p>
              We <span className="font-semibold">are</span> / We
              <span className="font-semibold">'re</span> ... .
            </p>
            <p>
              You <span className="font-semibold">are</span> / You
              <span className="font-semibold">'re</span> ... .
            </p>
            <p>
              They <span className="font-semibold">are</span> / They
              <span className="font-semibold">'re</span> ... .
            </p>
          </div>

          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase">Negative</h3>
            <p>
              I <span className="font-semibold">am</span>{" "}
              <span className="font-semibold text-red-700">not</span> ... . / I
              <span className="font-semibold">'m</span>{" "}
              <span className="font-semibold text-red-700">not</span> ... .
            </p>
            <p>
              You <span className="font-semibold">are</span>{" "}
              <span className="font-semibold text-red-700">not</span> ... . /
              You
              <span className="font-semibold"> are</span>
              <span className="font-semibold text-red-700">n't</span> ... .
            </p>
            <p>
              He <span className="font-semibold">is</span>{" "}
              <span className="font-semibold text-red-700">not</span> ... . / He
              <span className="font-semibold"> is</span>
              <span className="font-semibold text-red-700">n't</span> ... .
            </p>
            <p>
              She <span className="font-semibold">is</span>{" "}
              <span className="font-semibold text-red-700">not</span> ... . /
              She
              <span className="font-semibold"> is</span>
              <span className="font-semibold text-red-700">n't</span> ... .
            </p>
            <p>
              It <span className="font-semibold">is</span>{" "}
              <span className="font-semibold text-red-700">not</span> ... . / It
              <span className="font-semibold"> is</span>
              <span className="font-semibold text-red-700">n't</span> ... .
            </p>
            <p>
              We <span className="font-semibold">are</span>{" "}
              <span className="font-semibold text-red-700">not</span> ... . / We
              <span className="font-semibold"> are</span>
              <span className="font-semibold text-red-700">n't</span> ... .
            </p>
            <p>
              You <span className="font-semibold">are</span>{" "}
              <span className="font-semibold text-red-700">not</span> ... . /
              You
              <span className="font-semibold"> are</span>
              <span className="font-semibold text-red-700">n't</span> ... .
            </p>
            <p>
              They <span className="font-semibold">are</span>{" "}
              <span className="font-semibold text-red-700">not</span> ... . /
              They
              <span className="font-semibold"> are</span>
              <span className="font-semibold text-red-700">n't</span> ... .
            </p>
          </div>

          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase">
              Positive Questions
            </h3>
            <p>
              <span className="font-semibold">Am</span> I ... ?
            </p>
            <p>
              <span className="font-semibold">Are</span> you ... ?
            </p>
            <p>
              <span className="font-semibold">Is</span> he ... ?
            </p>
            <p>
              <span className="font-semibold">Is</span> she ... ?
            </p>
            <p>
              <span className="font-semibold">Is</span> it ... ?
            </p>
            <p>
              <span className="font-semibold">Are</span> we ... ?
            </p>
            <p>
              <span className="font-semibold">Are</span> you ... ?
            </p>
            <p>
              <span className="font-semibold">Are</span> they ... ?
            </p>
          </div>

          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase">
              Negative Questions
            </h3>
            <p>
              <span className="font-semibold">Am</span> I{" "}
              <span className="font-semibold text-red-700">not</span> ...?
            </p>
            <p>
              <span className="font-semibold">Are</span> you{" "}
              <span className="font-semibold text-red-700">not</span> ... ? /{" "}
              <span className="font-semibold">Are</span>
              <span className="font-semibold text-red-700">n't</span> you ... ?
            </p>
            <p>
              <span className="font-semibold">Is</span> he{" "}
              <span className="font-semibold text-red-700">not</span> ... ? /{" "}
              <span className="font-semibold">Is</span>
              <span className="font-semibold text-red-700">n't</span> he ... ?
            </p>
            <p>
              <span className="font-semibold">Is</span> she{" "}
              <span className="font-semibold text-red-700">not</span> ... ? /{" "}
              <span className="font-semibold">Is</span>
              <span className="font-semibold text-red-700">n't</span> she ... ?
            </p>
            <p>
              <span className="font-semibold">Is</span> it{" "}
              <span className="font-semibold text-red-700">not</span> ... ? /{" "}
              <span className="font-semibold">Is</span>
              <span className="font-semibold text-red-700">n't</span> it ... ?
            </p>
            <p>
              <span className="font-semibold">Are</span> we{" "}
              <span className="font-semibold text-red-700">not</span> ... ? /{" "}
              <span className="font-semibold">Are</span>
              <span className="font-semibold text-red-700">n't</span> we ... ?
            </p>
            <p>
              <span className="font-semibold">Are</span> you{" "}
              <span className="font-semibold text-red-700">not</span> ... ? /{" "}
              <span className="font-semibold">Are</span>
              <span className="font-semibold text-red-700">n't</span> you ... ?
            </p>
            <p>
              <span className="font-semibold">Are</span> they{" "}
              <span className="font-semibold text-red-700">not</span> ... ? /{" "}
              <span className="font-semibold">Are</span>
              <span className="font-semibold text-red-700">n't</span> they ... ?
            </p>
          </div>

          <div className="pb-9">
            <h3 className="font-bold text-pink-700 uppercase">Short Answers</h3>
            <p>
              Yes, I <span className="font-semibold">am</span>. / No, I
              <span className="font-semibold">'m</span>{" "}
              <span className="font-semibold text-red-700">not</span>.
            </p>
            <p>
              Yes, you <span className="font-semibold">are</span>. / No, you{" "}
              <span className="font-semibold">are</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, he <span className="font-semibold">is</span>. / No, he{" "}
              <span className="font-semibold">is</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, she <span className="font-semibold">is</span>. / No, she{" "}
              <span className="font-semibold">is</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, it <span className="font-semibold">is</span>. / No, it{" "}
              <span className="font-semibold">is</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, we <span className="font-semibold">are</span>. / No, we{" "}
              <span className="font-semibold">are</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, you <span className="font-semibold">are</span>. / No, you{" "}
              <span className="font-semibold">are</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, they <span className="font-semibold">are</span>. / No, they{" "}
              <span className="font-semibold">are</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
          </div>

          <p className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              As you can see, "am", "is", "are" are both the main verbs and the
              auxiliary verbs in the structures above. In other words, we use
              them to make negative sentences and questions.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-9">
            <p className="font-semibold pb-6 italic">Examples:</p>{" "}
            <p className="flex pb-1">
              <span className="w-10 font-bold">+</span>
              <span className="italic">She's a doctor.</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">-</span>
              <span className="italic">She isn't a doctor.</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">+?</span>
              <span className="italic">Is she a doctor?</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">-?</span>
              <span className="italic">
                Isn't she a doctor? / Is she not a doctor?
              </span>
            </p>
            <p className="flex pb-6">
              <span className="w-10 font-bold"></span>
              <span className="italic">Yes, she is. / No, she isn't.</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">+</span>
              <span className="italic">They're friendly.</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">-</span>
              <span className="italic">They aren't friendly.</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">+?</span>
              <span className="italic">Are they friendly?</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">-?</span>
              <span className="italic">
                Aren't they friendly? / Are they not friendly?
              </span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold"></span>
              <span className="italic">Yes, they are. / No, they aren't.</span>
            </p>
          </div>
        </div>

        {/* ---------- Body in Farsi ---------- */}
        <div
          className={`duration-300 ${
            farsi ? "" : "hidden opacity-0"
          } my-7 mx-5 text-indigo-950 text-xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            To Be (Present) <br />
            <span lang="fa" dir="rtl" className="text-2xl">
              بودن / هستن
            </span>
          </h1>

          <p lang="fa" dir="rtl" className="mb-9">
            فعل be به معنی «بودن / هستن» یکی از رایج ترین فعل ها در زبان انگلیسی
            است. نکته متفاوت در مورد این فعل این است که بر خلاف اغلب افعال در
            زبان انگلیسی برای فاعل های متفاوت صرف می شود.
          </p>
          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase">
              Positive{" "}
              <span lang="fa" dir="rtl" className="ml-5">
                خبری مثبت
              </span>
            </h3>
            <p>
              I <span className="font-semibold">am</span> / I
              <span className="font-semibold">'m</span> ... .
            </p>
            <p>
              You <span className="font-semibold">are</span> / You
              <span className="font-semibold">'re</span> ... .
            </p>
            <p>
              He <span className="font-semibold">is</span> / He
              <span className="font-semibold">'s</span> ... .
            </p>
            <p>
              She <span className="font-semibold">is</span> / She
              <span className="font-semibold">'s</span> ... .
            </p>
            <p>
              It <span className="font-semibold">is</span> / It
              <span className="font-semibold">'s</span> ... .
            </p>
            <p>
              We <span className="font-semibold">are</span> / We
              <span className="font-semibold">'re</span> ... .
            </p>
            <p>
              You <span className="font-semibold">are</span> / You
              <span className="font-semibold">'re</span> ... .
            </p>
            <p>
              They <span className="font-semibold">are</span> / They
              <span className="font-semibold">'re</span> ... .
            </p>
          </div>
          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase">
              Negative{" "}
              <span lang="fa" dir="rtl" className="ml-5">
                خبری منفی
              </span>
            </h3>
            <p>
              I <span className="font-semibold">am</span>{" "}
              <span className="font-semibold text-red-700">not</span> ... . / I
              <span className="font-semibold">'m</span>{" "}
              <span className="font-semibold text-red-700">not</span> ... .
            </p>
            <p>
              You <span className="font-semibold">are</span>{" "}
              <span className="font-semibold text-red-700">not</span> ... . /
              You
              <span className="font-semibold"> are</span>
              <span className="font-semibold text-red-700">n't</span> ... .
            </p>
            <p>
              He <span className="font-semibold">is</span>{" "}
              <span className="font-semibold text-red-700">not</span> ... . / He
              <span className="font-semibold"> is</span>
              <span className="font-semibold text-red-700">n't</span> ... .
            </p>
            <p>
              She <span className="font-semibold">is</span>{" "}
              <span className="font-semibold text-red-700">not</span> ... . /
              She
              <span className="font-semibold"> is</span>
              <span className="font-semibold text-red-700">n't</span> ... .
            </p>
            <p>
              It <span className="font-semibold">is</span>{" "}
              <span className="font-semibold text-red-700">not</span> ... . / It
              <span className="font-semibold"> is</span>
              <span className="font-semibold text-red-700">n't</span> ... .
            </p>
            <p>
              We <span className="font-semibold">are</span>{" "}
              <span className="font-semibold text-red-700">not</span> ... . / We
              <span className="font-semibold"> are</span>
              <span className="font-semibold text-red-700">n't</span> ... .
            </p>
            <p>
              You <span className="font-semibold">are</span>{" "}
              <span className="font-semibold text-red-700">not</span> ... . /
              You
              <span className="font-semibold"> are</span>
              <span className="font-semibold text-red-700">n't</span> ... .
            </p>
            <p>
              They <span className="font-semibold">are</span>{" "}
              <span className="font-semibold text-red-700">not</span> ... . /
              They
              <span className="font-semibold"> are</span>
              <span className="font-semibold text-red-700">n't</span> ... .
            </p>
          </div>

          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase">
              Positive Questions{" "}
              <span lang="fa" dir="rtl" className="ml-5">
                سوالی مثبت
              </span>
            </h3>
            <p>
              <span className="font-semibold">Am</span> I ... ?
            </p>
            <p>
              <span className="font-semibold">Are</span> you ... ?
            </p>
            <p>
              <span className="font-semibold">Is</span> he ... ?
            </p>
            <p>
              <span className="font-semibold">Is</span> she ... ?
            </p>
            <p>
              <span className="font-semibold">Is</span> it ... ?
            </p>
            <p>
              <span className="font-semibold">Are</span> we ... ?
            </p>
            <p>
              <span className="font-semibold">Are</span> you ... ?
            </p>
            <p>
              <span className="font-semibold">Are</span> they ... ?
            </p>
          </div>

          <div className="mb-9">
            <h3 className="font-bold text-pink-700 uppercase">
              Negative Questions{" "}
              <span lang="fa" dir="rtl" className="ml-5">
                سوالی منفی
              </span>
            </h3>
            <p>
              <span className="font-semibold">Am</span> I{" "}
              <span className="font-semibold text-red-700">not</span> ...?
            </p>
            <p>
              <span className="font-semibold">Are</span> you{" "}
              <span className="font-semibold text-red-700">not</span> ... ? /{" "}
              <span className="font-semibold">Are</span>
              <span className="font-semibold text-red-700">n't</span> you ... ?
            </p>
            <p>
              <span className="font-semibold">Is</span> he{" "}
              <span className="font-semibold text-red-700">not</span> ... ? /{" "}
              <span className="font-semibold">Is</span>
              <span className="font-semibold text-red-700">n't</span> he ... ?
            </p>
            <p>
              <span className="font-semibold">Is</span> she{" "}
              <span className="font-semibold text-red-700">not</span> ... ? /{" "}
              <span className="font-semibold">Is</span>
              <span className="font-semibold text-red-700">n't</span> she ... ?
            </p>
            <p>
              <span className="font-semibold">Is</span> it{" "}
              <span className="font-semibold text-red-700">not</span> ... ? /{" "}
              <span className="font-semibold">Is</span>
              <span className="font-semibold text-red-700">n't</span> it ... ?
            </p>
            <p>
              <span className="font-semibold">Are</span> we{" "}
              <span className="font-semibold text-red-700">not</span> ... ? /{" "}
              <span className="font-semibold">Are</span>
              <span className="font-semibold text-red-700">n't</span> we ... ?
            </p>
            <p>
              <span className="font-semibold">Are</span> you{" "}
              <span className="font-semibold text-red-700">not</span> ... ? /{" "}
              <span className="font-semibold">Are</span>
              <span className="font-semibold text-red-700">n't</span> you ... ?
            </p>
            <p>
              <span className="font-semibold">Are</span> they{" "}
              <span className="font-semibold text-red-700">not</span> ... ? /{" "}
              <span className="font-semibold">Are</span>
              <span className="font-semibold text-red-700">n't</span> they ... ?
            </p>
          </div>

          <div className="pb-9">
            <h3 className="font-bold text-pink-700 uppercase">
              Short Answers{" "}
              <span lang="fa" dir="rtl" className="ml-5">
                پاسخ کوتاه
              </span>
            </h3>
            <p>
              Yes, I <span className="font-semibold">am</span>. / No, I
              <span className="font-semibold">'m</span>{" "}
              <span className="font-semibold text-red-700">not</span>.
            </p>
            <p>
              Yes, you <span className="font-semibold">are</span>. / No, you{" "}
              <span className="font-semibold">are</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, he <span className="font-semibold">is</span>. / No, he{" "}
              <span className="font-semibold">is</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, she <span className="font-semibold">is</span>. / No, she{" "}
              <span className="font-semibold">is</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, it <span className="font-semibold">is</span>. / No, it{" "}
              <span className="font-semibold">is</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, we <span className="font-semibold">are</span>. / No, we{" "}
              <span className="font-semibold">are</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, you <span className="font-semibold">are</span>. / No, you{" "}
              <span className="font-semibold">are</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, they <span className="font-semibold">are</span>. / No, they{" "}
              <span className="font-semibold">are</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
          </div>
          <p lang="fa" dir="rtl" className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pr-3">
              همانطور که در ساختار های بالا می بینیم is ،am و are هم نقش فعل
              اصلی و هم نقش فعل کمکی را بازی می کنند.
            </span>
          </p>
          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-6">
              مثال:
            </p>{" "}
            <p className="flex">
              <span className="w-10 font-bold">+</span>
              <span className="italic">She's a doctor.</span>
            </p>
            <p lang="fa" dir="rtl" className="pb-1">
              او دکتر است.
            </p>
            <p className="flex">
              <span className="w-10 font-bold">-</span>
              <span className="italic">She isn't a doctor.</span>
            </p>
            <p lang="fa" dir="rtl" className="pb-1">
              او دکتر نیست.
            </p>
            <p className="flex">
              <span className="w-10 font-bold">+?</span>
              <span className="italic">Is she a doctor?</span>
            </p>
            <p lang="fa" dir="rtl" className="pb-1">
              آیا او دکتر است؟
            </p>
            <p className="flex">
              <span className="w-10 font-bold">-?</span>
              <span className="italic w-fit">
                Isn't she a doctor? / Is she not a doctor?
              </span>
            </p>
            <p lang="fa" dir="rtl" className="pb-1">
              آیا او دکتر نیست؟
            </p>
            <p className="flex">
              <span className="w-10 font-bold"></span>
              <span className="italic">Yes, she is. / No, she isn't.</span>
            </p>
            <p lang="fa" dir="rtl" className="pb-16">
              بله، او دکتر است. / نه، او دکتر نیست.
            </p>
            <p className="flex">
              <span className="w-10 font-bold">+</span>
              <span className="italic">They're very kind.</span>
            </p>
            <p lang="fa" dir="rtl" className="pb-1">
              آنها خیلی مهربان هستند.
            </p>
            <p className="flex">
              <span className="w-10 font-bold">-</span>
              <span className="italic">They aren't very kind.</span>
            </p>
            <p lang="fa" dir="rtl" className="pb-1">
              آنها خیلی مهربان نیستند.
            </p>
            <p className="flex">
              <span className="w-10 font-bold">+?</span>
              <span className="italic">Are they very kind?</span>
            </p>
            <p lang="fa" dir="rtl" className="pb-1">
              آیا آنها خیلی مهربان هستند؟
            </p>
            <p className="flex">
              <span className="w-10 font-bold">-?</span>
              <span className="italic w-fit">
                Aren't they very kind? / Are they not very kind?
              </span>
            </p>
            <p lang="fa" dir="rtl" className="pb-1">
              آیا آنها خیلی مهربان نیستند؟
            </p>
            <p className="flex">
              <span className="w-10 font-bold"></span>
              <span className="italic">Yes, they are. / No, they aren't.</span>
            </p>
            <p lang="fa" dir="rtl" className="pb-1">
              بله، آنها خیلی مهربان هستند. / نه، آنها خیلی مهربان نیستند.
            </p>
          </div>
        </div>
      </div>
      <ExerciseCard questions={questions} instruction={instruction} />
    </>
  );
};
