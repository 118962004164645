import { useState } from "react";
import { Link } from "react-router-dom";
import { LanguageSwitch, ExerciseCard, useTitle } from "../../../components";
import Bulb from "../../../assets/images/bulb.png";

export const ImperativesElementary = ({ title }) => {
  const [farsi, setFarsi] = useState(false);
  const instruction = <span>Put the words in the right order.</span>;
  const questions = [
    {
      id: 1,
      question: <span>be / don't / late.</span>,
      answer: <span>Don't be late.</span>,
    },
    {
      id: 2,
      question: <span>breath / a / take / please / deep.</span>,
      answer: (
        <span>Take a deep breath, please. // Please, take a deep breath.</span>
      ),
    },
    {
      id: 3,
      question: <span>your / waste / time / don't.</span>,
      answer: <span>Don't waste your time.</span>,
    },
    {
      id: 4,
      question: <span>to / be / kind / yourself.</span>,
      answer: <span>Be kind to yourself.</span>,
    },
    {
      id: 5,
      question: <span>make / please / a / night / don't / noise / at.</span>,
      answer: (
        <span>
          Don't make a noise at night, please. // Please, don't make a noise at
          night.
        </span>
      ),
    },
    {
      id: 5,
      question: <span>the / water / a lot of / drink / day / during.</span>,
      answer: <span>Drink a lot of water during the day.</span>,
    },
  ];

  useTitle(!farsi ? title : "جملات امری");

  return (
    <>
      {/* -------------------- Breadcrumb Menu -------------------- */}
      <div data-aos="fade" data-aos-duration="1500">
        <nav className="flex pl-3 pt-2 text-gray-400 text-sm font-semibold">
          <Link to="/" className="flex duration-300 hover:text-indigo-950">
            <span className="pt-0.5 md:pr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
            </span>
            <span className="max-md:hidden">Home</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link to="/grammar" className="duration-300 hover:text-indigo-950">
            <span>Grammar</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link
            to="/grammar/elementary"
            className="duration-300 hover:text-indigo-950"
          >
            <span>A1 - A2</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <span className="text-indigo-950">Imperatives</span>
        </nav>
      </div>

      <div data-aos="zoom-in" data-aos-duration="800">
        {/* -------------------- Language Switch -------------------- */}
        <LanguageSwitch farsi={farsi} setFarsi={setFarsi} />

        {/* -------------------- Body in English -------------------- */}
        <div
          className={`duration-300 ${
            farsi ? "hidden opacity-0" : ""
          } my-7 mx-5 text-indigo-950 text-xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            Imperatives
          </h1>
          <p className="mb-9">
            We use imperatives to give people orders or instructions. In other
            words, we use imperatives to tell others what to do, or what not to
            do.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-12">
            <p className="font-semibold pb-3">Examples:</p>{" "}
            <p className="pb-2">Open your books.</p>
            <p className="pb-2">Don't speak about it.</p>
          </div>

          <h2 className="font-bold mb-3">Positive Imperatives</h2>
          <p className="mb-9">
            In order to make a positive imperative sentence, we just need to
            start the sentence with the main verb.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-12">
            <p className="font-semibold pb-3">Examples:</p>{" "}
            <p className="pb-2">Do your homework.</p>
            <p className="pb-2">Close the door.</p>
          </div>

          <h2 className="font-bold mb-3">Negative Imperatives</h2>
          <p className="mb-9">
            In order to make a negative imperative sentence, we just put "don't"
            before the positive imperative sentence.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-12">
            <p className="font-semibold pb-3">Examples:</p>{" "}
            <p className="pb-2">Don't check your Instagram before sleeping.</p>
            <p className="pb-2">Don't forget to turn off the lights.</p>
          </div>

          <p className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              We can add the word "please" at the beginning or at the end of an
              imperative sentece to sound polite.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-3">Examples:</p>{" "}
            <p className="pb-2">Please, be quiet.</p>
            <p className="pb-2">Close the door, please.</p>
            <p className="pb-2">Don't smoke inside, please.</p>
          </div>
        </div>

        {/* ---------- Body in Farsi ---------- */}
        <div
          className={`duration-300 ${
            farsi ? "" : "hidden opacity-0"
          } my-7 mx-5 text-indigo-950 text-xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            Imperatives <br />
            <span lang="fa" dir="rtl" className="text-2xl">
              جملات امری
            </span>
          </h1>
          <p lang="fa" dir="rtl" className="mb-9">
            از ساختار امری استفاده می کنیم تا به دیگران دستور بدهیم. به عبارت
            دیگر هر وقت می خواهیم به کسی بگوییم چه کار باید بکند یا چه کار نباید
            بکند می توانیم از جملات امری استفاده کنیم.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-12">
            <p lang="fa" dir="rtl" className="font-semibold pb-3 not-italic">
              مثال:
            </p>{" "}
            <p className="pb-2">Open your books.</p>
            <p lang="fa" dir="rtl" className="not-italic mb-2">
              کتاب هایتان را باز کنید.
            </p>
            <p className="pb-2">Don't speak about it with anyone.</p>
            <p lang="fa" dir="rtl" className="not-italic">
              راجع بهش با هیچ کس صحبت نکن.
            </p>
          </div>

          <h2 lang="fa" dir="rtl" className="font-bold mb-3">
            جملات امری مثبت
          </h2>
          <p lang="fa" dir="rtl" className="mb-9">
            برای ساختن یک جمله امری مثبت، کافی است جمله را با شکل ساده فعل اصلی
            شروع کنیم.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-12">
            <p lang="fa" dir="rtl" className="font-semibold pb-3 not-italic">
              مثال:
            </p>{" "}
            <p>Do your homework.</p>
            <p lang="fa" dir="rtl" className="pb-2 not-italic">
              تکلیف هایت را انجام بده.
            </p>
            <p>Close the door.</p>
            <p lang="fa" dir="rtl" className=" not-italic">
              در را ببند.
            </p>
          </div>

          <h2 lang="fa" dir="rtl" className="font-bold mb-3">
            جملات امری منفی
          </h2>
          <p lang="fa" dir="rtl" className="mb-9">
            برای ساختن یک جمله امری منفی کافی است عبارت don't را در ابتدای جمله
            امری مثبت بگذاریم.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-12">
            <p lang="fa" dir="rtl" className="font-semibold pb-3 not-italic">
              مثال:
            </p>{" "}
            <p className="mb-1">Don't check your Instagram before sleeping.</p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              قبل از خوابیدن اینستاگرمت را چک نکن.
            </p>
            <p className="mb-1">Don't forget to turn off the lights.</p>
            <p lang="fa" dir="rtl" className=" not-italic">
              یادت نرود چراغ ها را خاموش کنی.
            </p>
          </div>

          <p lang="fa" dir="rtl" className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              برای اینکه جمله امری مان را مودبانه کنیم، می توانیم در ابتدا یا
              انتهای آن کلمه please بگذاریم.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-3 not-italic">
              مثال:
            </p>{" "}
            <p className="mb-1">Please, be quiet.</p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              لطفا ساکت باش.
            </p>
            <p className="mb-1">Close the door, please.</p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              در را ببند لطفا.
            </p>
            <p className="mb-1">Don't smoke inside, please.</p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              لطفا تو اتاق سیگار نکش.
            </p>
          </div>
        </div>
      </div>
      <ExerciseCard questions={questions} instruction={instruction} />
    </>
  );
};
