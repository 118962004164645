import { useState } from "react";
import { Link } from "react-router-dom";
import { LanguageSwitch, ExerciseCard, useTitle } from "../../../components";
import Bulb from "../../../assets/images/bulb.png";
import ArrowLongRight from "../../../assets/images/arrowLongLight.svg";

export const AdjectivesElementary = ({ title }) => {
  const [farsi, setFarsi] = useState(false);
  const instruction = <span>Put the words in the right order.</span>;
  const questions = [
    {
      id: 1,
      question: <span>friends / are / good / they.</span>,
      answer: <span>They are good friends.</span>,
    },
    {
      id: 2,
      question: <span>not / good / it / idea / is / a.</span>,
      answer: <span>It is not a good idea.</span>,
    },
    {
      id: 3,
      question: <span>is / cold / tea / my.</span>,
      answer: <span>My tea is cold.</span>,
    },
    {
      id: 4,
      question: <span>had / a / we / time / great.</span>,
      answer: <span>We had a great time.</span>,
    },
    {
      id: 5,
      question: <span>neighbors / are / noisy / so / our.</span>,
      answer: <span>Our neighbors are so noisy.</span>,
    },
    {
      id: 6,
      question: <span>was / very / a / exam / it / difficult.</span>,
      answer: <span>It was a very difficult exam.</span>,
    },
  ];

  useTitle(!farsi ? title : "صفت ها");

  return (
    <>
      {/* -------------------- Breadcrumb Menu -------------------- */}
      <div data-aos="fade" data-aos-duration="1500">
        <nav className="flex pl-3 pt-2 text-gray-400 text-sm font-semibold">
          <Link to="/" className="flex duration-300 hover:text-indigo-950">
            <span className="pt-0.5 md:pr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
            </span>
            <span className="max-md:hidden">Home</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link to="/grammar" className="duration-300 hover:text-indigo-950">
            <span>Grammar</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link
            to="/grammar/elementary"
            className="duration-300 hover:text-indigo-950"
          >
            <span>A1 - A2</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <span className="text-indigo-950">Adjectives</span>
        </nav>
      </div>

      <div data-aos="zoom-in" data-aos-duration="800">
        {/* -------------------- Language Switch -------------------- */}
        <LanguageSwitch farsi={farsi} setFarsi={setFarsi} />

        {/* -------------------- Body in English -------------------- */}
        <div
          className={`duration-300 ${
            farsi ? "hidden opacity-0" : ""
          } my-7 mx-5 text-indigo-950 text-xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            Adjectives
          </h1>
          <p className="mb-9">
            An <span className="underline">adjective</span> is a word that
            describes a noun or a pronoun.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-3">Examples:</p>
            <p>
              They live in a <span className="underline">big</span> house.
            </p>
            <p>
              She is very <span className="underline">clever</span>.
            </p>
          </div>

          <p className="mb-9">
            In the first example above, the adjective "big" is describing the
            noun "house", and in the second example, the adjective "clever" is
            describing the pronoun "she".
          </p>
          <p className="mb-9">
            In English an <span className="underline">adjective</span> usually
            sits before a noun.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-3">Examples:</p>
            <p>
              I don't like <span className="underline">cold</span> weather very
              much.
            </p>
            <p>
              I don't know the <span className="underline">right</span> answer.
            </p>
          </div>

          <p className="mb-9">
            We can also use an <span className="underline">adjective</span>{" "}
            without a noun, after the verb <span className="font-bold">be</span>{" "}
            (am / is / are / was / were).
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-3">Examples:</p>
            <p>
              His parents are <span className="underline">young</span>.
            </p>
            <p>
              It's very <span className="underline">modern</span>.
            </p>
          </div>

          <p className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              Remember that adjectives do not change before a plural noun.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p className="font-semibold pb-3">Examples:</p>{" "}
            <p className="flex pb-2">
              <span>
                a <span className="underline">comfortable</span> armchair
              </span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                many <span className="underline">comfortable</span> armchairs
              </span>
            </p>
            <p className="flex pb-2">
              <span>
                a <span className="underline">tall</span> man
              </span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                three <span className="underline">tall</span> men
              </span>
            </p>
            <p className="flex pb-2">
              <span>
                a <span className="underline">friendly</span> dog
              </span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                two <span className="underline">friendly</span> dogs
              </span>
            </p>
          </div>
        </div>

        {/* ---------- Body in Farsi ---------- */}
        <div
          className={`duration-300 ${
            farsi ? "" : "hidden opacity-0"
          } my-7 mx-5 text-indigo-950 text-xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            Adjectives <br />
            <span lang="fa" dir="rtl" className="text-2xl">
              صفت ها
            </span>
          </h1>

          <p lang="fa" dir="rtl" className="mb-9">
            <span className="underline">صفت</span> کلمه ایست که یک اسم یا یک
            ضمیر را توصیف می کند.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-3 not-italic">
              مثال:
            </p>
            <p>
              They live in a <span className="underline">big</span> house.
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              آنها در یک خانه بزرگ زندگی می کنند.
            </p>
            <p>
              She is very <span className="underline">clever</span>.
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              او خیلی باهوش است.
            </p>
          </div>

          <p lang="fa" dir="rtl" className="mb-9">
            در اولین مثال بالا، صفت big اسم house را توصیف می کند، و در مثال
            دوم، صفت clever ضمیر she را توصیف می کند.
          </p>
          <p lang="fa" dir="rtl" className="mb-9">
            در زبان انگلیسی معمولا یک صفت قبل از یک اسم می نشیند.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-3 not-italic">
              مثال:
            </p>
            <p>
              I don't like <span className="underline">cold</span> weather very
              much.
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              من از هوای سرد خیلی خوشم نمی آید.
            </p>
            <p>
              I don't know the <span className="underline">right</span> answer.
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              من جواب درست را نمی دانم.
            </p>
          </div>

          <p lang="fa" dir="rtl" className="mb-9">
            همچنین بعد از فعل be (am / is / are / was / were) می توانیم یک صفت
            داشته باشیم بدون نیاز به اینکه بعد آن اسمی باشد.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-3 not-italic">
              مثال:
            </p>
            <p>
              His parents are <span className="underline">young</span>.
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              والدین او جوان هستند.
            </p>
            <p>
              It's very <span className="underline">modern</span>.
            </p>
            <p lang="fa" dir="rtl" className="mb-2 not-italic">
              این خیلی جدید و به روز است.
            </p>
          </div>

          <p lang="fa" dir="rtl" className="flex mb-9">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              یادمان باشد که شکل یک صفت قبل از اسامی جمع تغییر نمی کند، به عبارت
              دیگر صفت ها s جمع نمی گیرند.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-9">
            <p lang="fa" dir="rtl" className="font-semibold pb-3 not-italic">
              مثال:
            </p>{" "}
            <p className="flex pb-2">
              <span>
                a <span className="underline">comfortable</span> armchair
              </span>

              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                many <span className="underline">comfortable</span> armchairs
              </span>
            </p>
            <p className="flex pb-2">
              <span>
                a <span className="underline">tall</span> man
              </span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                three <span className="underline">tall</span> men
              </span>
            </p>
            <p className="flex pb-2">
              <span>
                a <span className="underline">friendly</span> dog
              </span>
              <img src={ArrowLongRight} alt="arrow" className="w-8 mx-2 pt-1" />
              <span>
                two <span className="underline">friendly</span> dogs
              </span>
            </p>
          </div>
        </div>
      </div>
      <ExerciseCard questions={questions} instruction={instruction} />
    </>
  );
};
