import { useState } from "react";
import { Link } from "react-router-dom";
import { LanguageSwitch, ExerciseCard, useTitle } from "../../../components";
import Bulb from "../../../assets/images/bulb.png";
import LinkImg from "../../../assets/images/link.svg";

export const PresentPerfectSimpleElementary = ({ title }) => {
  const [farsi, setFarsi] = useState(false);
  const instruction = (
    <span>Write sentences in the Present Perfect Simple.</span>
  );
  const questions = [
    {
      id: 1,
      question: <span>He ____ his keys. (lose)</span>,
      answer: (
        <span>
          He<span className="font-bold">'s</span> / He{" "}
          <span className="font-bold">has lost</span> his keys.
        </span>
      ),
    },
    {
      id: 2,
      question: <span>____ she ____ her homework yet? (do)</span>,
      answer: (
        <span>
          <span className="font-bold">Has</span> she{" "}
          <span className="font-bold">done</span> her homework yet?
        </span>
      ),
    },
    {
      id: 3,
      question: <span>____ you ever ____ sushi? (try)</span>,
      answer: (
        <span>
          <span className="font-bold">Have</span> you ever{" "}
          <span className="font-bold">tried</span> sushi?
        </span>
      ),
    },
    {
      id: 4,
      question: <span>They ____ the windows. (clean)</span>,
      answer: (
        <span>
          They<span className="font-bold">'ve</span> / They{" "}
          <span className="font-bold">have cleaned</span> the windows.
        </span>
      ),
    },
    {
      id: 5,
      question: <span>I ____ my best friend for a long time. (not / see)</span>,
      answer: (
        <span>
          I <span className="font-bold">have not / haven't seen</span> my best
          friend for a long time.
        </span>
      ),
    },
    {
      id: 6,
      question: <span>____ he ____ the report? (finish)</span>,
      answer: (
        <span>
          <span className="font-bold">Has</span> he{" "}
          <span className="font-bold">finished</span> the report?
        </span>
      ),
    },
    {
      id: 7,
      question: <span>She ____ her driving test. (pass)</span>,
      answer: (
        <span>
          She<span className="font-bold">'s</span> / She{" "}
          <span className="font-bold">has passed</span> her driving test.
        </span>
      ),
    },
    {
      id: 8,
      question: <span>My parents ____ never ____ to Italy. (travel)</span>,
      answer: (
        <span>
          My parents <span className="font-bold">have</span> never{" "}
          <span className="font-bold">traveled</span> to Italy.
        </span>
      ),
    },
  ];

  useTitle(title);

  return (
    <>
      {/* -------------------- Breadcrumb Menu -------------------- */}
      <div data-aos="fade" data-aos-duration="1500">
        <nav className="flex pl-3 pt-2 text-gray-400 text-sm font-semibold">
          <Link to="/" className="flex duration-300 hover:text-indigo-950">
            <span className="pt-0.5 md:pr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
            </span>
            <span className="max-md:hidden">Home</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link to="/grammar" className="duration-300 hover:text-indigo-950">
            <span>Grammar</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <Link
            to="/grammar/elementary"
            className="duration-300 hover:text-indigo-950"
          >
            <span>A1 - A2</span>
          </Link>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <span className="text-indigo-950">Present Perfect Simple</span>
        </nav>
      </div>

      <div data-aos="zoom-in" data-aos-duration="800">
        {/* -------------------- Language Switch -------------------- */}
        <LanguageSwitch farsi={farsi} setFarsi={setFarsi} />

        {/* -------------------- Body in English -------------------- */}
        <div
          className={`duration-300 ${
            farsi ? "hidden opacity-0" : ""
          } my-7 mx-5 text-indigo-950 text-xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            The Present Perfect Simple
          </h1>
          <p className="mb-6">
            We use the Present Perfect Simple to talk about actions that
            happened and finished in the past, but we don’t say when. If we
            mention the time, or our reader / listener already knows the time,
            we should use the Simple Past instead.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-6">
            <p className="font-semibold pb-3">Examples:</p>{" "}
            <p className="flex pb-2">She has visited Paris many times.</p>
            <p className="flex pb-2">I’ve seen this movie before.</p>
          </div>

          <p className="mb-6">
            We often use "ever" in questions when we're talking about{" "}
            <mark>life experiences</mark>. "Ever" comes before the{" "}
            <span className="underline">past participle</span>.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-6">
            <p className="font-semibold pb-3">Examples:</p>{" "}
            <p className="pb-2">
              Have you <span className="font-bold">ever</span>{" "}
              <span className="underline">tried</span> surfing?
            </p>
            <p className="pb-2">
              Have you <span className="font-bold">ever</span>{" "}
              <span className="underline">eaten</span> sushi?
            </p>
          </div>

          <p className="mb-6">
            We can also use "never" before the past participle to say that the
            verb has not happened at any time in a person’s life. "Never" comes
            before the <span className="underline">past participle</span>.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-6">
            <p className="font-semibold pb-3">Examples:</p>{" "}
            <p className="pb-2">
              I've <span className="font-bold">never</span>{" "}
              <span className="underline">ridden</span> a horse.
            </p>
            <p className="pb-2">
              He has <span className="font-bold">never</span>{" "}
              <span className="underline">traveled</span> to Europe.
            </p>
          </div>

          <p className="flex mb-6">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              "Never" makes the sentence negative, so we don’t put "not" after
              the auxiliary verb when we use "never". (
              <span className="text-red-600 line-through">
                I haven't never ridden a horse.
              </span>
              )
            </span>
          </p>

          <p className="mb-6">
            We also use the Present Perfect Simple to talk about recent events
            when we don’t mention a specific time.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md italic mb-6">
            <p className="font-semibold pb-3">Examples:</p>{" "}
            <p className="flex pb-2">
              Your father has arrived. He’s waiting for you downstairs.
            </p>
            <p className="flex pb-2">
              I've done my homework, so I'm going out to play with my friends.
            </p>
          </div>

          <p className="mb-4">There are two types of verbs in English:</p>

          <div className="flex mb-4">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot pb-2"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="flex flex-col">
              <span className="pt-1 font-bold flex-none mb-2">
                Regular Verbs:
              </span>
              <span>
                Regular verbs are verbs that follow the usual Simple Past and
                past participle pattern by adding "-ed" to the base form. For
                example, "walk" becomes "walked" in both the Simple Past tense
                and the past participle form.
              </span>
            </span>
          </div>

          <div className="flex mb-4">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot pb-2"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="flex flex-col">
              <span className="pt-1 font-bold flex-none mb-2">
                Irregular Verbs:
              </span>
              <span>
                Irregular verbs are verbs that do not follow the usual Simple
                Past pattern (-ed). Their forms change in both the Simple Past
                tense and the past participle (
                <span className="italic">e.g., eat &gt; ate &gt; eaten</span>).
                The Simple Past and past participle forms of these verbs should
                be memorized.
              </span>
            </span>
          </div>

          <p className="mb-6">
            Let's take a look at the different forms of the Present Perfect
            Simple for the regular verb "work".
          </p>

          <div className="mb-6">
            <h3 className="font-bold text-pink-700 uppercase mb-1">Positive</h3>
            <p>
              I <span className="font-semibold">have</span> / I
              <span className="font-semibold">'ve worked</span> ... .
            </p>
            <p>
              You <span className="font-semibold">have</span> / You
              <span className="font-semibold">'ve worked</span> ... .
            </p>
            <p>
              He <span className="font-semibold">has</span> / He
              <span className="font-semibold">'s worked</span> ... .
            </p>
            <p>
              She <span className="font-semibold">has</span> / She
              <span className="font-semibold">'s worked</span> ... .
            </p>
            <p>
              It <span className="font-semibold">has</span> / It
              <span className="font-semibold">'s worked</span> ... .
            </p>
            <p>
              We <span className="font-semibold">have</span> / We
              <span className="font-semibold">'ve worked</span> ... .
            </p>
            <p>
              You <span className="font-semibold">have</span> / You
              <span className="font-semibold">'ve worked</span> ... .
            </p>
            <p>
              They <span className="font-semibold">have</span> / They
              <span className="font-semibold">'ve worked</span> ... .
            </p>
          </div>

          <div className="mb-6">
            <h3 className="font-bold text-pink-700 uppercase mb-1">Negative</h3>
            <p>
              I <span className="font-semibold">have</span>{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">worked</span> ... . / I
              <span className="font-semibold"> have</span>
              <span className="font-semibold text-red-700">n't</span>{" "}
              <span className="font-semibold">worked</span> ... .
            </p>
            <p>
              You <span className="font-semibold">have</span>{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">worked</span> ... . / You
              <span className="font-semibold"> have</span>
              <span className="font-semibold text-red-700">n't</span>{" "}
              <span className="font-semibold">worked</span> ... .
            </p>
            <p>
              He <span className="font-semibold">has</span>{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">worked</span> ... . / He
              <span className="font-semibold"> has</span>
              <span className="font-semibold text-red-700">n't</span>{" "}
              <span className="font-semibold">worked</span> ... .
            </p>
            <p>
              She <span className="font-semibold">has</span>{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">worked</span> ... . / She
              <span className="font-semibold"> has</span>
              <span className="font-semibold text-red-700">n't</span>{" "}
              <span className="font-semibold">worked</span> ... .
            </p>
            <p>
              It <span className="font-semibold">has</span>{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">worked</span> ... . / It
              <span className="font-semibold"> has</span>
              <span className="font-semibold text-red-700">n't</span>{" "}
              <span className="font-semibold">worked</span> ... .
            </p>
            <p>
              We <span className="font-semibold">have</span>{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">worked</span> ... . / We
              <span className="font-semibold"> have</span>
              <span className="font-semibold text-red-700">n't</span>{" "}
              <span className="font-semibold">worked</span> ... .
            </p>
            <p>
              You <span className="font-semibold">have</span>{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">worked</span> ... . / You
              <span className="font-semibold"> have</span>
              <span className="font-semibold text-red-700">n't</span>{" "}
              <span className="font-semibold">worked</span> ... .
            </p>
            <p>
              They <span className="font-semibold">have</span>{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">worked</span> ... . / They
              <span className="font-semibold"> have</span>
              <span className="font-semibold text-red-700">n't</span>{" "}
              <span className="font-semibold">worked</span> ... .
            </p>
          </div>

          <div className="mb-6">
            <h3 className="font-bold text-pink-700 uppercase mb-1">
              Positive Questions
            </h3>
            <p>
              <span className="font-semibold">Have</span> I{" "}
              <span className="font-semibold">worked</span> ... ?
            </p>
            <p>
              <span className="font-semibold">Have</span> you{" "}
              <span className="font-semibold">worked</span> ... ?
            </p>
            <p>
              <span className="font-semibold">Has</span> he{" "}
              <span className="font-semibold">worked</span> ... ?
            </p>
            <p>
              <span className="font-semibold">Has</span> she{" "}
              <span className="font-semibold">worked</span> ... ?
            </p>
            <p>
              <span className="font-semibold">Has</span> it{" "}
              <span className="font-semibold">worked</span> ... ?
            </p>
            <p>
              <span className="font-semibold">Have</span> we{" "}
              <span className="font-semibold">worked</span> ... ?
            </p>
            <p>
              <span className="font-semibold">Have</span> you{" "}
              <span className="font-semibold">worked</span>... ?
            </p>
            <p>
              <span className="font-semibold">Have</span> they{" "}
              <span className="font-semibold">worked</span> ... ?
            </p>
          </div>

          <div className="mb-6">
            <h3 className="font-bold text-pink-700 uppercase mb-1">
              Negative Questions
            </h3>
            <p>
              <span className="font-semibold">Have</span> I{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">worked</span> ...? /{" "}
              <span className="font-semibold">Have</span>
              <span className="font-semibold text-red-700">n't</span> I{" "}
              <span className="font-semibold">worked</span> ... ?
            </p>
            <p>
              <span className="font-semibold">Have</span> you{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">worked</span> ... ? /{" "}
              <span className="font-semibold">Have</span>
              <span className="font-semibold text-red-700">n't</span> you{" "}
              <span className="font-semibold">worked</span>
              ... ?
            </p>
            <p>
              <span className="font-semibold">Has</span> he{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">worked</span> ... ? /{" "}
              <span className="font-semibold">Has</span>
              <span className="font-semibold text-red-700">n't</span> he{" "}
              <span className="font-semibold">worked</span>
              ... ?
            </p>
            <p>
              <span className="font-semibold">Has</span> she{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">worked</span> ... ? /{" "}
              <span className="font-semibold">Has</span>
              <span className="font-semibold text-red-700">n't</span> she{" "}
              <span className="font-semibold">worked</span>
              ... ?
            </p>
            <p>
              <span className="font-semibold">Has</span> it{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">worked</span> ... ? /{" "}
              <span className="font-semibold">Has</span>
              <span className="font-semibold text-red-700">n't</span> it{" "}
              <span className="font-semibold">worked</span>
              ... ?
            </p>
            <p>
              <span className="font-semibold">Have</span> we{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">worked</span> ... ? /{" "}
              <span className="font-semibold">Have</span>
              <span className="font-semibold text-red-700">n't</span> we{" "}
              <span className="font-semibold">worked</span>
              ... ?
            </p>
            <p>
              <span className="font-semibold">Have</span> you{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">worked</span> ... ? /{" "}
              <span className="font-semibold">Have</span>
              <span className="font-semibold text-red-700">n't</span> you{" "}
              <span className="font-semibold">worked</span>
              ... ?
            </p>
            <p>
              <span className="font-semibold">Have</span> they{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">worked</span> ... ? /{" "}
              <span className="font-semibold">Have</span>
              <span className="font-semibold text-red-700">n't</span> they{" "}
              <span className="font-semibold">worked</span>
              ... ?
            </p>
          </div>

          <div className="pb-6">
            <h3 className="font-bold text-pink-700 uppercase mb-1">
              Short Answers
            </h3>
            <p>
              Yes, I <span className="font-semibold">have</span>. / No, I
              <span className="font-semibold"> have</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, you <span className="font-semibold">have</span>. / No, you{" "}
              <span className="font-semibold">have</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, he <span className="font-semibold">has</span>. / No, he{" "}
              <span className="font-semibold">has</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, she <span className="font-semibold">has</span>. / No, she{" "}
              <span className="font-semibold">has</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, it <span className="font-semibold">has</span>. / No, it{" "}
              <span className="font-semibold">has</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, we <span className="font-semibold">have</span>. / No, we{" "}
              <span className="font-semibold">have</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, you <span className="font-semibold">have</span>. / No, you{" "}
              <span className="font-semibold">have</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, they <span className="font-semibold">have</span>. / No, they{" "}
              <span className="font-semibold">have</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
          </div>

          <p className="mb-6">
            Now, let's take a look at the different forms of the Present Perfect
            Simple for the irregular verb "eat".
          </p>

          <div className="mb-6">
            <h3 className="font-bold text-pink-700 uppercase mb-1">Positive</h3>
            <p>
              I <span className="font-semibold">have</span> / I
              <span className="font-semibold">'ve eaten</span> ... .
            </p>
            <p>
              You <span className="font-semibold">have</span> / You
              <span className="font-semibold">'ve eaten</span> ... .
            </p>
            <p>
              He <span className="font-semibold">has</span> / He
              <span className="font-semibold">'s eaten</span> ... .
            </p>
            <p>
              She <span className="font-semibold">has</span> / She
              <span className="font-semibold">'s eaten</span> ... .
            </p>
            <p>
              It <span className="font-semibold">has</span> / It
              <span className="font-semibold">'s eaten</span> ... .
            </p>
            <p>
              We <span className="font-semibold">have</span> / We
              <span className="font-semibold">'ve eaten</span> ... .
            </p>
            <p>
              You <span className="font-semibold">have</span> / You
              <span className="font-semibold">'ve eaten</span> ... .
            </p>
            <p>
              They <span className="font-semibold">have</span> / They
              <span className="font-semibold">'ve eaten</span> ... .
            </p>
          </div>

          <div className="mb-6">
            <h3 className="font-bold text-pink-700 uppercase mb-1">Negative</h3>
            <p>
              I <span className="font-semibold">have</span>{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">eaten</span> ... . / I
              <span className="font-semibold"> have</span>
              <span className="font-semibold text-red-700">n't</span>{" "}
              <span className="font-semibold">eaten</span> ... .
            </p>
            <p>
              You <span className="font-semibold">have</span>{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">eaten</span> ... . / You
              <span className="font-semibold"> have</span>
              <span className="font-semibold text-red-700">n't</span>{" "}
              <span className="font-semibold">eaten</span> ... .
            </p>
            <p>
              He <span className="font-semibold">has</span>{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">eaten</span> ... . / He
              <span className="font-semibold"> has</span>
              <span className="font-semibold text-red-700">n't</span>{" "}
              <span className="font-semibold">eaten</span> ... .
            </p>
            <p>
              She <span className="font-semibold">has</span>{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">eaten</span> ... . / She
              <span className="font-semibold"> has</span>
              <span className="font-semibold text-red-700">n't</span>{" "}
              <span className="font-semibold">eaten</span> ... .
            </p>
            <p>
              It <span className="font-semibold">has</span>{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">eaten</span> ... . / It
              <span className="font-semibold"> has</span>
              <span className="font-semibold text-red-700">n't</span>{" "}
              <span className="font-semibold">eaten</span> ... .
            </p>
            <p>
              We <span className="font-semibold">have</span>{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">eaten</span> ... . / We
              <span className="font-semibold"> have</span>
              <span className="font-semibold text-red-700">n't</span>{" "}
              <span className="font-semibold">eaten</span> ... .
            </p>
            <p>
              You <span className="font-semibold">have</span>{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">eaten</span> ... . / You
              <span className="font-semibold"> have</span>
              <span className="font-semibold text-red-700">n't</span>{" "}
              <span className="font-semibold">eaten</span> ... .
            </p>
            <p>
              They <span className="font-semibold">have</span>{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">eaten</span> ... . / They
              <span className="font-semibold"> have</span>
              <span className="font-semibold text-red-700">n't</span>{" "}
              <span className="font-semibold">eaten</span> ... .
            </p>
          </div>

          <div className="mb-6">
            <h3 className="font-bold text-pink-700 uppercase mb-1">
              Positive Questions
            </h3>
            <p>
              <span className="font-semibold">Have</span> I{" "}
              <span className="font-semibold">eaten</span> ... ?
            </p>
            <p>
              <span className="font-semibold">Have</span> you{" "}
              <span className="font-semibold">eaten</span> ... ?
            </p>
            <p>
              <span className="font-semibold">Has</span> he{" "}
              <span className="font-semibold">eaten</span> ... ?
            </p>
            <p>
              <span className="font-semibold">Has</span> she{" "}
              <span className="font-semibold">eaten</span> ... ?
            </p>
            <p>
              <span className="font-semibold">Has</span> it{" "}
              <span className="font-semibold">eaten</span> ... ?
            </p>
            <p>
              <span className="font-semibold">Have</span> we{" "}
              <span className="font-semibold">eaten</span> ... ?
            </p>
            <p>
              <span className="font-semibold">Have</span> you{" "}
              <span className="font-semibold">eaten</span>... ?
            </p>
            <p>
              <span className="font-semibold">Have</span> they{" "}
              <span className="font-semibold">eaten</span> ... ?
            </p>
          </div>

          <div className="mb-6">
            <h3 className="font-bold text-pink-700 uppercase mb-1">
              Negative Questions
            </h3>
            <p>
              <span className="font-semibold">Have</span> I{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">eaten</span> ...? /{" "}
              <span className="font-semibold">Have</span>
              <span className="font-semibold text-red-700">n't</span> I{" "}
              <span className="font-semibold">eaten</span> ... ?
            </p>
            <p>
              <span className="font-semibold">Have</span> you{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">eaten</span> ... ? /{" "}
              <span className="font-semibold">Have</span>
              <span className="font-semibold text-red-700">n't</span> you{" "}
              <span className="font-semibold">eaten</span>
              ... ?
            </p>
            <p>
              <span className="font-semibold">Has</span> he{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">eaten</span> ... ? /{" "}
              <span className="font-semibold">Has</span>
              <span className="font-semibold text-red-700">n't</span> he{" "}
              <span className="font-semibold">eaten</span>
              ... ?
            </p>
            <p>
              <span className="font-semibold">Has</span> she{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">eaten</span> ... ? /{" "}
              <span className="font-semibold">Has</span>
              <span className="font-semibold text-red-700">n't</span> she{" "}
              <span className="font-semibold">eaten</span>
              ... ?
            </p>
            <p>
              <span className="font-semibold">Has</span> it{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">eaten</span> ... ? /{" "}
              <span className="font-semibold">Has</span>
              <span className="font-semibold text-red-700">n't</span> it{" "}
              <span className="font-semibold">eaten</span>
              ... ?
            </p>
            <p>
              <span className="font-semibold">Have</span> we{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">eaten</span> ... ? /{" "}
              <span className="font-semibold">Have</span>
              <span className="font-semibold text-red-700">n't</span> we{" "}
              <span className="font-semibold">eaten</span>
              ... ?
            </p>
            <p>
              <span className="font-semibold">Have</span> you{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">eaten</span> ... ? /{" "}
              <span className="font-semibold">Have</span>
              <span className="font-semibold text-red-700">n't</span> you{" "}
              <span className="font-semibold">eaten</span>
              ... ?
            </p>
            <p>
              <span className="font-semibold">Have</span> they{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">eaten</span> ... ? /{" "}
              <span className="font-semibold">Have</span>
              <span className="font-semibold text-red-700">n't</span> they{" "}
              <span className="font-semibold">eaten</span>
              ... ?
            </p>
          </div>

          <div className="pb-6">
            <h3 className="font-bold text-pink-700 uppercase mb-1">
              Short Answers
            </h3>
            <p>
              Yes, I <span className="font-semibold">have</span>. / No, I
              <span className="font-semibold"> have</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, you <span className="font-semibold">have</span>. / No, you{" "}
              <span className="font-semibold">have</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, he <span className="font-semibold">has</span>. / No, he{" "}
              <span className="font-semibold">has</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, she <span className="font-semibold">has</span>. / No, she{" "}
              <span className="font-semibold">has</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, it <span className="font-semibold">has</span>. / No, it{" "}
              <span className="font-semibold">has</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, we <span className="font-semibold">have</span>. / No, we{" "}
              <span className="font-semibold">have</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, you <span className="font-semibold">have</span>. / No, you{" "}
              <span className="font-semibold">have</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, they <span className="font-semibold">have</span>. / No, they{" "}
              <span className="font-semibold">have</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
          </div>

          <p className="flex mb-4">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              In the Present Perfect Simple tense, we use the auxiliary verb
              "have" or "has" to form negative sentences and questions.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-8">
            <p className="font-semibold pb-4 italic">Examples:</p>{" "}
            <p className="flex pb-1">
              <span className="w-10 font-bold">+</span>
              <span className="italic">I've seen this movie before.</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">-</span>
              <span className="italic">I haven't seen this movie before.</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">+?</span>
              <span className="italic">Have you seen this movie before?</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">-?</span>
              <span className="italic">
                Haven't you seen this movie before? / Have you not seen this
                movie before?
              </span>
            </p>
            <p className="flex mb-8">
              <span className="w-10 font-bold"></span>
              <span className="italic">Yes, I have. / No, I haven't.</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">+</span>
              <span className="italic">She's changed her hairstyle.</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">-</span>
              <span className="italic">She hasn't changed her hairstyle.</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">+?</span>
              <span className="italic">Has she changed her hairstyle?</span>
            </p>
            <p className="flex pb-1">
              <span className="w-10 font-bold">-?</span>
              <span className="italic">
                Hasn't she changed her hairstyle? / Has she not changed her
                hairstyle?
              </span>
            </p>
            <p className="flex">
              <span className="w-10 font-bold"></span>
              <span className="italic">Yes, she has. / No, she hasn't.</span>
            </p>
          </div>

          <p className="flex mb-4">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span className="pl-3">
              We need to memorize the past and past participle forms of
              irregular verbs. A good dictionary is the best place to check
              them. Below is a list of the most common irregular verbs in
              English.
            </span>
          </p>

          <div className="mx-auto w-max text-base sm:text-xl">
            <table className="table-auto border-separate border border-slate-300 text-center">
              <thead>
                <tr>
                  <th className="border border-slate-300 px-2">Base Form</th>
                  <th className="border border-slate-300 px-2">Past</th>
                  <th className="border border-slate-300 px-2">
                    Past Participle
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border border-slate-300 px-2">become</td>
                  <td className="border border-slate-300 px-2">became</td>
                  <td className="border border-slate-300 px-2">become</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">begin</td>
                  <td className="border border-slate-300 px-2">began</td>
                  <td className="border border-slate-300 px-2">begun</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">break</td>
                  <td className="border border-slate-300 px-2">broke</td>
                  <td className="border border-slate-300 px-2">broken</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">bring</td>
                  <td className="border border-slate-300 px-2">brought</td>
                  <td className="border border-slate-300 px-2">brought</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">build</td>
                  <td className="border border-slate-300 px-2">built</td>
                  <td className="border border-slate-300 px-2">built</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">buy</td>
                  <td className="border border-slate-300 px-2">bought</td>
                  <td className="border border-slate-300 px-2">bought</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">catch</td>
                  <td className="border border-slate-300 px-2">caught</td>
                  <td className="border border-slate-300 px-2">caught</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">come</td>
                  <td className="border border-slate-300 px-2">came</td>
                  <td className="border border-slate-300 px-2">come</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">cost</td>
                  <td className="border border-slate-300 px-2">cost</td>
                  <td className="border border-slate-300 px-2">cost</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">do</td>
                  <td className="border border-slate-300 px-2">did</td>
                  <td className="border border-slate-300 px-2">done</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">drink</td>
                  <td className="border border-slate-300 px-2">drank</td>
                  <td className="border border-slate-300 px-2">drunk</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">drive</td>
                  <td className="border border-slate-300 px-2">drove</td>
                  <td className="border border-slate-300 px-2">driven</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">eat</td>
                  <td className="border border-slate-300 px-2">ate</td>
                  <td className="border border-slate-300 px-2">eaten</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">fall</td>
                  <td className="border border-slate-300 px-2">fell</td>
                  <td className="border border-slate-300 px-2">fallen</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">feel</td>
                  <td className="border border-slate-300 px-2">felt</td>
                  <td className="border border-slate-300 px-2">felt</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">find</td>
                  <td className="border border-slate-300 px-2">found</td>
                  <td className="border border-slate-300 px-2">found</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">fly</td>
                  <td className="border border-slate-300 px-2">flew</td>
                  <td className="border border-slate-300 px-2">flown</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">forget</td>
                  <td className="border border-slate-300 px-2">forgot</td>
                  <td className="border border-slate-300 px-2">forgotten</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">get</td>
                  <td className="border border-slate-300 px-2">got</td>
                  <td className="border border-slate-300 px-2">gotten</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">give</td>
                  <td className="border border-slate-300 px-2">gave</td>
                  <td className="border border-slate-300 px-2">given</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">go</td>
                  <td className="border border-slate-300 px-2">went</td>
                  <td className="border border-slate-300 px-2">gone</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">have</td>
                  <td className="border border-slate-300 px-2">had</td>
                  <td className="border border-slate-300 px-2">had</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">hear</td>
                  <td className="border border-slate-300 px-2">heard</td>
                  <td className="border border-slate-300 px-2">heard</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">know</td>
                  <td className="border border-slate-300 px-2">knew</td>
                  <td className="border border-slate-300 px-2">known</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">leave</td>
                  <td className="border border-slate-300 px-2">left</td>
                  <td className="border border-slate-300 px-2">left</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">lose</td>
                  <td className="border border-slate-300 px-2">lost</td>
                  <td className="border border-slate-300 px-2">lost</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">make</td>
                  <td className="border border-slate-300 px-2">made</td>
                  <td className="border border-slate-300 px-2">made</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">meet</td>
                  <td className="border border-slate-300 px-2">met</td>
                  <td className="border border-slate-300 px-2">met</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">pay</td>
                  <td className="border border-slate-300 px-2">paid</td>
                  <td className="border border-slate-300 px-2">paid</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">put</td>
                  <td className="border border-slate-300 px-2">put</td>
                  <td className="border border-slate-300 px-2">put</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">read</td>
                  <td className="border border-slate-300 px-2">read</td>
                  <td className="border border-slate-300 px-2">read</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">run</td>
                  <td className="border border-slate-300 px-2">ran</td>
                  <td className="border border-slate-300 px-2">run</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">say</td>
                  <td className="border border-slate-300 px-2">said</td>
                  <td className="border border-slate-300 px-2">said</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">see</td>
                  <td className="border border-slate-300 px-2">saw</td>
                  <td className="border border-slate-300 px-2">seen</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">send</td>
                  <td className="border border-slate-300 px-2">sent</td>
                  <td className="border border-slate-300 px-2">sent</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">sing</td>
                  <td className="border border-slate-300 px-2">sang</td>
                  <td className="border border-slate-300 px-2">sung</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">sit</td>
                  <td className="border border-slate-300 px-2">sat</td>
                  <td className="border border-slate-300 px-2">sat</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">sleep</td>
                  <td className="border border-slate-300 px-2">slept</td>
                  <td className="border border-slate-300 px-2">slept</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">speak</td>
                  <td className="border border-slate-300 px-2">spoke</td>
                  <td className="border border-slate-300 px-2">spoken</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">spend</td>
                  <td className="border border-slate-300 px-2">spent</td>
                  <td className="border border-slate-300 px-2">spent</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">stand</td>
                  <td className="border border-slate-300 px-2">stood</td>
                  <td className="border border-slate-300 px-2">stood</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">swim</td>
                  <td className="border border-slate-300 px-2">swam</td>
                  <td className="border border-slate-300 px-2">swum</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">teach</td>
                  <td className="border border-slate-300 px-2">taught</td>
                  <td className="border border-slate-300 px-2">taught</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">take</td>
                  <td className="border border-slate-300 px-2">took</td>
                  <td className="border border-slate-300 px-2">taken</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">tell</td>
                  <td className="border border-slate-300 px-2">told</td>
                  <td className="border border-slate-300 px-2">told</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">think</td>
                  <td className="border border-slate-300 px-2">thought</td>
                  <td className="border border-slate-300 px-2">thought</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">understand</td>
                  <td className="border border-slate-300 px-2">understood</td>
                  <td className="border border-slate-300 px-2">understood</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">wake</td>
                  <td className="border border-slate-300 px-2">woke</td>
                  <td className="border border-slate-300 px-2">woken</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">wear</td>
                  <td className="border border-slate-300 px-2">wore</td>
                  <td className="border border-slate-300 px-2">worn</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">win</td>
                  <td className="border border-slate-300 px-2">won</td>
                  <td className="border border-slate-300 px-2">won</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">write</td>
                  <td className="border border-slate-300 px-2">wrote</td>
                  <td className="border border-slate-300 px-2">written</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="mt-16 mb-8">
            <p className="font-bold mb-1">RELATED TOPICS</p>
            <p className="text-fuchsia-900">
              <img className="w-5 h-5 inline mr-2" src={LinkImg} alt="link" />
              <Link to="/grammar/elementary/pastSimpleRegular" target="_blank">
                Simple Past (Regular Verbs){" "}
                <span className="font-light">(Elementary)</span>
              </Link>
            </p>
            <p className="text-fuchsia-900">
              <img className="w-5 h-5 inline mr-2" src={LinkImg} alt="link" />
              <Link
                to="/grammar/elementary/pastSimpleIrregular"
                target="_blank"
              >
                Simple Past (Irregular Verbs){" "}
                <span className="font-light">(Elementary)</span>
              </Link>
            </p>
          </div>
        </div>

        {/* ---------- Body in Farsi ---------- */}
        <div
          className={`duration-300 ${
            farsi ? "" : "hidden opacity-0"
          } my-7 mx-5 text-indigo-950 text-xl md:text-xl sm:w-10/12 sm:mx-auto md:w-9/12 lg:w-8/12`}
        >
          <h1 className="text-pink-700 text-center text-3xl md:text-4xl font-bold mb-9 drop-shadow">
            The Present Perfect Simple
          </h1>
          <p lang="fa" dir="rtl" className="mb-6">
            در زبان انگلیسی وقتی راجع به فعلی صحبت می کنیم که در گذشته اتفاق
            افتاده و تمام شده، اما خواننده یا شنونده مان زمانش را نمی داند از
            زمان Present Perfect Simple استفاده می کنیم. اگر خواننده یا شنونده
            مان زمان رخ دادن فعل را بداند از زمان Simple Past استفاده می کنیم.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-6">
            <p lang="fa" dir="rtl" className="font-semibold pb-3">
              مثال:
            </p>{" "}
            <p className="flex pb-1 italic">
              She has visited Paris many times.
            </p>
            <p lang="fa" dir="rtl" className="mb-2">
              او بارها پاریس را دیده است.
            </p>
            <p className="flex pb-2 italic">I’ve seen this movie before.</p>
            <p lang="fa" dir="rtl" className="mb-2">
              من این فیلم را قبلا دیده ام.
            </p>
          </div>

          <p lang="fa" dir="rtl" className="mb-6">
            در جملات سوالی می توانیم قید <span className="font-bold">ever</span>{" "}
            را قبل از <span className="underline">past participle</span> بگذاریم
            تا در مورد تجربه هایی که یک شخص در کل زندگی اش ممکن است داشته باشد
            صحبت کنیم.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-6">
            <p lang="fa" dir="rtl" className="font-semibold pb-3">
              مثال:
            </p>{" "}
            <p className="pb-1 italic">
              Have you <span className="font-bold">ever</span>{" "}
              <span className="underline">tried</span> surfing?
            </p>
            <p lang="fa" dir="rtl" className="mb-2">
              تو تا حالا تو عمرت موج سواری کردی؟
            </p>
            <p className="pb-1 italic">
              Have you <span className="font-bold">ever</span>{" "}
              <span className="underline">eaten</span> sushi?
            </p>
            <p lang="fa" dir="rtl" className="mb-2">
              تو تا حالا تو عمرت سوشی خوردی؟
            </p>
          </div>

          <p lang="fa" dir="rtl" className="mb-6">
            همچنین می توانیم قید <span className="font-bold">never</span> را قبل
            از <span className="underline">past participle</span> بگذاریم تا
            بگوییم یک فعل هرگز در طول زندگی یک شخص اتفاق نیافتاده است.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-6">
            <p lang="fa" dir="rtl" className="font-semibold pb-3">
              مثال:
            </p>{" "}
            <p className="pb-1 italic">
              I've <span className="font-bold">never</span>{" "}
              <span className="underline">ridden</span> a horse.
            </p>
            <p lang="fa" dir="rtl" className="mb-2">
              من هیچ وقت اسب سواری نکرده ام.
            </p>
            <p className="pb-2">
              He has <span className="font-bold">never</span>{" "}
              <span className="underline">traveled</span> to Europe.
            </p>
            <p lang="fa" dir="rtl" className="mb-2">
              او هرگز به اروپا سفر نکرده است.
            </p>
          </div>

          <p dir="rtl" className="flex mb-6">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span lang="fa" className="pr-3">
              قید never خودش جمله را منفی می کند، پس وقتی از never در جمله
              استفاده می کنیم دیگر قبل از فعل کمکی not نمی گذاریم. <br />(
              <span className="text-red-600 line-through">
                .I haven't never ridden a horse
              </span>
              )
            </span>
          </p>

          <p lang="fa" dir="rtl" className="mb-6">
            استفاده دیگر Present Perfect Simple در هنگام صحبت کردن درباره
            اتفاقاتی است که اخیرا رخ داده، بدون اشاره به زمان دقیق آنها.
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-6">
            <p lang="fa" dir="rtl" className="font-semibold pb-3">
              مثال:
            </p>{" "}
            <p className="pb-1 italic">
              Your father has arrived. He’s waiting for you downstairs.
            </p>
            <p lang="fa" dir="rtl" className="mb-2">
              پدرت رسیده است. طبقه پایین منتظرت است.
            </p>
            <p className="pb-2 italic">
              I've done my homework, so I'm going out to play with my friends.
            </p>
            <p lang="fa" dir="rtl" className="mb-1">
              تکالیفم را انجام دادم، پس می روم بیرون با دوستانم بازی کنم.
            </p>
          </div>

          <p lang="fa" dir="rtl" className="mb-4">
            در زبان انگلیسی دو گروه فعل داریم:
          </p>

          <div lang="fa" dir="rtl" className="flex mb-4">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot pb-2"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="flex flex-col">
              <span className="pt-1 font-bold flex-none mb-2">
                افعال باقاعده
              </span>
              <span>
                شکل گذشته و شکل سوم (past participle) افعال باقاعده از الگوی
                معمول پیروی می کنند و "ed-" می گیرند، مثلا فعل "walk" که شکل
                گذشته و شکل سوم اش "walked" است.
              </span>
            </span>
          </div>

          <div lang="fa" dir="rtl" className="flex mb-4">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                fill="#BE185D"
                className="bi bi-dot pb-2"
                viewBox="0 0 16 16"
              >
                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
              </svg>
            </span>
            <span className="flex flex-col">
              <span className="pt-1 font-bold flex-none mb-2">
                افعال بی قاعده:
              </span>
              <span>
                افعال بی قاعده از الگوی معمول "ed-" پیروی نمی کنند و باید شکل
                گذشته و شکل سوم آنها را حفظ کنیم، مثلا فعل "eat" که شکل گذشته اش
                "ate" و شکل سوم اش "eaten" است.
              </span>
            </span>
          </div>

          <p lang="fa" dir="rtl" className="mb-6">
            اکنون می خواهیم به ساختار های مختلف جمله در زمان Present Perfect
            برای فعل با قاعده "work" نگاه کنیم.
          </p>

          <div className="mb-6">
            <h3 className="font-bold text-pink-700 uppercase mb-1">
              <span>Positive</span>
              <span lang="fa" className="ml-8">
                خبری مثبت
              </span>
            </h3>
            <p>
              I <span className="font-semibold">have</span> / I
              <span className="font-semibold">'ve worked</span> ... .
            </p>
            <p>
              You <span className="font-semibold">have</span> / You
              <span className="font-semibold">'ve worked</span> ... .
            </p>
            <p>
              He <span className="font-semibold">has</span> / He
              <span className="font-semibold">'s worked</span> ... .
            </p>
            <p>
              She <span className="font-semibold">has</span> / She
              <span className="font-semibold">'s worked</span> ... .
            </p>
            <p>
              It <span className="font-semibold">has</span> / It
              <span className="font-semibold">'s worked</span> ... .
            </p>
            <p>
              We <span className="font-semibold">have</span> / We
              <span className="font-semibold">'ve worked</span> ... .
            </p>
            <p>
              You <span className="font-semibold">have</span> / You
              <span className="font-semibold">'ve worked</span> ... .
            </p>
            <p>
              They <span className="font-semibold">have</span> / They
              <span className="font-semibold">'ve worked</span> ... .
            </p>
          </div>

          <div className="mb-6">
            <h3 className="font-bold text-pink-700 uppercase mb-1">
              <span>Negative</span>
              <span lang="fa" className="ml-8">
                خبری منفی
              </span>
            </h3>
            <p>
              I <span className="font-semibold">have</span>{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">worked</span> ... . / I
              <span className="font-semibold"> have</span>
              <span className="font-semibold text-red-700">n't</span>{" "}
              <span className="font-semibold">worked</span> ... .
            </p>
            <p>
              You <span className="font-semibold">have</span>{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">worked</span> ... . / You
              <span className="font-semibold"> have</span>
              <span className="font-semibold text-red-700">n't</span>{" "}
              <span className="font-semibold">worked</span> ... .
            </p>
            <p>
              He <span className="font-semibold">has</span>{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">worked</span> ... . / He
              <span className="font-semibold"> has</span>
              <span className="font-semibold text-red-700">n't</span>{" "}
              <span className="font-semibold">worked</span> ... .
            </p>
            <p>
              She <span className="font-semibold">has</span>{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">worked</span> ... . / She
              <span className="font-semibold"> has</span>
              <span className="font-semibold text-red-700">n't</span>{" "}
              <span className="font-semibold">worked</span> ... .
            </p>
            <p>
              It <span className="font-semibold">has</span>{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">worked</span> ... . / It
              <span className="font-semibold"> has</span>
              <span className="font-semibold text-red-700">n't</span>{" "}
              <span className="font-semibold">worked</span> ... .
            </p>
            <p>
              We <span className="font-semibold">have</span>{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">worked</span> ... . / We
              <span className="font-semibold"> have</span>
              <span className="font-semibold text-red-700">n't</span>{" "}
              <span className="font-semibold">worked</span> ... .
            </p>
            <p>
              You <span className="font-semibold">have</span>{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">worked</span> ... . / You
              <span className="font-semibold"> have</span>
              <span className="font-semibold text-red-700">n't</span>{" "}
              <span className="font-semibold">worked</span> ... .
            </p>
            <p>
              They <span className="font-semibold">have</span>{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">worked</span> ... . / They
              <span className="font-semibold"> have</span>
              <span className="font-semibold text-red-700">n't</span>{" "}
              <span className="font-semibold">worked</span> ... .
            </p>
          </div>

          <div className="mb-6">
            <h3 className="font-bold text-pink-700 uppercase mb-1">
              <span>Positive Questions</span>
              <span lang="fa" className="ml-8">
                سوالی مثبت
              </span>
            </h3>
            <p>
              <span className="font-semibold">Have</span> I{" "}
              <span className="font-semibold">worked</span> ... ?
            </p>
            <p>
              <span className="font-semibold">Have</span> you{" "}
              <span className="font-semibold">worked</span> ... ?
            </p>
            <p>
              <span className="font-semibold">Has</span> he{" "}
              <span className="font-semibold">worked</span> ... ?
            </p>
            <p>
              <span className="font-semibold">Has</span> she{" "}
              <span className="font-semibold">worked</span> ... ?
            </p>
            <p>
              <span className="font-semibold">Has</span> it{" "}
              <span className="font-semibold">worked</span> ... ?
            </p>
            <p>
              <span className="font-semibold">Have</span> we{" "}
              <span className="font-semibold">worked</span> ... ?
            </p>
            <p>
              <span className="font-semibold">Have</span> you{" "}
              <span className="font-semibold">worked</span>... ?
            </p>
            <p>
              <span className="font-semibold">Have</span> they{" "}
              <span className="font-semibold">worked</span> ... ?
            </p>
          </div>

          <div className="mb-6">
            <h3 className="font-bold text-pink-700 uppercase mb-1">
              <span>Negative Questions</span>
              <span lang="fa" className="ml-8">
                سوالی منفی
              </span>
            </h3>
            <p>
              <span className="font-semibold">Have</span> I{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">worked</span> ...? /{" "}
              <span className="font-semibold">Have</span>
              <span className="font-semibold text-red-700">n't</span> I{" "}
              <span className="font-semibold">worked</span> ... ?
            </p>
            <p>
              <span className="font-semibold">Have</span> you{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">worked</span> ... ? /{" "}
              <span className="font-semibold">Have</span>
              <span className="font-semibold text-red-700">n't</span> you{" "}
              <span className="font-semibold">worked</span>
              ... ?
            </p>
            <p>
              <span className="font-semibold">Has</span> he{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">worked</span> ... ? /{" "}
              <span className="font-semibold">Has</span>
              <span className="font-semibold text-red-700">n't</span> he{" "}
              <span className="font-semibold">worked</span>
              ... ?
            </p>
            <p>
              <span className="font-semibold">Has</span> she{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">worked</span> ... ? /{" "}
              <span className="font-semibold">Has</span>
              <span className="font-semibold text-red-700">n't</span> she{" "}
              <span className="font-semibold">worked</span>
              ... ?
            </p>
            <p>
              <span className="font-semibold">Has</span> it{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">worked</span> ... ? /{" "}
              <span className="font-semibold">Has</span>
              <span className="font-semibold text-red-700">n't</span> it{" "}
              <span className="font-semibold">worked</span>
              ... ?
            </p>
            <p>
              <span className="font-semibold">Have</span> we{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">worked</span> ... ? /{" "}
              <span className="font-semibold">Have</span>
              <span className="font-semibold text-red-700">n't</span> we{" "}
              <span className="font-semibold">worked</span>
              ... ?
            </p>
            <p>
              <span className="font-semibold">Have</span> you{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">worked</span> ... ? /{" "}
              <span className="font-semibold">Have</span>
              <span className="font-semibold text-red-700">n't</span> you{" "}
              <span className="font-semibold">worked</span>
              ... ?
            </p>
            <p>
              <span className="font-semibold">Have</span> they{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">worked</span> ... ? /{" "}
              <span className="font-semibold">Have</span>
              <span className="font-semibold text-red-700">n't</span> they{" "}
              <span className="font-semibold">worked</span>
              ... ?
            </p>
          </div>

          <div className="pb-6">
            <h3 className="font-bold text-pink-700 uppercase mb-1">
              <span>Short Answers</span>
              <span lang="fa" className="ml-8">
                پاسخ کوتاه
              </span>
            </h3>
            <p>
              Yes, I <span className="font-semibold">have</span>. / No, I
              <span className="font-semibold"> have</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, you <span className="font-semibold">have</span>. / No, you{" "}
              <span className="font-semibold">have</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, he <span className="font-semibold">has</span>. / No, he{" "}
              <span className="font-semibold">has</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, she <span className="font-semibold">has</span>. / No, she{" "}
              <span className="font-semibold">has</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, it <span className="font-semibold">has</span>. / No, it{" "}
              <span className="font-semibold">has</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, we <span className="font-semibold">have</span>. / No, we{" "}
              <span className="font-semibold">have</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, you <span className="font-semibold">have</span>. / No, you{" "}
              <span className="font-semibold">have</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, they <span className="font-semibold">have</span>. / No, they{" "}
              <span className="font-semibold">have</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
          </div>

          <p lang="fa" dir="rtl" className="mb-6">
            در ادامه ساختار های مختلف جمله در این زمان را برای فعل بی قاعده
            "eat" به عنوان مثال مشاهده می کنیم.
          </p>

          <div className="mb-6">
            <h3 className="font-bold text-pink-700 uppercase mb-1">
              <span>Positive</span>
              <span lang="fa" className="ml-8">
                خبری مثبت
              </span>
            </h3>
            <p>
              I <span className="font-semibold">have</span> / I
              <span className="font-semibold">'ve eaten</span> ... .
            </p>
            <p>
              You <span className="font-semibold">have</span> / You
              <span className="font-semibold">'ve eaten</span> ... .
            </p>
            <p>
              He <span className="font-semibold">has</span> / He
              <span className="font-semibold">'s eaten</span> ... .
            </p>
            <p>
              She <span className="font-semibold">has</span> / She
              <span className="font-semibold">'s eaten</span> ... .
            </p>
            <p>
              It <span className="font-semibold">has</span> / It
              <span className="font-semibold">'s eaten</span> ... .
            </p>
            <p>
              We <span className="font-semibold">have</span> / We
              <span className="font-semibold">'ve eaten</span> ... .
            </p>
            <p>
              You <span className="font-semibold">have</span> / You
              <span className="font-semibold">'ve eaten</span> ... .
            </p>
            <p>
              They <span className="font-semibold">have</span> / They
              <span className="font-semibold">'ve eaten</span> ... .
            </p>
          </div>

          <div className="mb-6">
            <h3 className="font-bold text-pink-700 uppercase mb-1">
              <span>Negative</span>
              <span lang="fa" className="ml-8">
                خبری منفی
              </span>
            </h3>
            <p>
              I <span className="font-semibold">have</span>{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">eaten</span> ... . / I
              <span className="font-semibold"> have</span>
              <span className="font-semibold text-red-700">n't</span>{" "}
              <span className="font-semibold">eaten</span> ... .
            </p>
            <p>
              You <span className="font-semibold">have</span>{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">eaten</span> ... . / You
              <span className="font-semibold"> have</span>
              <span className="font-semibold text-red-700">n't</span>{" "}
              <span className="font-semibold">eaten</span> ... .
            </p>
            <p>
              He <span className="font-semibold">has</span>{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">eaten</span> ... . / He
              <span className="font-semibold"> has</span>
              <span className="font-semibold text-red-700">n't</span>{" "}
              <span className="font-semibold">eaten</span> ... .
            </p>
            <p>
              She <span className="font-semibold">has</span>{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">eaten</span> ... . / She
              <span className="font-semibold"> has</span>
              <span className="font-semibold text-red-700">n't</span>{" "}
              <span className="font-semibold">eaten</span> ... .
            </p>
            <p>
              It <span className="font-semibold">has</span>{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">eaten</span> ... . / It
              <span className="font-semibold"> has</span>
              <span className="font-semibold text-red-700">n't</span>{" "}
              <span className="font-semibold">eaten</span> ... .
            </p>
            <p>
              We <span className="font-semibold">have</span>{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">eaten</span> ... . / We
              <span className="font-semibold"> have</span>
              <span className="font-semibold text-red-700">n't</span>{" "}
              <span className="font-semibold">eaten</span> ... .
            </p>
            <p>
              You <span className="font-semibold">have</span>{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">eaten</span> ... . / You
              <span className="font-semibold"> have</span>
              <span className="font-semibold text-red-700">n't</span>{" "}
              <span className="font-semibold">eaten</span> ... .
            </p>
            <p>
              They <span className="font-semibold">have</span>{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">eaten</span> ... . / They
              <span className="font-semibold"> have</span>
              <span className="font-semibold text-red-700">n't</span>{" "}
              <span className="font-semibold">eaten</span> ... .
            </p>
          </div>

          <div className="mb-6">
            <h3 className="font-bold text-pink-700 uppercase mb-1">
              <span>Positive Questions</span>
              <span lang="fa" className="ml-8">
                سوالی مثبت
              </span>
            </h3>
            <p>
              <span className="font-semibold">Have</span> I{" "}
              <span className="font-semibold">eaten</span> ... ?
            </p>
            <p>
              <span className="font-semibold">Have</span> you{" "}
              <span className="font-semibold">eaten</span> ... ?
            </p>
            <p>
              <span className="font-semibold">Has</span> he{" "}
              <span className="font-semibold">eaten</span> ... ?
            </p>
            <p>
              <span className="font-semibold">Has</span> she{" "}
              <span className="font-semibold">eaten</span> ... ?
            </p>
            <p>
              <span className="font-semibold">Has</span> it{" "}
              <span className="font-semibold">eaten</span> ... ?
            </p>
            <p>
              <span className="font-semibold">Have</span> we{" "}
              <span className="font-semibold">eaten</span> ... ?
            </p>
            <p>
              <span className="font-semibold">Have</span> you{" "}
              <span className="font-semibold">eaten</span>... ?
            </p>
            <p>
              <span className="font-semibold">Have</span> they{" "}
              <span className="font-semibold">eaten</span> ... ?
            </p>
          </div>

          <div className="mb-6">
            <h3 className="font-bold text-pink-700 uppercase mb-1">
              <span>Negative Questions</span>
              <span lang="fa" className="ml-8">
                سوالی منفی
              </span>
            </h3>
            <p>
              <span className="font-semibold">Have</span> I{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">eaten</span> ...? /{" "}
              <span className="font-semibold">Have</span>
              <span className="font-semibold text-red-700">n't</span> I{" "}
              <span className="font-semibold">eaten</span> ... ?
            </p>
            <p>
              <span className="font-semibold">Have</span> you{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">eaten</span> ... ? /{" "}
              <span className="font-semibold">Have</span>
              <span className="font-semibold text-red-700">n't</span> you{" "}
              <span className="font-semibold">eaten</span>
              ... ?
            </p>
            <p>
              <span className="font-semibold">Has</span> he{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">eaten</span> ... ? /{" "}
              <span className="font-semibold">Has</span>
              <span className="font-semibold text-red-700">n't</span> he{" "}
              <span className="font-semibold">eaten</span>
              ... ?
            </p>
            <p>
              <span className="font-semibold">Has</span> she{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">eaten</span> ... ? /{" "}
              <span className="font-semibold">Has</span>
              <span className="font-semibold text-red-700">n't</span> she{" "}
              <span className="font-semibold">eaten</span>
              ... ?
            </p>
            <p>
              <span className="font-semibold">Has</span> it{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">eaten</span> ... ? /{" "}
              <span className="font-semibold">Has</span>
              <span className="font-semibold text-red-700">n't</span> it{" "}
              <span className="font-semibold">eaten</span>
              ... ?
            </p>
            <p>
              <span className="font-semibold">Have</span> we{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">eaten</span> ... ? /{" "}
              <span className="font-semibold">Have</span>
              <span className="font-semibold text-red-700">n't</span> we{" "}
              <span className="font-semibold">eaten</span>
              ... ?
            </p>
            <p>
              <span className="font-semibold">Have</span> you{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">eaten</span> ... ? /{" "}
              <span className="font-semibold">Have</span>
              <span className="font-semibold text-red-700">n't</span> you{" "}
              <span className="font-semibold">eaten</span>
              ... ?
            </p>
            <p>
              <span className="font-semibold">Have</span> they{" "}
              <span className="font-semibold text-red-700">not</span>{" "}
              <span className="font-semibold">eaten</span> ... ? /{" "}
              <span className="font-semibold">Have</span>
              <span className="font-semibold text-red-700">n't</span> they{" "}
              <span className="font-semibold">eaten</span>
              ... ?
            </p>
          </div>

          <div className="pb-6">
            <h3 className="font-bold text-pink-700 uppercase mb-1">
              <span>Short Answers</span>
              <span lang="fa" className="ml-8">
                پاسخ کوتاه
              </span>
            </h3>
            <p>
              Yes, I <span className="font-semibold">have</span>. / No, I
              <span className="font-semibold"> have</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, you <span className="font-semibold">have</span>. / No, you{" "}
              <span className="font-semibold">have</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, he <span className="font-semibold">has</span>. / No, he{" "}
              <span className="font-semibold">has</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, she <span className="font-semibold">has</span>. / No, she{" "}
              <span className="font-semibold">has</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, it <span className="font-semibold">has</span>. / No, it{" "}
              <span className="font-semibold">has</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, we <span className="font-semibold">have</span>. / No, we{" "}
              <span className="font-semibold">have</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, you <span className="font-semibold">have</span>. / No, you{" "}
              <span className="font-semibold">have</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
            <p>
              Yes, they <span className="font-semibold">have</span>. / No, they{" "}
              <span className="font-semibold">have</span>
              <span className="font-semibold text-red-700">n't</span>.
            </p>
          </div>

          <p dir="rtl" className="flex mb-4">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span lang="fa" className="pr-3">
              برای منفی یا سوالی کردن جملات در این زمان از فعل کمکی have یا has
              استفاده می کنیم.
            </span>
          </p>

          <div className="w-5/6 mx-auto px-4 py-3 rounded-lg bg-pink-50 shadow-md mb-8">
            <p lang="fa" dir="rtl" className="font-semibold pb-4">
              مثال:
            </p>{" "}
            <p className="flex ">
              <span className="w-10 font-bold">+</span>
              <span className="italic">I've seen this movie before.</span>
            </p>
            <p lang="fa" dir="rtl" className="mb-1">
              من این فیلم را قبلا دیده ام.
            </p>
            <p className="flex ">
              <span className="w-10 font-bold">-</span>
              <span className="italic">I haven't seen this movie before.</span>
            </p>
            <p lang="fa" dir="rtl" className="mb-1">
              من این فیلم را قبلا ندیده ام.
            </p>
            <p className="flex ">
              <span className="w-10 font-bold">+?</span>
              <span className="italic">Have you seen this movie before?</span>
            </p>
            <p lang="fa" dir="rtl" className="mb-1">
              آیا تو قبلا این فیلم را دیده ای؟
            </p>
            <p className="flex ">
              <span className="w-10 font-bold">-?</span>
              <span className="italic">
                Haven't you seen this movie before? / Have you not seen this
                movie before?
              </span>
            </p>
            <p lang="fa" dir="rtl" className="mb-1">
              آیا تو قبلا این فیلم را ندیدی؟
            </p>
            <p className="flex mb-16">
              <span className="w-10 font-bold"></span>
              <span className="italic">Yes, I have. / No, I haven't.</span>
            </p>
            <p className="flex ">
              <span className="w-10 font-bold">+</span>
              <span className="italic">She's changed her hairstyle.</span>
            </p>
            <p lang="fa" dir="rtl" className="mb-1">
              او مدل موهایش را عوض کرده است.
            </p>
            <p className="flex ">
              <span className="w-10 font-bold">-</span>
              <span className="italic">She hasn't changed her hairstyle.</span>
            </p>
            <p lang="fa" dir="rtl" className="mb-1">
              او مدل موهایش را تغییر نداده است.
            </p>
            <p className="flex ">
              <span className="w-10 font-bold">+?</span>
              <span className="italic">Has she changed her hairstyle?</span>
            </p>
            <p lang="fa" dir="rtl" className="mb-1">
              آیا او مدل موهایش را تغییر داده است؟
            </p>
            <p className="flex ">
              <span className="w-10 font-bold">-?</span>
              <span className="italic">
                Hasn't she changed her hairstyle? / Has she not changed her
                hairstyle?
              </span>
            </p>
            <p lang="fa" dir="rtl" className="mb-1">
              آیا او مدل موهایش را تغییر نداده است؟
            </p>
            <p className="flex">
              <span className="w-10 font-bold"></span>
              <span className="italic">Yes, she has. / No, she hasn't.</span>
            </p>
          </div>

          <p dir="rtl" className="flex mb-4">
            <img
              className="w-8 h-8 relative bottom-1 animate-shake origin-bottom"
              src={Bulb}
              alt="Bulb"
            />
            <span lang="fa" className="pr-3">
              همانطور که گفتیم باید شکل گذشته و شکل سوم (past participle) افعال
              بی قاعده را حفظ کنیم. بهترین جا برای چک کردن شکل گذشته و شکل سوم
              افعال بی قاعده یک دیکشنری معتبر است. در پایین لیستی از رایج ترین
              افعال بی قاعده را در زبان انگلیسی مشاهده می کنید.
            </span>
          </p>

          <div className="mx-auto w-max text-base sm:text-xl">
            <table className="table-auto border-separate border border-slate-300 text-center">
              <thead>
                <tr>
                  <th className="border border-slate-300 px-2">Base Form</th>
                  <th className="border border-slate-300 px-2">Past</th>
                  <th className="border border-slate-300 px-2">
                    Past Participle
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border border-slate-300 px-2">become</td>
                  <td className="border border-slate-300 px-2">became</td>
                  <td className="border border-slate-300 px-2">become</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">begin</td>
                  <td className="border border-slate-300 px-2">began</td>
                  <td className="border border-slate-300 px-2">begun</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">break</td>
                  <td className="border border-slate-300 px-2">broke</td>
                  <td className="border border-slate-300 px-2">broken</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">bring</td>
                  <td className="border border-slate-300 px-2">brought</td>
                  <td className="border border-slate-300 px-2">brought</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">build</td>
                  <td className="border border-slate-300 px-2">built</td>
                  <td className="border border-slate-300 px-2">built</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">buy</td>
                  <td className="border border-slate-300 px-2">bought</td>
                  <td className="border border-slate-300 px-2">bought</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">catch</td>
                  <td className="border border-slate-300 px-2">caught</td>
                  <td className="border border-slate-300 px-2">caught</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">come</td>
                  <td className="border border-slate-300 px-2">came</td>
                  <td className="border border-slate-300 px-2">come</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">cost</td>
                  <td className="border border-slate-300 px-2">cost</td>
                  <td className="border border-slate-300 px-2">cost</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">do</td>
                  <td className="border border-slate-300 px-2">did</td>
                  <td className="border border-slate-300 px-2">done</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">drink</td>
                  <td className="border border-slate-300 px-2">drank</td>
                  <td className="border border-slate-300 px-2">drunk</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">drive</td>
                  <td className="border border-slate-300 px-2">drove</td>
                  <td className="border border-slate-300 px-2">driven</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">eat</td>
                  <td className="border border-slate-300 px-2">ate</td>
                  <td className="border border-slate-300 px-2">eaten</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">fall</td>
                  <td className="border border-slate-300 px-2">fell</td>
                  <td className="border border-slate-300 px-2">fallen</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">feel</td>
                  <td className="border border-slate-300 px-2">felt</td>
                  <td className="border border-slate-300 px-2">felt</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">find</td>
                  <td className="border border-slate-300 px-2">found</td>
                  <td className="border border-slate-300 px-2">found</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">fly</td>
                  <td className="border border-slate-300 px-2">flew</td>
                  <td className="border border-slate-300 px-2">flown</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">forget</td>
                  <td className="border border-slate-300 px-2">forgot</td>
                  <td className="border border-slate-300 px-2">forgotten</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">get</td>
                  <td className="border border-slate-300 px-2">got</td>
                  <td className="border border-slate-300 px-2">gotten</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">give</td>
                  <td className="border border-slate-300 px-2">gave</td>
                  <td className="border border-slate-300 px-2">given</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">go</td>
                  <td className="border border-slate-300 px-2">went</td>
                  <td className="border border-slate-300 px-2">gone</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">have</td>
                  <td className="border border-slate-300 px-2">had</td>
                  <td className="border border-slate-300 px-2">had</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">hear</td>
                  <td className="border border-slate-300 px-2">heard</td>
                  <td className="border border-slate-300 px-2">heard</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">know</td>
                  <td className="border border-slate-300 px-2">knew</td>
                  <td className="border border-slate-300 px-2">known</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">leave</td>
                  <td className="border border-slate-300 px-2">left</td>
                  <td className="border border-slate-300 px-2">left</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">lose</td>
                  <td className="border border-slate-300 px-2">lost</td>
                  <td className="border border-slate-300 px-2">lost</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">make</td>
                  <td className="border border-slate-300 px-2">made</td>
                  <td className="border border-slate-300 px-2">made</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">meet</td>
                  <td className="border border-slate-300 px-2">met</td>
                  <td className="border border-slate-300 px-2">met</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">pay</td>
                  <td className="border border-slate-300 px-2">paid</td>
                  <td className="border border-slate-300 px-2">paid</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">put</td>
                  <td className="border border-slate-300 px-2">put</td>
                  <td className="border border-slate-300 px-2">put</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">read</td>
                  <td className="border border-slate-300 px-2">read</td>
                  <td className="border border-slate-300 px-2">read</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">run</td>
                  <td className="border border-slate-300 px-2">ran</td>
                  <td className="border border-slate-300 px-2">run</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">say</td>
                  <td className="border border-slate-300 px-2">said</td>
                  <td className="border border-slate-300 px-2">said</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">see</td>
                  <td className="border border-slate-300 px-2">saw</td>
                  <td className="border border-slate-300 px-2">seen</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">send</td>
                  <td className="border border-slate-300 px-2">sent</td>
                  <td className="border border-slate-300 px-2">sent</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">sing</td>
                  <td className="border border-slate-300 px-2">sang</td>
                  <td className="border border-slate-300 px-2">sung</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">sit</td>
                  <td className="border border-slate-300 px-2">sat</td>
                  <td className="border border-slate-300 px-2">sat</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">sleep</td>
                  <td className="border border-slate-300 px-2">slept</td>
                  <td className="border border-slate-300 px-2">slept</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">speak</td>
                  <td className="border border-slate-300 px-2">spoke</td>
                  <td className="border border-slate-300 px-2">spoken</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">spend</td>
                  <td className="border border-slate-300 px-2">spent</td>
                  <td className="border border-slate-300 px-2">spent</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">stand</td>
                  <td className="border border-slate-300 px-2">stood</td>
                  <td className="border border-slate-300 px-2">stood</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">swim</td>
                  <td className="border border-slate-300 px-2">swam</td>
                  <td className="border border-slate-300 px-2">swum</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">teach</td>
                  <td className="border border-slate-300 px-2">taught</td>
                  <td className="border border-slate-300 px-2">taught</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">take</td>
                  <td className="border border-slate-300 px-2">took</td>
                  <td className="border border-slate-300 px-2">taken</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">tell</td>
                  <td className="border border-slate-300 px-2">told</td>
                  <td className="border border-slate-300 px-2">told</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">think</td>
                  <td className="border border-slate-300 px-2">thought</td>
                  <td className="border border-slate-300 px-2">thought</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">understand</td>
                  <td className="border border-slate-300 px-2">understood</td>
                  <td className="border border-slate-300 px-2">understood</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">wake</td>
                  <td className="border border-slate-300 px-2">woke</td>
                  <td className="border border-slate-300 px-2">woken</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">wear</td>
                  <td className="border border-slate-300 px-2">wore</td>
                  <td className="border border-slate-300 px-2">worn</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">win</td>
                  <td className="border border-slate-300 px-2">won</td>
                  <td className="border border-slate-300 px-2">won</td>
                </tr>
                <tr>
                  <td className="border border-slate-300 px-2">write</td>
                  <td className="border border-slate-300 px-2">wrote</td>
                  <td className="border border-slate-300 px-2">written</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="mt-16 mb-8">
            <p className="font-bold mb-1">RELATED TOPICS</p>
            <p className="text-fuchsia-900">
              <img className="w-5 h-5 inline mr-2" src={LinkImg} alt="link" />
              <Link to="/grammar/elementary/pastSimpleRegular" target="_blank">
                Simple Past (Regular Verbs){" "}
                <span className="font-light">(Elementary)</span>
              </Link>
            </p>
            <p className="text-fuchsia-900">
              <img className="w-5 h-5 inline mr-2" src={LinkImg} alt="link" />
              <Link
                to="/grammar/elementary/pastSimpleIrregular"
                target="_blank"
              >
                Simple Past (Irregular Verbs){" "}
                <span className="font-light">(Elementary)</span>
              </Link>
            </p>
          </div>
        </div>
      </div>
      <ExerciseCard questions={questions} instruction={instruction} />
    </>
  );
};
