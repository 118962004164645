import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import ReactGA from "react-ga4";

import "./index.css";

import { ScrollToTop } from "./components";
import App from "./App";

ReactGA.initialize("G-SC5LHZTESX", {
  gaOptions: {
    cookieFlags: "SameSite=None; Secure",
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <ScrollToTop />
      <App />
    </Router>
  </React.StrictMode>
);
